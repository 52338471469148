import MediaGrid from '../MediaGrid';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setPopupGalleryModal } from 'src/store/actions/modals.actions';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  popupGallery: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: 'calc(100% - 30px)',
    padding: '15px',
  },
}));

type PopupGalleryType = {
  popupData: any;
  currentMediaID?: any;
  display?: any;
};

const PopupGallery: React.FunctionComponent<PopupGalleryType> = ({ popupData, currentMediaID, display = "responsive" }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const medias = popupData.media;
  const [gridData, setGridData]: any = useState([]);
  
  useEffect(() => {
    const tmpPhotos = medias.filter((item: any) => item.type === "image").map((photo: any) => {
      return { mediaID: photo.mediaID, type: 'photo', thumbLink: photo.thumbLink, fullsizeLink: photo.fullsizeLink, name: photo.name, favorite: photo.favorite };
    });
    const tmpFiles = medias.filter((item: any) => item.type === "attachment").map((file: any) => {
      return { mediaID: file.mediaID, type: 'attachment', icon: "document-" + file.name.split('.').pop() + "", name: file.name, size: file.size, favorite: file.favorite };
    });
    const tmpVideos = medias.filter((item: any) => item.type === "video").map((video: any) => {
      return { mediaID: video.mediaID, type: 'video', thumbLink: video.thumbLink, link: video.link, name: video.name, favorite: video.favorite };
    });
    const tmpGridData = [].concat(tmpPhotos, tmpFiles, tmpVideos).filter((item: any) => item);
    if(tmpGridData.length > 0) {
      setGridData(tmpGridData);
    }
  }, [medias], [popupData, medias]);

  const handleOpenModal = (mediaID: any) => {
    const settings = {
      isOpen: true,
      mediaID: mediaID,
      mediaList: gridData,
    };
    dispatch(setPopupGalleryModal(settings));  
  };
  
  return (
    <div className={classes.popupGallery} data-cy={isCypress() ? "popupCardGallery" : null}>
      <MediaGrid
        data={gridData}
        currentMediaID={currentMediaID}
        display={display}
        width='100%'
        maxWidth='100%'
        onModalOpen={handleOpenModal}
      />
    </div>
  );
}

export default PopupGallery;
import Clamp from 'react-multiline-clamp';
import config from 'src/constants/config';
import DateFormat from '../../../utils/dateFormat';
import hexToRgba from 'hex-to-rgba';
import htmlParse from 'html-react-parser';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import PopupGallery from 'src/components/Gallery/PopupGallery';
import React, { useCallback, useRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import SVG from '../../../components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { isCypress } from 'src/utils/useCypress';
import { saveUserSettings } from 'src/utils/useUser';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  popupCategoryWidthParam?: any,
  popupCategoryBackgroundColorParam?: any,
  popupCategoryColorParam?: any,
  isPropagated?: any;
  inModal?: any;
}

const useStyles = createUseStyles((theme: any) => ({
  popupCard: {
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    boxShadow: (props: Props) => {
      if(props.isPropagated) return theme.shadows[4];
      else return theme.shadows[2];
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      borderRadius: (props: Props) => {
        if(!props.inModal) return '0px';
        else return '';
      },
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timelineHeader: {
    padding: '2rem 0rem 0.5rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  detailInformation: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  popupCategory: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 8px',
    borderRadius: '10px 0 0 10px',
    position: 'absolute',
    right: '0',
    transition: 'right 0.25s',
    backgroundColor: (props: Props) => props.popupCategoryBackgroundColorParam,
    color: (props: Props) => props.popupCategoryColorParam,
    '& > span': {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 4px',
      right: (props: Props) => '-' + (props.popupCategoryWidthParam - 35).toString() + 'px',
      '& > span': {
        fontSize: '10px',
        opacity: '0',
        transition: 'opacity 0.25s',
      },
      '&.active': {
        right: '0',
        '& > span': {
          opacity: '1',
        },
      },
    },
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    color: '#C2C4D9',
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    '& > svg': {
      width: '14px',
      height: '14px',
      marginRight: '4px',
    }
  },
  timelineContent: {
    padding: '0.5rem 30px 20px 30px',
  },
  title: {
    display: 'block',
    fontWeight: 'bold',
    color: theme.colors.grey[800],
    fontSize: '24px',
    maxWidth: '100%',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
  },
  description: {
    display: 'block',
    color: theme.colors.grey[650],
    fontSize: '14px',
    marginTop: '24px',
    position: "relative",
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    width: '100%',  
    '& a': {
      color: theme.colors.primaryBlue[500],
    },
    '& div.youtube-embed-wrapper': {
      borderRadius: '12px',
    },
    '& iframe[title*="YouTube"]': {
      borderRadius: '12px',
    },
  },
  spinner: {
    '& svg': {
      width: '116px',
      height: '116px',
      [theme.breakpoints.down('sm')]: {
        width: '68px',
        height: '68px',
      },
      color: "#888FAE",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
  },
  timelineBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  showToggleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '0.5rem',
    backgroundColor: theme.colors.white,
  },
  showToggle: {
    color: theme.colors.primaryBlue[500],
    cursor: "pointer",
    fontWeight: '600',
    textTransform: 'uppercase',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  preloadWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  timelineInformation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 30px 8px 30px',
    width: 'calc(100% - 60px)',
    '&:empty': {
      display: 'none',
    },
  },
  popupButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '12px 0px 20px 0px',
    width: '100%',
    gap: '8px',
    justifyContent: 'space-between',
  },
  popupButtonsGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    '& > button': {
      position: 'relative',
    },
  },
  popupButton: {
    height: '30px',
    padding: '5px 12px',
    minWidth: 'unset',
    fontSize: '12px',
    '&.right': {
      marginLeft: 'auto',
    },
    '& > span': {
      '& > svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
}));

type ItemsType = {
  popupData: any;
  className?: any;
  inModal?: boolean;
  displaySkeleton?: boolean;
  disableGallery?: boolean;
  disableMoreContent?: boolean;
  disableButtons?: boolean;
  disableButtonMarkAsViewed?: boolean;
  disableButtonToggleViewed?: boolean;
  disableButtonPostPone?: boolean;
  disableButtonMore?: boolean;
  onClose?: any;
};

const PopupCard: React.FunctionComponent<ItemsType> = ({ popupData, className, inModal, displaySkeleton, disableGallery, disableMoreContent, disableButtons, disableButtonMarkAsViewed, disableButtonPostPone, disableButtonToggleViewed, disableButtonMore, onClose }) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const popupID = popupData.id;
  const configurationData = useAppSelector((state: any) => state.configuration);
  const browserData = useAppSelector((state: any) => state.browser);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const popupService = useAppSelector((state: any) => state.services).popupsService;
  const popupCategoryEl: any = useRef({clientWidth: 0});
  const popupCategories = useMemo(() => configurationData.configuration.popupCategories ? configurationData.configuration.popupCategories : [], [configurationData.configuration.popupCategories]);
  const popupCategory = useMemo(() => Object.keys(popupData).length !== 0 ? (popupCategories.filter((category: any) => category.popupCategoryID === popupData.categoryID).length === 0 ? null : popupCategories.find((category: any) => category.popupCategoryID === popupData.categoryID)) : null, [popupCategories, popupData]);
  const popupCategoryBackgroundColor = useMemo(() => popupCategory ? hexToRgba(popupCategory.color, '0.2') : '', [popupCategory]);
  const popupCategoryColor = useMemo(() => popupCategory ? popupCategory.color : '', [popupCategory]);

  const queryString = getQueryString(location);

  const [state, setState] = useStates({
    isLoaded: false,
    popupCategoryActive: false,
    popupCategoryWidth: 0,
    postPoned: false,
  });

  const [popupLocalData, setPopupLocalData] = useStates(popupData);

  const classes = useStyles({
    popupCategoryWidthParam: state.popupCategoryWidth,
    popupCategoryBackgroundColorParam: popupCategoryBackgroundColor,
    popupCategoryColorParam: popupCategoryColor,
    inModal: inModal,
  });
    
  useEffect(() => {
    if(popupCategoryEl && popupCategoryEl.current) {
      const popupCategoryCurrentWidth: any = popupCategoryEl.current.clientWidth;
      setState("popupCategoryWidth", popupCategoryCurrentWidth);
      setState("popupCategoryActive", false);
      setState("isLoaded", true);
    }  
  }, [state.isLoaded, browserData.width, languageData.language, setState], [state.isLoaded, browserData.width, languageData.language]);

  const handleCategoryActive = useCallback(() => {
    setState("popupCategoryActive", !state.popupCategoryActive);
  }, [setState, state.popupCategoryActive]);

  const handleSetPopupAsViewed = () => {
    const settings = {
      viewed: true,
    };
    popupService && popupService.settingsPopup(popupID, settings).then((result: any) => {
      if(result) {
        if(result.status === 200) {
          if(onClose) {
            onClose();
          }
          createNotification(t("popup_marked_as_viewed"), "success");
        } else {
          createNotification(t("popup_failed_marked_as_viewed"), "error");
        }
      } else {
        createNotification(t("popup_failed_marked_as_viewed"), "error");
      }
    }).catch(() => {
      createNotification(t("popup_failed_marked_as_viewed"), "error");
    });
  };

  const handleSetPopupToggleViewed = () => {
    const newState = !popupLocalData.viewed;
    const settings = {
      viewed: newState,
    };
    popupService && popupService.settingsPopup(popupID, settings).then((result: any) => {
      if(result) {
        if(result.status === 200) {
          setPopupLocalData("viewed", newState)
          createNotification(newState ? t("popup_marked_as_viewed") : t("popup_marked_as_unviewed"), "success");
        } else {
          createNotification(newState ? t("popup_failed_marked_as_viewed") : t("popup_failed_marked_as_unviewed"), "error");
        }
      } else {
        createNotification(newState ? t("popup_failed_marked_as_viewed") : t("popup_failed_marked_as_unviewed"), "error");
      }
    }).catch(() => {
      createNotification(newState ? t("popup_failed_marked_as_viewed") : t("popup_failed_marked_as_unviewed"), "error");
    });
  };

  const handlePopupPostpone = async () => {
    if(state.postPoned === false) {
      setState("postPoned", true);
      const result = await saveUserSettings(dispatch, userData, "customizations", ["app", "popup_postpone"], moment());
      if(result) {
        createNotification(t("popup_postponed", {minutes: config.NEWS_POSTPONE_LIMIT}), "success");
        setState("postPoned", false);
      } else {
        createNotification(t("popup_failed_postpone"), "error");
        setState("postPoned", false);
      }
      if(onClose) {
        onClose();
      }
    }
  };

  const handleViewMorePopups = () => {
    if(onClose) {
      onClose();
    }
    navigate(`/news${queryString}`);
  };


  return (state.isLoaded && !displaySkeleton) ? (
    <div id={`popupCard_${popupID}`} className={`${classes.popupCard} ${className ? className : ''}`} data-cy={`popupCard${popupID}`}>
      <div className={classes.timelineHeader}>
        <div className={classes.detailInformation}>
          <div>
            <span className={classes.title} data-cy={isCypress() ? "popupCardTitle" : null}>
              {popupLocalData.title}
            </span> 
            <span data-clarity-unmask="true" className={classes.date} data-cy={isCypress() ? "popupCardDate" : null}>
              <SVG src="clock"/>
              {DateFormat(popupLocalData.date,"timeline", languageData, t)}
            </span>
          </div>
          {
            popupCategory ? (
              <div className={`${classes.popupCategory} ${state.popupCategoryActive ? 'active' : null}`} onClick={handleCategoryActive} ref={popupCategoryEl} data-cy={isCypress() ? "popupCardpopupCategory" : null}>
                <span>{popupCategory.title}</span>
              </div>
            ) : null
          }      
        </div>
      </div>
      <div className={classes.timelineContent}>
        {
          (popupLocalData.content && popupLocalData.content !== "") ? (
            <span className={classes.description} data-cy={isCypress() ? "popupCardContent" : null}>
              <Clamp
                lines={4}
                maxLines={100}
                withToggle={!disableMoreContent}
                withTooltip={!disableMoreContent}
                showMoreElement={({ toggle }: any) => (
                  <div className={classes.showToggleWrapper}>
                    <span className={classes.showToggle} onClick={toggle}>
                      {t('show_more')}
                    </span>
                  </div>
                )}
                showLessElement={({ toggle }: any) => (
                  <div className={classes.showToggleWrapper}>
                    <span className={classes.showToggle} onClick={toggle}>
                      {t('show_less')}
                    </span>
                  </div>
                )}
              >
                {htmlParse(popupLocalData.content)}
              </Clamp>
            </span>
          ) : null
        }
        {
          (!disableGallery && popupLocalData.media.length) ? (
            <PopupGallery popupData={popupLocalData}/>
          ) : null
        }
        {
          !disableButtons ? (
            <div className={classes.popupButtons}>
              <div className={classes.popupButtonsGroup}>
              {
                !disableButtonPostPone ? (
                  <NormalButton onClick={handlePopupPostpone} className={classes.popupButton} buttonType="saved" startIcon={<SVG src="clock"/>} tooltip={t('popup_postpone_info')} tooltipMaxWidth={200} tooltipPosition='top'>
                    {t('popup_postpone')}
                  </NormalButton>
                ) : null
              }
              {
                (!disableButtonMore && popupLocalData.isMorePopups) ? (
                  <NormalButton onClick={handleViewMorePopups} className={classes.popupButton} buttonType="secondary" startIcon={<SVG src="news-outlined"/>} tooltip={t('popup_more_info')} tooltipMaxWidth={200} tooltipPosition='top'>
                    {t('popup_more')}
                  </NormalButton>
                ) : null
              }
              </div>
              {
                !disableButtonToggleViewed ? (
                  <div className={classes.popupButtonsGroup}>
                    <NormalButton onClick={handleSetPopupToggleViewed} className={`${classes.popupButton} right`} buttonType="primary" startIcon={<SVG src={popupLocalData.viewed ? "eye-slash" : "eye"}/>} tooltip={t('popup_mark_toggle_viewed_info')} tooltipMaxWidth={200} tooltipPosition='top'>
                      {popupLocalData.viewed ? t('popup_mark_as_unviewed') : t('popup_mark_as_viewed')}
                    </NormalButton>
                  </div>
                ) : null
              }
              {
                !disableButtonMarkAsViewed ? (
                  <div className={classes.popupButtonsGroup}>
                    <NormalButton onClick={handleSetPopupAsViewed} className={`${classes.popupButton} right`} buttonType="primary" startIcon={<SVG src="eye"/>} tooltip={t('popup_mark_as_viewed_info')} tooltipMaxWidth={200} tooltipPosition='top'>
                      {t('popup_mark_as_viewed')}
                    </NormalButton>
                  </div>
                ) : null
              }
            </div>
          ) : null
        } 
      </div>
    </div>
  ) : (
    <div className={`${classes.popupCard} ${className ? className : ''}`}>
      <div className={classes.timelineHeader}>
        <div className={classes.date}>
          <div className={classes.preloadWrapper}>
            <Skeleton variant="text" animation="wave" width={100} height={10}/>
          </div>
        </div>     
      </div>
      <div className={classes.timelineContent}>
        <div className={classes.title}>
          <div className={classes.preloadWrapper}>
            <Skeleton variant="text" animation="wave" width={150} height={20}/>
          </div>
        </div>
         <div className={classes.description}>
          <div className={classes.preloadWrapper}>
            <Skeleton variant="rectangular" animation="wave" width={350} height={50}/>
          </div>
        </div>      
      </div>
      <div className={classes.popupButtons}>
        <Skeleton variant="rectangular" animation="wave" width={90} height={30}/>
      </div>
    </div>
  );
}

export default PopupCard;
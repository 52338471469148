import BreakSelect from './components/BreakSelect';
import ChildSelect from './components/ChildSelect';
import ClassSelect from './components/ClassSelect';
import Employees from './components/Employees';
import EmployeeSelect from './components/EmployeeSelect';
import React from 'react';
import Rooms from './components/Rooms';
import RoomSelect from './components/RoomSelect';
import SchoolSelect from './components/SchoolSelect';
import Subjects from './components/Subjects';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import TimetableEdit from './components/TimetableEdit';
import TimetableSelect from './components/TimetableSelect';
import TimetableSettings from './components/TimetableSettings';
import TimetableViewParent from './components/TimetableViewParent';
import TimetableViewTeacher from './components/TimetableViewTeacher';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { Navigate, useLocation } from 'react-router';
import { setTimetableChild, setTimetableClass, setTimetableEmployee, setTimetableSchool, setTimetableViewMode } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';

const useStyles = createUseStyles((theme: any) => ({
  timetablePage: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    padding: '24px',
    height: 'calc(100% - 48px)',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const location = useLocation();
  const timetableData = useAppSelector((state: any) => state.timetable);
  const userData = useAppSelector((state: any) => state.user);

  const queryString = getQueryString(location);

  const userAccess = userData.userAccess;

  useEffect(() => {
    return () => {
      dispatch(setTimetableClass(null));
      dispatch(setTimetableChild(null));
      dispatch(setTimetableEmployee(null));
      dispatch(setTimetableSchool(null));
      dispatch(setTimetableViewMode("schoolSelect"));
    };
  }, [dispatch], []);

  return !userAccess.calendar ? (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  ) : (
    <div className={classes.timetablePage}>
    {
      !userAccess.subscription ? (
        <SubscriptionBanner service="timetable" isInCenter={true}/>
      ) : (
        <>
        {
          getUserRole(userData.userObject.roleType) === "director" ? (
            <div className={classes.wrapper}>
              {
                timetableData.viewMode === "schoolSelect" ? (
                  <SchoolSelect/>
                ) : timetableData.viewMode === "classSelect" ? (
                  <ClassSelect/>
                ) : timetableData.viewMode === "employeeSelect" ? (
                  <EmployeeSelect/>
                ) : timetableData.viewMode === "childSelect" ? (
                  <ChildSelect/>
                ) : timetableData.viewMode === "breakSelect" ? (
                  <BreakSelect/>
                ) : timetableData.viewMode === "roomSelect" ? (
                  <RoomSelect/>
                ) : timetableData.viewMode === "timetableSettings" ? (
                  <TimetableSettings/>
                ) : timetableData.viewMode === "timetableSelect" ? (
                  <TimetableSelect/>
                ) : timetableData.viewMode === "rooms" ? (
                  <Rooms/>
                ) : timetableData.viewMode === "subjects" ? (
                  <Subjects/>
                ) : timetableData.viewMode === "employees" ? (
                  <Employees/>
                ) : timetableData.viewMode === "timetableEdit" ? (
                  <TimetableEdit/>
                ) : null
              }
            </div>
          ) : (
            <div className={classes.wrapper}>
              {
                getUserRole(userData.userObject.roleType) === "parent" ? (
                  <TimetableViewParent/>
                ) : (
                  <TimetableViewTeacher/>
                )
              }
            </div> 
          )
        }
        </>
      )
    }
    </div>
  );
};

export default PageTemplate;
import CircularProgress from '@mui/material/CircularProgress';
import config from 'src/constants/config';
import EndOfScroll from '../../../components/Layouts/EndOfScroll';
import moment from 'src/utils/moment';
import React, { useCallback, useMemo, useRef } from 'react';
import Sidebar from 'src/components/Layouts/Sidebar';
import SpeedDialButton from 'src/components/Buttons/SpeedDialButton';
import SVG from '../../../components/Images/SvgRenderer';
import TimelineCard from '../../../components/Cards/TimelineCard';
import TimelineHeader from './components/TimelineHeader';
import { createUseStyles } from 'react-jss';
import { getActiveFilters, getFiltersKeys, getFiltersKeysArray, getQueryParams } from 'src/utils/useFunctions';
import { getUserRole, getUserSetting, saveUserSettings } from '../../../utils/useUser';
import { InView } from 'react-intersection-observer';
import { resetFilterParams, setFilterParams, setFiltersSettings, setIsFilterParamsLoaded, setIsFilterSettingsLoaded } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { setPostCreateModal, setWarningModal } from '../../../store/actions/modals.actions';
import { setTimelinePosts } from '../../../store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isStandalone: any;
  isEmbed: any;
};

const useStyles = createUseStyles((theme: any) => ({
  timelinePage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: 'auto',
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapperColumn: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  timelineWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '20px',
  },
  timelineCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  timelineCardInView: {
    width: '100%',
    height: 'auto',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
  addButton: {
    position: (props: Props) => {
      if(props.isEmbed) return 'fixed';
      else return 'absolute';
    },
    zIndex: theme.zIndex.addButton,
    right: (props: Props) => {
      if(props.isEmbed) return '14%';
      else return '18%';
    },
    bottom: (props: Props) => {
      if(props.isStandalone) return '10px';
      else return '10px';
    },
    [theme.breakpoints.down('sm')]: {
      right: '10px',
      bottom: (props: Props) => {
        if(props.isStandalone) return '76px';
        else return '66px';
      },
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const userData = useAppSelector((state: any) => state.user); 
  const timelineService = useAppSelector((state: any) => state.services).timelineService;

  const isLoading = useRef(false);

  const queryParams = getQueryParams(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;

  const posts = [...timelineData.posts];
  const sortedPosts = posts.sort((a: any, b: any) => moment(b.created).diff(moment(a.created)));

  const classes = useStyles({
    isStandalone: browserData.isStandalone,
    isEmbed: isEmbed,
  });

  const [state, setState, setStates] = useStates({
    isFirstTime: true,
    isLoadingDefault: true,
    isLoadingMore: false,
    isEndOfScroll: false,
    isSpeedDialOpen: false,
    page: 1,
    lastFilterParams: [],
  });

  const limit = config.TIMELINE_POSTS_LIMIT;
  const activeClasses = useMemo(() => dataData.classes.filter((theclass: any) => theclass.isArchived === false && theclass.active === true).length, [dataData.classes]);

  const loadTimeline = useCallback((defaultPosts?: boolean) => {
    if(state.isLoadingMore) return;
    if(state.isEndOfScroll && !defaultPosts) return;
    let currentPage = state.page;
    const oldPosts = defaultPosts ? [] : timelineData.posts;
    if(defaultPosts) {
      currentPage = 1;
      setStates({
        isEndOfScroll: false,
      });
    } else {              
      setStates({
        isEndOfScroll: false,
        isLoadingDefault: false,
        isLoadingMore: true,
      });
      currentPage++;
    } 
    if(currentPage !== state.page || currentPage === 1) {
      const filtersParams = getFiltersKeys(filtersData.filterParams, {'timelineFavorite': 'favorite'});
      const getFiltersParams = {...filtersParams, page: currentPage, limit: limit};
      if(JSON.stringify(state.lastFilterParams) === JSON.stringify(getFiltersParams)) return;
      isLoading.current = true;
      setStates({
        lastFilterParams: getFiltersParams,
        isLoadingDefault: defaultPosts
      });
      timelineService && timelineService.listPosts(getFiltersParams).then((result: any) => {
        if(result && result.data && result.data.posts) {
          const resultPosts = result.data.posts;
          if(resultPosts.length === 0) {
            if(JSON.stringify({...state.lastFilterParams, page: '', limit: ''}) !== JSON.stringify({...getFiltersParams, page: '', limit: ''})) {
              dispatch(setTimelinePosts([]));
            }
            setStates({
              isEndOfScroll: true,
              isLoadingMore: false,
            });
            setTimeout(() => { 
              setState("isLoadingDefault", false);
              isLoading.current = false;
            }, 1);           
          } else {
            const newPosts = resultPosts.map((post: any) => {
              const childrenID = post.children.map((child: any) => child.childID);
              const uniqueID = post.postID + "_" + childrenID.join();
              return { ...post, uniqueID };
            });
            const posts = [].concat(oldPosts, newPosts);
            dispatch(setTimelinePosts(posts));
            setState("page", currentPage);
            setTimeout(() => {  
              setStates({
                isLoadingDefault: false,
                isLoadingMore: false,
              });
              isLoading.current = false;
            }, 1); 
          }
        } 
      });
    }  
  }, [dispatch, filtersData.filterParams, setState, setStates, state.isEndOfScroll, state.isLoadingMore, state.page, state.lastFilterParams, timelineData.posts, timelineService, limit]);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && isLoading.current === false) {
      loadTimeline(true);
    }
  }, [filtersData.filterParams, dispatch, loadTimeline, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams, filtersData.isFilterParamsLoaded, languageData.language]);
  
  const onScrollView = (inView: any) => {
    if(inView) {
      if(!state.isLoadingMore && !state.isEndOfScroll) loadTimeline(false);
    }
  };
  
  const handleSpeedDial = () => {
    if(activeClasses > 0) {
      setState("isSpeedDialOpen", !state.isSpeedDialOpen);
    } else {
      const settings = {
        isOpen: true,
        title: t('function_unavailable'),
        content: t('function_unavailable_create_post'),
      };
      dispatch(setWarningModal(settings));
    }
  };
  
  const handleCreate = (postType: any) => {
    const settings = {
      isOpen: true,
      postType: postType,
    };
    dispatch(setPostCreateModal(settings));
  };

  const speedDialItems = [
    {
      name: t('create_post_video'),
      icon: <SVG src="video"/>,
      onClick: () => handleCreate(3),
    },
    {
      name: t('create_poll'),
      icon: <SVG src="poll"/>,
      onClick: () => handleCreate(2),
    },
    {
      name: t('create_post'),
      icon: <SVG src="post"/>,
      onClick: () => handleCreate(1),
    }
  ];

  const handleOnScroll = () => {
    document.dispatchEvent(new CustomEvent('scroll'))
  };

  const saveFilters = useCallback(async (filterParam: any) => {
    if(getUserSetting(userData.userSettings, "addons", ["timeline", "timeline_filters_save"])) {
      const filtersParams = getFiltersKeysArray(filterParam, {'favorite': 'timelineFavorite'});
      await saveUserSettings(dispatch, userData, "filters", ["timeline"], filtersParams);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if(filtersData.isFilterSetupLoaded && !filtersData.isFilterSettingsLoaded && !filtersData.isFilterParamsLoaded) {
      const settings = {
        isAllowedPostID: true,
        isAllowedPaymentID: false,
        isAllowedPostType: true,
        isAllowedGalleryType: false,
        isAllowedActivityType: true,
        isAllowedChildID: true,
        isAllowedEmployeeID: false,
        isAllowedDate: true,
        isAllowedAuthors: true,
        isAllowedPolls: getUserRole(userData.userObject.roleType) === "parent",
        isAllowedClasses: true,
        isAllowedSchools: true,
        isAllowedTimelineFavorite: true,
        isAllowedGalleryFavorite: false,
        isAllowedGalleryHide: false,
        isAllowedPaymentMethod: false,
        isAllowedPaymentStatus: false,
        isAllowedPaymentType: false,
        isAllowedCurrencyID: false,
        isAllowedArchived: false,
      };
      dispatch(setFiltersSettings(settings));
      setTimeout(() => {
        dispatch(setIsFilterSettingsLoaded(true));
      }, 100);
    }
  }, [dispatch, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded, userData.userObject.roleType], [filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded, filtersData.isFilterParamsLoaded]);

  useEffect(() => {
    if(!filtersData.isFilterParamsLoaded && filtersData.isFilterSettingsLoaded) {
      if(getUserSetting(userData.userSettings, "addons", ["timeline", "timeline_filters_save"])) {
        const customFilters = getUserSetting(userData.userSettings, "filters", ["timeline"]);
        if(Array.isArray(customFilters) && customFilters.length > 0) {
          customFilters.forEach((item: any) => {
            dispatch(setFilterParams({[item.key]: item.value}));
          });
          if(getUserSetting(userData.userSettings, "addons", ["app", "app_filter_autoopening"])) {
            dispatch(setIsFiltersVisible(true));
          }
        } else {
          dispatch(setFilterParams({}));
        }
      } else {
        dispatch(setFilterParams({}));
      }
      dispatch(setIsFilterParamsLoaded(true));
    }
  }, [dispatch, userData.userSettings, filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded], [filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    dispatch(setTimelinePosts([]));
    dispatch(setIsFilterParamsLoaded(false));
    dispatch(setIsFilterSettingsLoaded(false));
    return () => {
      dispatch(setTimelinePosts([]));
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(resetFilterParams());
    }
  }, [dispatch], []);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && !state.isFirstTime) {
      saveFilters(filtersData.filterParams);
    } else {
      setState("isFirstTime", false);
    }
  }, [saveFilters, setState, state.isFirstTime, filtersData.filterParams, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams]);

  return (
    <div className={classes.timelinePage} onScroll={handleOnScroll}>
      <div className={classes.wrapper}>
        <div className={classes.wrapperColumn}>
          <div className={classes.timelineWrapper}>
            {
              getUserRole(userData.userObject.roleType) !== "parent" ? (
                <TimelineHeader/>
              ) : null
            }
            {
              ((isLoading.current === true && timelineData.posts.length === 0) || state.isLoadingDefault) ? (
                <div className={classes.spinner}>
                  <CircularProgress/>
                </div>
              ) : (sortedPosts && sortedPosts.length === 0) ? (
                  <div className={classes.notFound}>
                    <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                    <span>{t('no_results')}</span>
                    <p>{(state.page === 1 && getActiveFilters(filtersData.filterParams) === 0) ? t('no_results_found') : t('filters_or_search_no_results')}</p>
                  </div>
                ) : sortedPosts.map((data: any, key: any) => {
                  return (key === sortedPosts.length - 1) ? (
                    <div className={classes.timelineCardWrapper} key={`k_${key}`}>
                      <InView className={classes.timelineCardInView} onChange={(inView) => onScrollView(inView)}>
                        <TimelineCard uniqueID={data.uniqueID}/>
                      </InView>
                      {
                        (state.isLoadingMore && !state.isEndOfScroll) ? (
                          <div className={classes.spinner}>
                            <CircularProgress/>
                          </div>
                        ) : null
                      }
                      {
                        state.isEndOfScroll ? ( 
                          <EndOfScroll text={t("no_more_posts")}/>
                        ) : null
                      }
                    </div>
                  ) : (
                    <div className={classes.timelineCardWrapper} key={`k_${key}`}>
                      <TimelineCard uniqueID={data.uniqueID}/>
                    </div>
                  )
                }
              )
            }
          </div>
        </div>
        <Sidebar/>
        {
          getUserRole(userData.userObject.roleType) !== "parent" ? (
            <SpeedDialButton className={classes.addButton} items={speedDialItems} onClick={handleSpeedDial} isOpen={state.isSpeedDialOpen} isTooltipOpen={true} isHidden={modalsData.postCreateModal.isOpen} dataCy="timelineAddButton"/>
          ) : null
        }
      </div>
    </div>
  );
};

export default PageTemplate;
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { getElementID } from '../../../utils/useUUID';
import { isCypress } from '../../../utils/useCypress';
import { Tooltip } from '@mui/material';

interface Props {
  maxWidth: any;
  disabled: boolean;
};

const useStyles = createUseStyles((theme: any) => ({
  checkboxWrapper: {
    display: "inline-flex",
    alignItems: "center",
  },
  checkbox: {
    color: theme.colors.grey[700],
    '&.Mui-checked': {
      color: theme.colors.primaryBlue[500],
    }
  },
  checkboxLabel: {
    fontSize: "14px",
    cursor: (props: Props) => {
      if(props.disabled) return 'default';
      else return 'pointer';
    },
    whiteSpace: 'nowrap',
    color: (props: Props) => {
      if(props.disabled) return theme.colors.grey[700];
      else return '';
    },
  },
  tooltipWidth: {
    maxWidth: (props: Props) => props.maxWidth + 'px',
  },
}));

type CheckboxType = {
  name?: any;
  checked?: any;
  value?: any;
  label?: any;
  labelLeft?: any;
  useName?: boolean;
  checkedIcon? :any;
  icon?: any;
  disabled?: any;
  onChange?: any;
  onClick?: any;
  className?: any;
  tooltip?: any;
  tooltipPosition?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  tooltipArrow?: boolean;
  tooltipMaxWidth?: any;
  dataCy? : any;
};

const CheckboxInput: React.FunctionComponent<CheckboxType> = ({name, checked, value, labelLeft, label, useName = false, checkedIcon = <CheckBoxIcon/>, icon = <CheckBoxOutlineBlankIcon/>, disabled, onChange, onClick, className, tooltip, tooltipPosition = "right", tooltipArrow = true, tooltipMaxWidth = '100', dataCy}) => {

  const classes = useStyles({
    maxWidth: tooltipMaxWidth,
    disabled: disabled,
  });
  
  const uid = getElementID();

  const handleOnChange = (e: any) => {
    if(onChange) {
      if(useName) {
        onChange(name, value, !checked, e);
      } else {
        onChange(value, !checked, e);
      }
    }
  };

  return (
    <div className={`${classes.checkboxWrapper} ${className ? className : null}`}>
      {
        labelLeft ? (
          <label htmlFor={uid} className={classes.checkboxLabel} data-cy={isCypress() ? dataCy + 'LabelLeft' : null}>{labelLeft}</label>
        ) : null
      }
      {
        (tooltip && !disabled) ? (
          <Tooltip title={tooltip} classes={{ tooltip: classes.tooltipWidth }} placement={tooltipPosition} arrow={tooltipArrow}>
            <Checkbox id={uid} name={name ? name : uid} className={classes.checkbox} checked={checked} value={value} icon={icon} checkedIcon={checkedIcon} onChange={handleOnChange} onClick={disabled ? null : onClick} disabled={disabled} data-cy={isCypress() ? dataCy : null}/>
          </Tooltip>
        ) : (
          <Checkbox id={uid} name={name ? name : uid} className={classes.checkbox} checked={checked} value={value} icon={icon} checkedIcon={checkedIcon} onChange={handleOnChange} onClick={disabled ? null : onClick} disabled={disabled} data-cy={isCypress() ? dataCy : null}/>
        )
      }
      {
        label ? (
          <label htmlFor={uid} className={classes.checkboxLabel} data-cy={isCypress() ? dataCy + 'Label' : null}>{label}</label>
        ) : null
      }
    </div>
  );
}

export default CheckboxInput;
import BottomMenu from '../../components/Menus/BottomMenu';
import config from '../../constants/config';
import Filters from 'src/components/Layouts/Filters';
import Input from 'src/components/Forms/Input';
import LeftMenu from '../../components/Menus/LeftMenu';
import menuItems from '../../constants/menu';
import ProfileMenu from '../../components/Menus/ProfileMenu';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import SideContent from 'src/components/Layouts/SideContent';
import SVG from '../../components/Images/SvgRenderer';
import theme from '../../ui/theme';
import ToolbarMenu from 'src/components/Menus/ToolbarMenu';
import TopBarButton from 'src/components/Buttons/TopBarButton';
import useBreakpoint from 'src/utils/useBreakpoint';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createUseStyles } from 'react-jss';
import { getAppEnvironmentApiData } from 'src/utils/useApp';
import { getSideContentSize, mergeMenuItems, parseEnviromentName } from 'src/utils/useFunctions';
import { getUserHomepage, getUserRole, getUserSetting } from '../../utils/useUser';
import { isCypress } from '../../utils/useCypress';
import { IsLocation } from 'src/utils/useLocation';
import { resetFilterParams, setFilterParams } from 'src/store/actions/filters.actions';
import { setBrowserHeight, setBrowserIsStandalone, setBrowserWidth } from '../../store/actions/browser.actions';
import { setChangelogModal } from 'src/store/actions/modals.actions';
import { setContainerHeight, setCurrentMenu, setIsFiltersVisible, setIsSearchVisible } from 'src/store/actions/layout.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from '../../utils/useState';
import { useTranslation } from 'react-i18next';
import { useWindowResize } from '../../utils/useWindowResize';

interface Props {
  children: any;
};

interface ClassesProps {
  containerHeight?: any;
  isDisplayContent?: any;
  isFiltersVisible?: any;
  isSideContent?: any;
  sideContentSize: any;
};

const useStyles = createUseStyles((theme: any) => ({
  mainLayout: {
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
    position: 'relative',
    display: 'flex',
  }, 
  mainContent: {
    flex: '1 1 100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  sideContent: {
    display: (props: ClassesProps) => {
      if(props.isSideContent) return 'flex';
      else return 'none';
    },
    width: (props: ClassesProps) => `${props.sideContentSize}%`,
    maxWidth: (props: ClassesProps) => `${props.sideContentSize}%`,
    flex: (props: ClassesProps) => `0 0 ${props.sideContentSize}%`,
    height: '100%',
  },  
  topBar: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    width: (props: ClassesProps) => {
      if(props.isSideContent) return `${100 - props.sideContentSize}%`;
      else return '100%';
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',
    backgroundColor: theme.colors.white,
    zIndex: theme.zIndex.topBar,
    boxShadow: theme.shadows[2],
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '20px',
    transform: 'translateY(-50%)',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: theme.zIndex.logo,
    '&.disabled': {
      cursor: 'auto',
    },
    '& > svg': {
      width: 'auto',
      height: '34px',
      [theme.breakpoints.down('md')]: {
        height: '64px',
      },    
    },
    '& > span': {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '100%',
      fontSize: '10px',
      fontWeight: '600',
      backgroundColor: theme.colors.chip,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[350],
      borderRadius: '20px',
      width: 'fit-content',
      padding: '0 4px',
      height: '20px',
      cursor: 'help',
    },
  },
  toolbar: {
    position: 'absolute',
    top: '50%',
    right: '20px',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    zIndex: theme.zIndex.toolbar,
  },
  container: {
    position: 'fixed',
    top: '64px',
    left: '0',
    right: '0',
    display: 'flex',
    width: (props: ClassesProps) => {
      if(props.isSideContent) return `${100 - props.sideContentSize}%`;
      else return '100%';
    },
    height: (props: ClassesProps) => props.containerHeight.toString() + 'px',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: theme.zIndex.container,
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flex: '0 0 240px',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    height: '100%',
    flex: '1 1 100%',
    [theme.breakpoints.down('md')]: {
      flex: '0 0 100%',
    }, 
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: (props: ClassesProps) => props.containerHeight.toString() + 'px',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
    '&::before': {
      display: 'block',
      content: `''`,
      width: '100%',
      minHeight: (props: ClassesProps) => {
        if(props.isFiltersVisible) return '60px';
        else return '0px';
      },
      transition: 'min-height 0.5s',
    },
    '& *': {
      display: (props: ClassesProps) => {
        if(!props.isDisplayContent) return '';
        else return '';
      },
    },
    '& > div': {
      flex: '1 1 auto',
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.1rem',
    width: '100%',
    marginBottom: '1rem',
    maxWidth: 'calc(100% - 2rem)',
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    width: '100%',
    maxWidth: 'calc(100% - 2rem)',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '12px',  
      whiteSpace: 'nowrap',
      '& > span': {
        fontWeight: 'bold',
      },
      '&:last-of-type': {
        cursor: 'pointer',
        transition: 'all 0.25s',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  searchBar: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
    maxWidth: '50%',
    zIndex: theme.zIndex.searchBar,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 26px)',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: theme.colors.white,
      transform: 'unset',
      marginLeft: '0',
      padding: '0 13px',
    },
  },
}));

const MainLayout: React.FunctionComponent<Props> = (props: Props) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();

  const isDisplayContent = useMemo(() => layoutData.isModalFullscreen ? false : true, [layoutData.isModalFullscreen]);
  const breakpoint: any = useBreakpoint();

  const isBranch = config.ENVIRONMENT === "review";
  const branch = config.BRANCH;
  
  const topBarEl: any = useRef({clientHeight: 0});
  const bottomBarEl: any = useRef({clientHeight: 0});
  const topBarHeight = topBarEl.current ? topBarEl.current.clientHeight : 0;
  const bottomBarHeight = bottomBarEl.current ? bottomBarEl.current.clientHeight : 0;
  
  const [browserWidth, browserHeight] = useWindowResize();
  const windowHandler: any = window;
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const mainMenuItems = menuItems[layoutData.currentMenu].filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));
  const mainMenuItemSettings = getUserSetting(userData.userSettings, "customizations", [`${layoutData.currentMenu}menu`]);
  const mainMenuItemsUpdate = mainMenuItemSettings ? mergeMenuItems(mainMenuItems, mainMenuItemSettings) : mainMenuItems;

  const profileMenuItems = menuItems.profile.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const [state, setState] = useStates({
    containerHeight: '0',
    search: '',
  });

  const classes = useStyles({
    containerHeight: state.containerHeight,
    isDisplayContent: isDisplayContent,
    isFiltersVisible: layoutData.isFiltersVisible,
    isSideContent: layoutData.sideContent !== null,
    sideContentSize: getSideContentSize(breakpoint),
  });
  
  useEffect(() => {
    const topBarCurrentHeight: any = topBarHeight;
    const bottomCurrentBarHeight: any = bottomBarHeight;
    const containerCurrentHeight: any = browserHeight - topBarCurrentHeight - (mainMenuItemsUpdate.length === 0 ? 0 : bottomCurrentBarHeight);
    const isStandalone = windowHandler.matchMedia('(display-mode: standalone)').matches;
    setState("containerHeight", containerCurrentHeight);
    dispatch(setContainerHeight(containerCurrentHeight));
    dispatch(setBrowserWidth(browserWidth));
    dispatch(setBrowserHeight(browserHeight));   
    dispatch(setBrowserIsStandalone(isStandalone));
  }, [browserWidth, browserHeight, windowHandler, dispatch, setState, mainMenuItemsUpdate, topBarHeight, bottomBarHeight], [browserWidth, browserHeight, windowHandler, topBarHeight, bottomBarHeight]);
  
  const handleLogoClick = () => {
    dispatch(setCurrentMenu("main"));
    navigate(getUserHomepage(dispatch, dataData, layoutData, userData));
  };

  const handleSearch = (value: any) => {
    setState("search", value);
  };

  const handleSearchClick = () => {
    if(layoutData.isFiltersVisible) {
      dispatch(setIsFiltersVisible(false));
      dispatch(resetFilterParams());
    }
    if(filtersData.filterParams.search !== null) {
      dispatch(setFilterParams({search: null}));
    }
    dispatch(setIsSearchVisible(!layoutData.isSearchVisible));
  };

  const handleOnClickVersion = () => {
    //dispatch(setIsOpenWelcomeScreen(true));
    const settings = {
      isOpen: true,
      isAutoOpen: false,
    };
    dispatch(setChangelogModal(settings));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(state.search.length > 0) {
        if(state.search !== filtersData.filterParams.search) {
          dispatch(setFilterParams({search: state.search}));
        }
      } else {
        if(filtersData.filterParams.search !== null) {
          dispatch(setFilterParams({search: null}));
        }
      }
    }, 2000);
    return () => {
      clearTimeout(delayDebounceFn);
    }
  }, [state.search, dispatch, filtersData.filterParams.search], [state.search]);

  return (
    <div className={classes.mainLayout} data-cy={isCypress() ? `mainLayout` : null}>
      <div className={classes.mainContent}>
        <div className={classes.topBar} ref={topBarEl} data-cy={isCypress() ? `mainLayoutTopBar` : null}>
          <div className={classes.logo} onClick={handleLogoClick} data-cy={isCypress() ? `mainLayoutLogo` : null}>
            <SVG src={smallDevice ? config.APP_LOGO_SMALL : config.APP_LOGO}/>
            {
              config.APP_ENVIRONMENT !== "production" ? (
                <Tooltip title={getAppEnvironmentApiData().name} placement='bottom' arrow>
                  <span>{getAppEnvironmentApiData().shortName}</span>
                </Tooltip>
              ) : null
            }
          </div>
          {
            (IsLocation("/timeline") && layoutData.isSearchVisible) ? (
              <div className={classes.searchBar} data-cy={isCypress() ? `mainLayoutSearchBar` : null}>
                {
                  smallDevice ? (
                    <TopBarButton className={layoutData.isSearchVisible ? 'active' : null} icon={<SVG src="chevron-left"/>} onClick={handleSearchClick}/>
                  ) : null
                }
                <Input useName={false} onKeyDown={handleSearch} onKeyUp={handleSearch}  placeholder={`${t('search')}...`} dataCy='searchInput' autoFocus={true}/>
              </div>
            ) : null
          }
          <div className={classes.toolbar}>
            <ToolbarMenu/>
            <ProfileMenu items={profileMenuItems} dataCy={`mainLayoutProfileMenu`}/>
          </div>
          <Filters/>
        </div>
        <div className={classes.container} data-cy={isCypress() ? `mainLayoutContainer` : null}>
          {
            mainMenuItemsUpdate.length !== 0 ? (
              <div className={classes.sidebar}>
                <LeftMenu items={mainMenuItemsUpdate} dataCy={`mainLayoutLeftMenu`}/>
                <div className={classes.footer} data-cy={isCypress() ? `mainLayoutFooter` : null}>
                  {
                    isBranch ? (
                      <div className={classes.footerRow}>
                        <span data-cy={isCypress() ? `mainLayoutFooterBranch` : null}>
                          {t('branch')} <span>{parseEnviromentName(branch)}</span>
                        </span>
                      </div>
                    ) : null
                  }
                  <div className={classes.footerRow}>
                  <Tooltip title={t('twigsee_since')} arrow>
                    <span data-clarity-unmask="true" data-cy={isCypress() ? `mainLayoutFooterCopyright` : null}>
                      &copy; {t('twigsee')} {new Date().getFullYear()}
                    </span>
                  </Tooltip>
                    <span data-cy={isCypress() ? `mainLayoutFooterDivider` : null}>|</span>
                    <span data-clarity-unmask="true" data-cy={isCypress() ? `mainLayoutFooterVersion` : null} onClick={handleOnClickVersion}>
                      {t('version')} {config.APP_VERSION}
                    </span>
                  </div>
                </div> 
              </div>
            ) : null
          }
          <div className={classes.contentWrapper} data-cy={isCypress() ? `mainLayoutContentWrapper` : null}>
            <div className={classes.content} data-cy={isCypress() ? `mainLayoutContent` : null}> 
              {props.children}
            </div>
          </div>
        </div>
        {
          mainMenuItemsUpdate.length !== 0 ? (
            <BottomMenu items={mainMenuItemsUpdate} customRef={bottomBarEl} dataCy={`mainLayoutBottomMenu`}/>
          ) : null
        }
      </div>
      <SideContent className={classes.sideContent}/>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default MainLayout;
import ChildrenListSelect from 'src/components/Selects/ChildrenListSelect';
import React, { useCallback, useRef } from 'react';
import TimetableTable from 'src/components/Tables/TimetableTable';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { Navigate, useLocation } from 'react-router-dom';
import { setTimetableChild, setTimetableData, setTimetableRooms, setTimetableSchool, setTimetableSettings, setTimetableSubjects, setTimetableType } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: '32px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const TimetableViewParent: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const userData = useAppSelector((state: any) => state.user);
  const timetableWrapper: any = useRef(null);
  const timetableWrapperCurrent = timetableWrapper.current;
  const timetableWrapperClientWidth = timetableWrapperCurrent ? (timetableWrapperCurrent.clientWidth ? timetableWrapperCurrent.clientWidth : 0) : 0;
  const timetableService = useAppSelector((state: any) => state.services).timetableService;

  const queryString = getQueryString(location);

  const userAccessSchools = userData.userAccessSchools;

  const childrenData = dataData.children.filter((item: any) => userAccessSchools.timetable.includes(item.schoolID) && userAccessSchools.subscription.includes(item.schoolID));

  const [state, setState] = useStates({
    isLoading: true,
    childrenListSelectWidth: timetableWrapperCurrent ? timetableWrapperCurrent.clientWidth : 0,
    currentChild: childrenData[0],
  });

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getHours = useCallback((childID: any) => {
    const params = {
      childID: childID,
      schoolID: getChildData(childID).schoolID,
      type: [1],
    };
    timetableService && timetableService.listHours(params).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableData(result.data));
          setState("isLoading", false);
        } else {
          createNotification(t("timetable_failed_load"), "error");
        }
      } else {
        createNotification(t("timetable_failed_load"), "error");
      }
    }).catch(() => {
      createNotification(t("timetable_failed_load"), "error");
    });
  }, [dispatch, t, timetableService, setState, getChildData]);

  const getRooms = useCallback((childID: any) => {
    timetableService && timetableService.listRooms().then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableRooms(result.data));
          getHours(childID);
        } else {
          createNotification(t("timetable_rooms_not_loaded"), "error");
        }
      } else {
        createNotification(t("timetable_rooms_not_loaded"), "error");
      }
    }).catch(() => {
      createNotification(t("timetable_rooms_not_loaded"), "error");
    });
  }, [t, timetableService, dispatch, getHours]);

  const getSubjects = useCallback((childID: any) => {
    timetableService && timetableService.listSubjects().then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableSubjects(result.data));
          getRooms(childID);
        } else {
          createNotification(t("timetable_subjects_not_loaded"), "error");
        }
      } else {
        createNotification(t("timetable_subjects_not_loaded"), "error");
      }
    }).catch(() => {
      createNotification(t("timetable_subjects_not_loaded"), "error");
    });
  }, [t, timetableService, getRooms, dispatch]);

  useEffect(() => {
    if(childrenData.length !== 0) {
      const settings = {
        breaks: false,
        canEdit: false,
        canMultipleHours: false,
        subjectPopup: true,
      };
      dispatch(setTimetableSettings(settings));
      dispatch(setTimetableSchool(getSchoolData(getChildData(state.currentChild.childID).schoolID)));
      getSubjects(state.currentChild.childID);
    }
  }, [timetableData, dispatch, state, getSubjects, getChildData, getSchoolData, childrenData], []);

  const handleCurrentChild = (child: any) => {
    setState("isLoading", true);
    setState("currentChild", child);
    dispatch(setTimetableChild(child));
    dispatch(setTimetableType("child"));
    dispatch(setTimetableSchool(getSchoolData(getChildData(child.childID).schoolID)));
    getSubjects(child.childID);
  };

  useEffect(() => {
    setState("childrenListSelectWidth", timetableWrapperClientWidth);  
  }, [browserData.width, timetableWrapperClientWidth, setState], [browserData.width, timetableWrapperClientWidth]);
  
  return childrenData.length !== 0 ? (
    <div className={classes.wrapper} ref={timetableWrapper}>
      <ChildrenListSelect currentChild={state.currentChild} childrenData={childrenData} setCurrentChild={handleCurrentChild} width={state.childrenListSelectWidth} isDisabled={state.isLoading}/>
      {
        state.isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress/>
          </div>
        ) : (
          <TimetableTable/>
        )
      }
    </div> 
  ) : (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  );
};

export default TimetableViewParent;
const customizations = [
  {
    key: 'mainmenu',
    roles: [
      "parent",
      "teacher",
      "director"
    ],
    items: [],
  },
  {
    key: 'homepage',
    roles: [
      "parent",
      "teacher",
      "director"
    ],
    value: '',
  },
  /*
  {
    key: 'theme',
    roles: [
      "parent",
      "teacher",
      "director"
    ],
    value: 'light',
  },*/
  {
    key: 'communication',
    roles: [
      "parent",
      "teacher",
      "director"
    ],
    items: [
      {
        key: 'autorefresh_threads',
        value: 60000,
      },
      {
        key: 'autorefresh_messages',
        value: 60000,
      }
    ],
  },
  {
    key: 'app',
    roles: [
      "parent",
      "teacher",
      "director"
    ],
    items: [
      {
        key: 'sidecontent',
        value: null,
      },
      {
        key: 'popup_postpone',
        value: null,
      },
    ],
  },
  {
    key: 'calendar',
    roles: [
      "parent",
      "teacher",
      "director"
    ],
    items: [
      {
        key: 'first_day_in_week',
        value: 1,
      },
    ],
  },
];

export default customizations;
import config from 'src/constants/config';
import moment from 'src/utils/moment';
import PopupModal from 'src/components/Modals/PopupModal';
import PropTypes from 'prop-types';
import React from 'react';
import { getQueryParams } from 'src/utils/useFunctions';
import { getUserSetting } from 'src/utils/useUser';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useLocation } from 'react-router';

interface Props {
  children: any;
}

const PopupsController: React.FunctionComponent<Props> = (props:Props) => {

  const location = useLocation();
  const popupsData = useAppSelector((state: any) => state.popups);
  const userData = useAppSelector((state: any) => state.user);
  const popup = popupsData.popup;

  const queryParams = getQueryParams(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const enablePopups = queryParams.get("enablePopups");
  const isEnablePopups = enablePopups === "true" ? true : false;

  const popupPostPoned = getUserSetting(userData.userSettings, "customizations", ["app", "popup_postpone"]);
  const isPopupPostPoned = popupPostPoned ? moment(popupPostPoned).add(config.NEWS_POSTPONE_LIMIT,"minutes").isAfter(moment(),"minutes") : false;
  const isPopupEnabled = isEmbed ? isEnablePopups : true;

  return (
    <>
      {
        (popup !== null && !isPopupPostPoned && isPopupEnabled) ? (
          <PopupModal/>
        ) : null
      }
      {props.children}
    </>
  );
};

PopupsController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default PopupsController;
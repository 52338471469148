import * as AuthService from '../../../services/auth.service';
import CircularProgress from '@mui/material/CircularProgress';
import config from '../../../constants/config';
import CountUp from 'react-countup';
import NormalButton from '../../../components/Buttons/NormalButton';
import React, { useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import { createUseStyles } from 'react-jss';
import { getAppData } from 'src/utils/useApp';
import { getQueryParams, isValidHexColor } from 'src/utils/useFunctions';
import { setDeinitializeUserController } from 'src/store/actions/controllers.actions';
import { setUserStatus } from '../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  backgroundColor?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  loadingScreen: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: theme.zIndex.screen,
    backgroundColor: (props: Props) => props.backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      maxWidth: '60%',
    }, 
  },
  loadingWrapper: {
    position: 'absolute',
    top: '75%',
    transform: 'translateY(-75%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      marginTop: '8px',
    },
  },
  loading: {
    position: 'relative',
  },
  loadingRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    width: '100%',
    maxWidth: '100%',
    position: 'absolute',
    bottom: '43px',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      whiteSpace: 'nowrap',
      '& > span': {
        fontWeight: 'bold',
      },
    },
  },
  buttons: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  failed: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '103px',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > span': {
      textAlign: 'center',
      color: '#EE576C',
      fontWeight: 'bold',
    },
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const LoadingScreen: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();

  const queryParams = getQueryParams(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const background = queryParams.get("background");
  const backgroundColor = isEmbed ? (background === "gradient" ? theme.colors.gradient : (isValidHexColor(`#${background}`) ? `#${background}` : theme.colors.white)) : theme.colors.white;

  const classes = useStyles({
    backgroundColor: backgroundColor,
  });

  const lastValue = useRef(0);

  const [state, setState] = useStates({
    start: 0,
    end: 0,
  });
  
  const handleLogout = () => {
    dispatch(setUserStatus("loggingOff"));
    const savedData = getAppData();
    const savedUsers = savedData.users ? savedData.users : [];
    dispatch(setDeinitializeUserController(true));
    navigate(savedUsers.length > 1 ? "/auth/switch" : "/auth/login");
    window.location.reload();
  };

  const handleClickTryAgain = () => {
    window.location.reload();
  };

  const handleClickLogout = () => {
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  };

  useEffect(() => {
    if(lastValue.current === 0) {
      setState("end", loadingData.userLoadedStatus);
      lastValue.current = loadingData.userLoadedStatus;
    } else {
      setState("start", state.end);
      setState("end", loadingData.userLoadedStatus);
    }
  }, [loadingData.userLoadedStatus, state, setState], [loadingData.userLoadedStatus]);

  return (
    <div className={classes.loadingScreen}>
      <SVG src={config.APP_LOGO}/>
      {
        loadingData.isFailed ? (
          <div className={classes.failed}>
            {
              typeof loadingData.isFailed === "boolean" ? (
                <span>{t('failed_download_data')}!</span>
              ) : (
                <span>{t(`failed_${loadingData.isFailed}`)}</span>
              )
            }
            <div className={classes.buttons}>
              <NormalButton buttonType="primary" onClick={handleClickTryAgain}>
                {t("try_again")}
              </NormalButton>
              {
                Object.keys(userData.userObject).length > 0 ? (
                  <NormalButton buttonType="secondary" onClick={handleClickLogout}>
                    {t("logout")}
                  </NormalButton>
                ) : null
              }
            </div>
          </div>        
        ) : (
          <div className={classes.loadingWrapper}>
            <div className={classes.loading}>  
              <CircularProgress className={classes.spinner}/>
            </div>
            {
              (!loadingData.isUserLoaded && userData.userStatus === "loggedIn") ? (
                <span data-clarity-unmask="true">
                  <CountUp start={state.start} end={state.end} duration={0.5} suffix='%'/>
                </span>
              ) : null
            }
            {
              ( userData.userStatus === "loggingOff") ? (
                <span>{t('loggout_info')}</span>
              ) : null
            }
          </div>
        )
      }
      <div className={classes.loadingRow}>
        <span data-clarity-unmask="true">
          &copy; {t('twigsee')} {new Date().getFullYear()}
        </span>
        {
          loadingData.isLanguageLoaded && !loadingData.isFailed ? (
            <>
              <span>|</span>
              <span data-clarity-unmask="true">{t('version')} {config.APP_VERSION}</span>
              {
                isEmbed ? (
                  <>
                    <span>|</span>
                    <span data-clarity-unmask="true">{t('version_embed')} {config.APP_EMBED_VERSION}</span>
                  </>
                ) : null
              }
            </>
          ) : null
        }
      </div>
    </div>
  );
}

export default LoadingScreen;
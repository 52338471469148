import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import EmojiIcon from 'src/components/Icons/EmojiIcon';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback } from 'react';
import TabsMenu from 'src/components/Menus/TabsMenu';
import UsersSelect from 'src/components/Selects/UsersSelect';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings, handleSum, isKey } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { setReactionDetailModal } from 'src/store/actions/modals.actions';
import { setTimelinePosts } from 'src/store/actions/timeline.actions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  headerWrapper: {
    display: 'block',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    position: 'relative',
    zIndex: 1,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
    '&:only-child': {
      padding: "20px",
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(60vh - 40px)',
    maxHeight: 'calc(60vh - 40px)',
    overflow: 'auto',
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  tabsWrapper: {
    display: 'flex',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    justifyContent: 'center',
    padding: '0 24px',
    '& > div': {
      width: '100%',
    },
  },
  reactionsList: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    gap: '8px',
    padding: '24px',
  },
  reactionUser: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    padding: '4px 8px',
    '& > span': {
      fontWeight: '500',
    },
  },
  reactionUserImageWrapper: {
    position: 'relative',
  },
  reactionUserImage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48px',
    height: '48px',
    position: 'relative',
    borderRadius: '12px',
    backgroundColor: theme.colors.white,
    '& > div': {
      maxWidth: '100%',
      maxHeight: '100%',
      width: 'unset',
      height: 'unset',
      borderRadius: '12px',
    },
  },
  reactionUserEmoji: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '100%',
    width: '24px',
    height: '24px',
    fontSize: '90%',
    lineHeight: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  reactionItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    backgroundColor: theme.colors.white,
    borderRadius: '12px',
    padding: '4px 8px',
    '& > span': {
      fontWeight: '500',
    },
  },
  reactionItemWrapper: {
    position: 'relative',
  },
  reactionItemEmoji: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48px',
    height: '48px',
    position: 'relative',
    borderRadius: '100%',
    fontSize: '180%',
    backgroundColor: theme.colors.white,
  },
  reactionItemCount: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '100%',
    width: '16px',
    height: '16px',
    padding: '4px',
    fontSize: '70%',
    lineHeight: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flex: '1 1 100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

const Post: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const userData = useAppSelector((state: any) => state.user);
  const reactionsService = useAppSelector((state: any) => state.services).reactionsService;
  const uniqueID = useAppSelector((state: any) => state.modals).reactionDetailModal.ID;
  const postData = timelineData.posts.find((post: any) => post.uniqueID === uniqueID);
  const postID = postData.postID;
  const schoolID = postData.schoolID;
  const reactions = useMemo(() => configurationData.configuration.reactions, [configurationData.configuration.reactions]);
  const totalReactions = postData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0);

  const schoolSettings = userData.schoolSettings;
  const isVisibleForParent = getSchoolSettings(schoolID, 'modules', schoolSettings).reaction ? getSchoolSettings(schoolID, 'modules', schoolSettings).reaction.visibleForParent : false;

  const getUserData = (userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  };

  const [state, setState] = useStates({
    viewMode: "all",
    isLoaded: false,
    postReactions: {
      items: [],
      totals: [],
    },
  });
 
  const onClose = ()  => {
    const settings = {
      isOpen: false,
      ID: null,
      type: null,
    };
    dispatch(setReactionDetailModal(settings));   
  };
  
  const onCloseModal = () => {
    onClose();
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleViewMode = (value: any) => {
    setState("viewMode", value);
  };

  const tabsItems = useMemo(() => {
    const defaultTab = [
      {
        name: 'all',
        value: 'all',
        badge: state.postReactions.items.length,
        isEnabled: true,
      },
    ];
    const emojiTabs = state.postReactions.totals.map((item: any) => {
      return {
        name: <EmojiIcon emoji={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).emoji}/>,
        value: item.emojiID,
        badge: item.total,
        isEnabled: true,
      };
    });
    const tabs = defaultTab.concat(emojiTabs);
    return tabs;
  }, [reactions, state.postReactions]);

  const reactionItems = useMemo(() => {
    const tabs = state.postReactions.totals.map((item: any) => {
      return {
        name: <EmojiIcon emoji={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).emoji}/>,
        value: item.emojiID,
        badge: item.total,
        isEnabled: true,
      };
    });
    return tabs;
  }, [reactions, state.postReactions]);

  const updatePostReactions = useCallback((reactionsData: any) => {
    const postData = timelineData.posts.filter((post: any) => post.uniqueID === uniqueID).length === 0 ? 0 : timelineData.posts.find((post: any) => post.uniqueID === uniqueID);
    const postID = postData.postID;
    const newTimelineData = timelineData.posts.map((post: any) => {
      if(post.postID === postID) {
        return { ...post, reactions: {...post.reactions, myEmojiID: reactionsData.myEmojiID, totals: reactionsData.totals} };
      } else {
        return post;
      }
    });
    dispatch(setTimelinePosts(newTimelineData));
  }, [dispatch, timelineData.posts, uniqueID]);

  useEffect(() => {
    if(totalReactions === 0) {
      setState("isLoaded", true);
    } else {
      reactionsService && reactionsService.listReactions(postID, "post").then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.reactions && result.data.reactions.length !== 0) {
              const reactionsData = result.data.reactions[0];
              setState("postReactions", reactionsData);
              updatePostReactions(reactionsData);
              setState("isLoaded", true);
            } else {
              createNotification(t("reactions_not_loaded"), "error");
            }
          } else {
            createNotification(t("reactions_not_loaded"), "error");
          }
        } else {
          createNotification(t("reactions_not_loaded"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("reactions_not_loaded"), "error");
      });
    }
  }, [postID, reactionsService, t, setState, postData.reactions.totals, totalReactions, updatePostReactions], []);

  const customNotFound = (
    <NotFound text={t('no_reactions_found')}/>
  );

  return (
    <>
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          <p>{t('reactions')}</p>
          <CloseButton onClick={handleClose} dataCy="timesButton"/>
        </div>
        {
          (getUserRole(userData.userObject.roleType) === "parent" && isVisibleForParent && totalReactions > 0) || (getUserRole(userData.userObject.roleType) !== "parent" && totalReactions > 0) ? (
            <div className={classes.tabsWrapper}>
              <TabsMenu items={tabsItems} selected={state.viewMode} onSelect={handleViewMode} disabled={!state.isLoaded}/>
            </div>
          ) : null
        }
      </div>
      <div className={classes.body}>
        {
          state.isLoaded ? (
            <>
              {
                getUserRole(userData.userObject.roleType) === "parent" ? (
                  <div className={classes.reactionsList}>
                    {
                      isVisibleForParent ? (
                        <>
                          {
                            (state.viewMode === "all" ? state.postReactions.items : state.postReactions.items.filter((item: any) => item.emojiID === state.viewMode)).length === 0 ? (
                              <>{customNotFound}</>
                            ) : (
                              <>
                                {
                                  (state.viewMode === "all" ? state.postReactions.items : state.postReactions.items.filter((item: any) => item.emojiID === state.viewMode)).map((item: any, key: any) => (
                                    <div className={classes.reactionUser} key={`k_${key}`}>
                                      <div className={classes.reactionUserImageWrapper}>
                                        <AuthenticatedImage className={classes.reactionUserImage} thumbLink={getUserData(item.userID).photo.thumbLink}/>
                                        <EmojiIcon className={classes.reactionUserEmoji} emoji={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).emoji}/>
                                      </div>
                                      <span>
                                        {getUserData(item.userID).displayName}
                                      </span>
                                    </div>
                                  ))
                                }
                              </>
                            )
                          }
                        </>
                      ) : (
                        <>
                          {
                            reactionItems.length === 0 ? (
                              <>{customNotFound}</>
                            ) : reactionItems.map((item: any, key: any) => (
                              <div className={classes.reactionItem} key={`k_${key}`}>
                                <div className={classes.reactionUserImageWrapper}>
                                  <EmojiIcon className={classes.reactionItemEmoji} emoji={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).emoji}/>
                                  <span className={classes.reactionItemCount} data-clarity-unmask="true">
                                    {item.count > 100 ? '99+' : item.count}
                                  </span>
                                </div>
                                <span>
                                  {item.name}
                                </span>
                              </div>
                            ))
                          }
                        </>
                      )
                    }
                  </div>
                ) : (
                  <UsersSelect
                    mode="detail"
                    isSelectAll={false}
                    isSelectInAllSchool={false}
                    isMultipleSelect={false}
                    isAllowSearch={false}
                    isShowUsersEmojis={true}
                    isDisableClick={true}
                    defaultSchools={[{schoolID: schoolID}]}
                    defaultUsers={(state.viewMode === "all" ? state.postReactions.items : state.postReactions.items.filter((item: any) => item.emojiID === state.viewMode)).map((item: any) => { return { userID: item.userID, schoolID: getUserData(item.userID).schoolID}; })}
                    defaultUsersData={(state.viewMode === "all" ? state.postReactions.items : state.postReactions.items.filter((item: any) => item.emojiID === state.viewMode)).map((item: any) => { return { ...getUserData(item.userID), emojiID: item.emojiID}; } )}
                    isReload={state.viewMode}
                    isCloseAllOnReload={true}
                    customNoResults={customNotFound}
                  />
                )
              }
            </>
          ) : (
            <div className={classes.loading}>
              <CircularProgress className={classes.spinner}/>
            </div>
          )
        }
      </div>
    </>
  )
};

export default Post;
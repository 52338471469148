import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  popup: any;
};

const initialState: ArrayModel = {
  popup: null,
};

const slice = createSlice({
  name: 'popups',
  initialState: initialState,
  reducers: {
    setPopup(state, action: PayloadAction<Model>) {
      state.popup = action.payload;
    },
  }
});

export default slice;
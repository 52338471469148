import CloseButton from 'src/components/Buttons/CloseButton';
import Modal from '../../../utils/modal';
import React, { useCallback, useRef } from 'react';
import theme from '../../../ui/theme';
import TimelineCard from 'src/components/Cards/TimelineCard';
import TimelineComments from 'src/components/Comments/TimelineComments';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setIsModalFullscreen } from 'src/store/actions/layout.actions';
import { setTimelineCommentDetailModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMediaQuery } from '@mui/material';
import { useStates } from 'src/utils/useState';

interface Props {
  commentsHeight?: any;
  browserHeight?: any;
  commentsEnabled?: any;
  isCommentsEnabled?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "0px",
    backgroundColor: 'transparent',
    backdropFilter: 'saturate(180%) blur(20px)',
    overflow: "hidden",
    position: "fixed",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '100vw',
    height: (props: Props) => props.browserHeight + 'px',
    maxHeight: (props: Props) => props.browserHeight + 'px',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.colors.white,
    },
  },
  scroller: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    position: 'relative',
  },
  closeButton: {
    position: 'fixed',
    top: '0.5rem',
    left: '0.5rem',
    padding: '10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    outline: "none",
    backgroundColor: 'rgba(110, 113, 145, 0.9)',
    minWidth: "48px",
    minHeight: "48px",
    width: "48px",
    height: "48px",
    maxWidth: "48px",
    maxHeight: "48px",
    fontSize: "20px",
    cursor: "pointer",
    opacity: "1",
    transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
    zIndex: '4',
    '& svg': {
      color: theme.colors.white,
    },
    "&:hover": {
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: '#F1F1F1',
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 'unset',
      left: 'unset',
      margin: '8px',
      minWidth: "32px",
      minHeight: "32px",
      width: "32px",
      height: "32px",
      maxWidth: "32px",
      maxHeight: "32px",
      padding: '6px',
    },
  },
  contentWrapper: {
    width: '100%',
    maxWidth: '100%',
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '0',
      height: '100%',
      maxHeight: '100%',
      alignItems: 'flex-start',
      overflow: 'hidden',
      position: 'relative',
      backgroundImage: theme.colors.gradient,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      '&::before': {
        content: `''`,
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        width: '100%',
        height: '48px',
        backgroundColor: theme.colors.white,
      },
      '&::after': {
        content: (props: Props) => {
          if(props.commentsEnabled && props.isCommentsEnabled) return `''`;
          else return '';
        },
        display: 'block',
        width: '100%',
        height: '133px',
        minHeight: '133px',
      },
    },
  },
  contentInnerPlaceholder: {
    position: 'absolute',
    top: '32px',
    left: '0',
    right: '0',
    bottom: '32px',
    width: '100%',
    height: 'calc(100% - 84px)',
    zIndex: '-1',
  },
  contentInnerWrapper: {
    display: 'block',
    width: '50%',
    height: '100%',
    maxWidth: '50%',
    maxHeight: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'auto',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: '24px',
    maxHeight: '100%',
    maxWidth: '100%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0',
      height: '100%',
      position: 'unset',
      backgroundColor: 'transparent',
      boxShadow: theme.shadows[0],
    },
  },
  timelineCardWrapper: {
    width: '100%',
  },
  timelineCard: {
    boxShadow: 'none',
    borderRadius: '24px 24px 0 0',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0',
    },
  },
  commentsWrapper: {
    minHeight: (props: Props) => {
      if(props.commentsEnabled && props.isCommentsEnabled) return (props.commentsHeight + 20) + 'px';
      else return props.commentsHeight + 'px';
    },
    maxHeight: (props: Props) => {
      if(props.commentsEnabled && props.isCommentsEnabled) return (props.commentsHeight + 20) + 'px';
      else return props.commentsHeight + 'px';
    },
    marginBottom: (props: Props) => {
      if(props.commentsEnabled && props.isCommentsEnabled) return '20px';
      else return '';
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset !important',
      maxHeight: 'unset !important',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
    },
  },
  timelineComments: {
    height: (props: Props) => props.commentsHeight + 'px',
    borderRadius: '0 0 24px 24px',
    '&::after': {
      content: (props: Props) => {
        if(props.commentsEnabled && props.isCommentsEnabled) return `''`;
        else return '';
      },
      display: 'block',
      width: '100%',
      height: '93px',
      minHeight: '93px',
    },
    [theme.breakpoints.down('md')]: {
      backgroundImage: 'none',
      borderRadius: '0',
      height: 'unset !important',
      minHeight: '200px',
      flex: '1 1 auto',
      '&::after': {
        display: 'none',
      },
    },
  },
  commentsList: {
    height: (props: Props) => props.commentsHeight + 'px',
    borderRadius: '0 0 24px 24px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0',
      height: 'unset !important',
    },
  },
  commentBox: {
    boxShadow: theme.shadows[2],
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '5',
    borderRadius: '0 0 24px 24px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '0',
    },
  },
}));

const TimelineCommentDetailModal: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const browserData = useAppSelector((state: any) => state.browser);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const userData = useAppSelector((state: any) => state.user);
  const uniqueID = useAppSelector((state: any) => state.modals).timelineCommentDetailModal.uniqueID;
  const isLoaded = timelineData.posts.filter((post: any) => post.uniqueID === uniqueID).length === 1;
  const postData = isLoaded ? timelineData.posts.find((post: any) => post.uniqueID === uniqueID) : null;
  const schoolID = isLoaded && postData ? postData.schoolID : 0;
  const commentInputFocused = modalsData.timelineCommentDetailModal.addComment;
  const commentsEnabled = postData === null ? false : postData.comments.enable;
  const commentsTotal = postData === null ? 0 : postData.comments.total;
  const schoolSettings = userData.schoolSettings;
  const isCommentsEnabled = (schoolID && schoolSettings) ? (getSchoolSettings(schoolID, 'modules', schoolSettings).comment ? getSchoolSettings(schoolID, 'modules', schoolSettings).comment.enable : false) : false;
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const contentWrapperRef: any = useRef(null);
  const timelineCardRef: any = useRef(null);
  const commentsWrapperRef: any = useRef(null);

  const [state, setState] = useStates({
    commentsHeight: 0,
    browserHeight: browserData.height,
    commentsEnabled: commentsEnabled,
    isCommentsEnabled: isCommentsEnabled,
  });

  const classes = useStyles({
    commentsHeight: state.commentsHeight,
    browserHeight: state.browserHeight,
    commentsEnabled: commentsEnabled,
    isCommentsEnabled: isCommentsEnabled,
  });

  const onClose = ()  => {
    dispatch(setIsModalFullscreen(false));
    const settings = {
      isOpen: false,
      uniqueID: null,
      addComment: false,
    };
    dispatch(setTimelineCommentDetailModal(settings));
  };
  
  const onCloseModal = () => {
    onClose();
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleCommentsSize = useCallback(() => {
    if(contentWrapperRef.current && timelineCardRef.current && commentsWrapperRef.current) {
      const contentWrapperHeight = contentWrapperRef.current.clientHeight;
      const timelineCardHeight = timelineCardRef.current.clientHeight;
      const timelineCommentsHeight = contentWrapperHeight - timelineCardHeight;
      const browserPercentage = (browserData.height / 100) * 45;
      setState("commentsHeight", timelineCommentsHeight > browserPercentage ? timelineCommentsHeight : browserPercentage);
    }
  }, [setState, browserData.height]);

  useEffect(() => {
    handleCommentsSize();
  }, [handleCommentsSize], [browserData.height, contentWrapperRef, timelineCardRef, commentsWrapperRef]);

  useEffect(() => {
    setTimeout(() => {
      handleCommentsSize();
    }, 1);
  }, [handleCommentsSize], []);

  useEffect(() => {
    dispatch(setIsModalFullscreen(smallDevice));
  }, [smallDevice, dispatch], [smallDevice, browserData]);

  return (
    <Modal
      open={true} 
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "timelineCommentDetailModal" : null}>
        <div className={classes.scroller}>
          <div className={classes.contentWrapper} >
            <CloseButton className={classes.closeButton} onClick={handleClose} dataCy="timesButton"/>
            <div className={classes.contentInnerWrapper}>
              <div className={classes.contentInnerPlaceholder} ref={contentWrapperRef}/>
              <div className={classes.content}>
                {
                  !smallDevice ? (
                    <div className={classes.timelineCardWrapper}>
                      <TimelineCard className={classes.timelineCard} uniqueID={uniqueID} disableButtonComment={true} disableContextMenu={true} customRef={timelineCardRef}/>
                    </div>
                  ) : null
                }
                {
                  (commentsEnabled || commentsTotal > 0) ? (
                    <div className={classes.commentsWrapper} ref={commentsWrapperRef}>
                      <TimelineComments className={classes.timelineComments} classNameCommentsList={classes.commentsList} classNameCommentBox={classes.commentBox} uniqueID={uniqueID} autoFocus={commentInputFocused}/>
                    </div>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
  </Modal>
  )
};

export default TimelineCommentDetailModal;
import React, { useCallback } from 'react';
import SchoolsPicker from 'src/components/Forms/SchoolsPicker';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { Navigate, useLocation } from 'react-router';
import { setTimetableRooms, setTimetableSchool, setTimetableSubjects, setTimetableViewMode } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  schoolSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    '& > span': {
      fontSize: '18px',
      backgroundColor: theme.colors.white,
      borderRadius: '24px',
      padding: '4px 8px',
    },
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      height: '24px',
    },
  },
}));

const SchoolSelect: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const classes = useStyles();

  const queryString = getQueryString(location);

  const userAccessSchools = userData.userAccessSchools;

  const list = dataData.schools.filter((item: any) => userAccessSchools.timetable.includes(item.schoolID));

  const [state, setState] = useStates({
    canClick: true,
  });

  const handleChange = (value: any) => {
    setState("canClick", false);
    dispatch(setTimetableSchool(value));
    getSubjects(value);
  };

  const getRooms = useCallback((newSchool: any) => {
    timetableService && timetableService.listRooms(newSchool.schoolID).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableRooms(result.data));
          dispatch(setTimetableViewMode("timetableSettings"));
        } else {
          createNotification(t("timetable_rooms_not_loaded"), "error");
          setState("canClick", true);
        }
      } else {
        createNotification(t("timetable_rooms_not_loaded"), "error");
        setState("canClick", true);
      }
    }).catch(() => {
      createNotification(t("timetable_rooms_not_loaded"), "error");
      setState("canClick", true);
    });
  }, [t, timetableService, dispatch, setState]);

  const getSubjects = useCallback((newSchool: any) => {
    timetableService && timetableService.listSubjects(newSchool.schoolID).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableSubjects(result.data));
          getRooms(newSchool);
        } else {
          createNotification(t("timetable_subjects_not_loaded"), "error");
          setState("canClick", true);
        }
      } else {
        createNotification(t("timetable_subjects_not_loaded"), "error");
        setState("canClick", true);
      }
    }).catch(() => {
      createNotification(t("timetable_subjects_not_loaded"), "error");
      setState("canClick", true);
    });
  }, [t, timetableService, getRooms, dispatch, setState]);

  useEffect(() => {
    if(list.length === 1) {
      const value = list[0];
      dispatch(setTimetableSchool(value));
      dispatch(setTimetableViewMode("timetableSettings"));
      getSubjects(value); 
    }
  }, [dispatch, getSubjects, list], []);

  return list.length > 1 ? (
    <div className={classes.schoolSelectWrapper}>
      <span>{t('select_school')}</span>
      <SchoolsPicker schoolsData={list} setCurrentSchool={handleChange} isDisabled={!state.canClick}/>
    </div>
  ) : list.length === 0 ? (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  ) : null;
};

export default SchoolSelect;
import MuiModal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  position?: any;
  isFullscreen?: any;
  disableEnforceFocus?: any;
  zIndex?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  modal: {
    display: "flex",
    overflow: 'auto',
    justifyContent: (props: Props) => {
      if(props.position === "left-top" || props.position === "left-center" || props.position === "left-bottom") return "flex-start";
      else if(props.position === "center-top" || props.position === "center-center" || props.position === "center-bottom") return "center";
      else if(props.position === "right-top" || props.position === "right-center" || props.position === "right-bottom") return "flex-end";
      else return "center"; 
    },
    alignItems: (props: Props) => {
      if(props.position === "left-top" || props.position === "center-top" || props.position === "right-top") return "flex-start";
      else if(props.position === "left-center" || props.position === "center-center" || props.position === "right-center") return "center";
      else if(props.position === "left-bottom" || props.position === "center-bottom" || props.position === "right-bottom") return "flex-end";
      else return "center"; 
    },
    position: (props: Props) => {
      if(!props.disableEnforceFocus) return "static !important";
      else return ""; 
    },
    zIndex: (props: Props) => {
      if(props.zIndex) return props.zIndex;
      else return '';
    },
    '& > div[tabindex="-1"]': {
      outline: 'none',
      position: (props: Props) => {
        if(props.isFullscreen) return "fixed";
        else if(!props.disableEnforceFocus) return "fixed";
        else return ""; 
      },
      top: (props: Props) => {
        if(props.isFullscreen) return "0";
        else return ""; 
      },
      left: (props: Props) => {
        if(props.isFullscreen) return "0";
        else return ""; 
      },
      right: (props: Props) => {
        if(props.isFullscreen) return "0";
        else return ""; 
      },
      bottom: (props: Props) => {
        if(props.isFullscreen) return "0";
        else return ""; 
      },
      margin: (props: Props) => {
        if(props.isFullscreen) return "0";
        else return ""; 
      },
      width: (props: Props) => {
        if(props.isFullscreen) return "100vw";
        else return ""; 
      },
      maxWidth: (props: Props) => {
        if(props.isFullscreen) return "100vw";
        else return ""; 
      },
      height: (props: Props) => {
        if(props.isFullscreen) return "100vh";
        else return ""; 
      },
      maxHeight: (props: Props) => {
        if(props.isFullscreen) return "100vh";
        else return ""; 
      },
      borderRadius: (props: Props) => {
        if(props.isFullscreen) return "0";
        else return ""; 
      },
      zIndex: (props: Props) => {
        if(!props.disableEnforceFocus) return theme.zIndex.modal;
        else return ""; 
      },
    },
  },
}));

type ModalType = {
  children: any;
  open: boolean;
  position?: 'left-top' | 'center-top' | 'right-top' | 'left-center' | 'center-center' | 'right-center' | 'left-bottom' | 'center-bottom' | 'right-bottom';
  className?: any;
  closeAfterTransition?: boolean;
  disableAutoFocus?: boolean;
  disableEscapeKeyDown?: boolean;
  disableEnforceFocus?: boolean;
  hideBackdrop?: boolean;
  keepMounted?: boolean;
  isFullscreen?: boolean;
  onClose?: any;
  zIndex?: number;
};

const Modal: React.FunctionComponent<ModalType> = ({children, open, position = "center-center", className, closeAfterTransition, disableAutoFocus, disableEscapeKeyDown, disableEnforceFocus = true, hideBackdrop, keepMounted, isFullscreen, onClose, zIndex}) => {

  const classes = useStyles({
    position: position,
    isFullscreen: isFullscreen,
    disableEnforceFocus: disableEnforceFocus,
    zIndex: zIndex,
  });
  
  return (
    <MuiModal
      open={open}
      keepMounted={keepMounted}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${classes.modal} ${className}`}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={onClose}
      closeAfterTransition={closeAfterTransition}
      disableAutoFocus={disableAutoFocus}
      disableEnforceFocus={!disableEnforceFocus}
      hideBackdrop={hideBackdrop}
    > 
      {children}      
    </MuiModal>
  );
}


Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default Modal;
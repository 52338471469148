import EmojiIcon from 'src/components/Icons/EmojiIcon';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';

const useStyles = createUseStyles((theme: any) => ({
  reactionEmoji: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transform: 'scale(1)',
    transition: 'transform 0.25s',
    width: '28px',
    height: '28px',
    fontSize: '90%',
    '&.active': {
      backgroundColor: theme.colors.grey[200],
      borderRadius: '100%',
    },
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
}));

type ReactionsPopperListType = {
  data: any;
  onEmoji?: any;
};

const ReactionsPopperList: React.FunctionComponent<ReactionsPopperListType> = ({ data, onEmoji }) => {

  const classes = useStyles();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const reactions = useMemo(() => configurationData.configuration.reactions, [configurationData.configuration.reactions]);

  return (
    <>
      {
        reactions.map((item: any, key: any) => (
          <EmojiIcon key={`k_${key}`} className={`${classes.reactionEmoji} ${(data.reactions.myEmojiID && data.reactions.myEmojiID !== 0 && data.reactions.myEmojiID === item.emojiID) ? 'active' : ''}`} emoji={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).emoji} tooltipTitle={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).name} tooltipPosition="top" onClick={() => onEmoji(item.emojiID)}/>
        ))
      }
    </>
  );
}

export default ReactionsPopperList;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  absenceService: any;
  attendanceService: any;
  calendarService: any;
  classbookService: any;
  clubsService: any;
  commentsService: any;
  communicationService: any;
  dataService: any;
  exportService: any;
  galleryService: any;
  inspirationService: any;
  mealsService: any;
  orderService: any;
  paymentService: any;
  popupsService: any;
  reactionsService: any;
  stockService: any;
  substitutionsService: any;
  timelineService: any;
  timetableService: any;
  wagesService: any;
};

const initialState: ArrayModel = {
  absenceService: null,
  attendanceService: null,
  calendarService: null,
  classbookService: null,
  clubsService: null,
  commentsService: null,
  communicationService: null,
  dataService: null,
  exportService: null,
  galleryService: null,
  inspirationService: null,
  mealsService: null,
  orderService: null,
  paymentService: null,
  popupsService: null,
  reactionsService: null,
  stockService: null,
  substitutionsService: null,
  timelineService: null,
  timetableService: null,
  wagesService: null,
};

const slice = createSlice({
  name: 'services',
  initialState: initialState,
  reducers: {
    setAbsenceService(state, action: PayloadAction<Model>){
      state.absenceService = action.payload;
    },
    setAttendanceService(state, action: PayloadAction<Model>){
      state.attendanceService = action.payload;
    },
    setCalendarService(state, action: PayloadAction<Model>){
      state.calendarService = action.payload;
    },
    setClassbookService(state, action: PayloadAction<Model>){
      state.classbookService = action.payload;
    },
    setClubsService(state, action: PayloadAction<Model>){
      state.clubsService = action.payload;
    },
    setCommentsService(state, action: PayloadAction<Model>){
      state.commentsService = action.payload;
    },
    setCommunicationService(state, action: PayloadAction<Model>){
      state.communicationService = action.payload;
    },
    setDataService(state, action: PayloadAction<Model>){
      state.dataService = action.payload;
    },
    setExportService(state, action: PayloadAction<Model>){
      state.exportService = action.payload;
    },
    setGalleryService(state, action: PayloadAction<Model>){
      state.galleryService = action.payload;
    },
    setInspirationService(state, action: PayloadAction<Model>){
      state.inspirationService = action.payload;
    },
    setMealsService(state, action: PayloadAction<Model>){
      state.mealsService = action.payload;
    },
    setOrderService(state, action: PayloadAction<Model>){
      state.orderService = action.payload;
    },
    setPaymentService(state, action: PayloadAction<Model>){
      state.paymentService = action.payload;
    },
    setPopupsService(state, action: PayloadAction<Model>){
      state.popupsService = action.payload;
    },
    setReactionsService(state, action: PayloadAction<Model>){
      state.reactionsService = action.payload;
    },
    setStockService(state, action: PayloadAction<Model>){
      state.stockService = action.payload;
    },
    setSubstitutionsService(state, action: PayloadAction<Model>){
      state.substitutionsService = action.payload;
    },
    setTimelineService(state, action: PayloadAction<Model>){
      state.timelineService = action.payload;
    },
    setTimetableService(state, action: PayloadAction<Model>){
      state.timetableService = action.payload;
    },
    setWagesService(state, action: PayloadAction<Model>){
      state.wagesService = action.payload;
    },
  },
});

export default slice;
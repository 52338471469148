import Filters from 'src/components/Layouts/Filters';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import theme from 'src/ui/theme';
import useBreakpoint from 'src/utils/useBreakpoint';
import { createUseStyles } from 'react-jss';
import { getQueryParams, getQueryString, getSideContentSize, isValidHexColor } from 'src/utils/useFunctions';
import { isCypress } from '../../utils/useCypress';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate } from 'react-router';
import { useMemo } from 'src/utils/useMemo';
import { setTheme } from 'src/store/actions/layout.actions';

interface Props {
  children: any;
}

interface ClassesProps {
  backgroundColor?: any;
  isDisplayContent?: any;
  isFiltersVisible?: any;
  isSideContent?: any;
  sideContentSize: any;
}

const useStyles = createUseStyles((_: any) => ({
  embedLayout: {
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'auto',
    backgroundColor: (props: ClassesProps) => props.backgroundColor,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  }, 
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    maxWidth: '100%',
    overflow: 'visible',
    '&::before': {
      display: 'block',
      content: `''`,
      width: '100%',
      minHeight: (props: ClassesProps) => {
        if(props.isFiltersVisible) return '60px';
        else return '0px';
      },
      transition: 'min-height 0.5s',
    },
    '& *': {
      display: (props: ClassesProps) => {
        if(!props.isDisplayContent) return '';
        else return '';
      },
    },
    '& > *': {
      //overflow: 'visible !important',
    },
    '& > div': {
      flex: '1 1 auto',
    },
  },
}));

const EmbedLayout: React.FunctionComponent<Props> = (props: Props) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const layoutData = useAppSelector((state: any) => state.layout);
  const isDisplayContent = useMemo(() => layoutData.isModalFullscreen ? false : true, [layoutData.isModalFullscreen]);
  const breakpoint: any = useBreakpoint();

  const queryParams = getQueryParams(location);
  const queryString = getQueryString(location);
  const background = queryParams.get("background");
  const appTheme = queryParams.get("theme");
  const backgroundColor = background === "gradient" ? theme.colors.gradient : (isValidHexColor(`#${background}`) ? `#${background}` : theme.colors.white);

  const classes = useStyles({
    backgroundColor: backgroundColor,
    isDisplayContent: isDisplayContent,
    isFiltersVisible: layoutData.isFiltersVisible,
    isSideContent: layoutData.sideContent !== null,
    sideContentSize: getSideContentSize(breakpoint),
  });

  const onMessage = useCallback((e: any) => {
    const data = e.data;
    if(data) {
      if(data.action) {
        if(data.action === "navigate" && data.to) {
          navigate(`${data.to}${queryString}`);
        }
      }
    }
  }, [navigate, queryString]);

  useEffect(() => {
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage] , []);

  useEffect(() => {
    if(appTheme && appTheme === "dark") {
      dispatch(setTheme("dark"));
    }
  }, [appTheme, dispatch], []);

  return (
    <div className={classes.embedLayout} data-cy={isCypress() ? `embedLayout` : null}>
      <Filters/>
      <div className={classes.content} data-cy={isCypress() ? `embedLayoutContent` : null}> 
        {props.children}
      </div>
    </div>
  );
}

EmbedLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default EmbedLayout;
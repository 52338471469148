const useVersions = () => {
  
  const versions = [
    {
      name: '2.3.3',
      date: '25-11-2024',
      list: {
        parent:  [
          "added_news",
          "added_popups",
          "added_twigchat_reactions",
          "updated_user_switch",
          "updated_excuse_notes_part_of_day_indication",
          "updated_sso_login_interface",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        teacher:  [
          "added_classbook_missing_records",
          "added_news",
          "added_popups",
          "added_twigchat_reactions",
          "updated_user_switch",
          "updated_excuse_notes_part_of_day_indication",
          "updated_sso_login_interface",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        director:  [
          "added_classbook_missing_records",
          "added_news",
          "added_popups",
          "added_twigchat_reactions",
          "updated_user_switch",
          "updated_excuse_notes_part_of_day_indication",
          "updated_sso_login_interface",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.3.2',
      date: '09-10-2024',
      list: {
        parent:  [
          "added_pinterest_blog",
          "added_microsoft_files_support",
          "added_calendar_event_export",
          "updated_profile_menu",
          "updated_children_datatable",
          "fixed_gallery_end",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        teacher:  [
          "added_pinterest_blog",
          "added_microsoft_files_support",
          "added_calendar_event_export",
          "added_classbook_years",
          "updated_press_report_column_groups",
          "updated_profile_menu",
          "updated_children_datatable",
          "fixed_attendance_sorting",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        director:  [
          "added_pinterest_blog",
          "added_microsoft_files_support",
          "added_calendar_event_export",
          "added_classbook_years",
          "updated_press_report_column_groups",
          "updated_profile_menu",
          "updated_children_datatable",
          "fixed_attendance_sorting",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.3.1',
      date: '07-08-2024',
      list: {
        parent:  [
          "updated_twigchat",
          "updated_substitutions_classes",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        teacher:  [
          "updated_press_report_design_and_flow",
          "updated_substitutions_classes",
          "updated_twigchat",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        director:  [
          "updated_press_report_design_and_flow",
          "updated_substitutions_classes",
          "updated_twigchat",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
      },
    },
    {
      name: '2.3.0',
      date: '25-06-2024',
      list: {
        parent:  [
          "added_new_user_settings",
          "added_customization_options",
          "added_filters_remembering",
          "added_welcome_screen",
          "added_setup_screen",
          "added_data_management",
          "added_twigchat_pilot",
          "updated_gallery_parent",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        teacher:  [
          "added_new_user_settings",
          "added_customization_options",
          "added_filters_remembering",
          "added_welcome_screen",
          "added_setup_screen",
          "added_data_management",
          "added_twigchat_pilot",
          "added_delete_post_option_teacher_director",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        director:  [
          "added_new_user_settings",
          "added_customization_options",
          "added_filters_remembering",
          "added_welcome_screen",
          "added_setup_screen",
          "added_data_management",
          "added_twigchat_pilot",
          "added_delete_post_option_teacher_director",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
      },
    },
    {
      name: '2.2.6',
      date: '06-05-2024',
      list: {
        parent:  [
          "nothing_new_parent",
          "prepared_for_the_next_major_update",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        teacher:  [
          "added_press_report_teacher",
          "prepared_for_the_next_major_update",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        director:  [
          "added_press_report_director",
          "prepared_for_the_next_major_update",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
      },
    },
    {
      name: '2.2.5',
      date: '18-03-2024',
      list: {
        parent:  [
          "changed_children_page_parent",
          "optimalized_icons",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        teacher:  [
          "changed_image_processing",
          "added_post_refresh_button",
          "changed_children_page_teacher_director",
          "optimalized_icons",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        director:  [
          "changed_image_processing",
          "added_post_refresh_button",
          "changed_children_page_teacher_director",
          "optimalized_icons",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
      },
    },
    {
      name: '2.2.4',
      date: '11-02-2024',
      list: {
        parent:  [
          "added_user_switch",
          "added_sso_login",
          "added_context_menu_parent",
          "moved_cookies_to_settings",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        teacher:  [
          "fixed_classbook_detail_formating",
          "added_user_switch",
          "added_sso_login",
          "added_context_menu",
          "moved_cookies_to_settings",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        director:  [
          "fixed_classbook_detail_formating",
          "added_user_switch",
          "added_sso_login",
          "added_context_menu",
          "moved_cookies_to_settings",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.2.3',
      date: '27-01-2024',
      list: {
        parent:  [
          "reworked_loading_of_app",
          "updated_changelog_roles",
          "added_calendar_button_child_card",
          "reworked_camera_interface_parent",
          "added_camera_input_profile",
          "added_settings_addons_comments_sendbyenter",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        teacher:  [
          "reworked_loading_of_app",
          "updated_changelog_roles",
          "updated_classbook_modal",
          "added_calendar_button_child_card",
          "fixed_classbook_modes",
          "reworked_camera_interface_teacher_director",
          "added_camera_input_profile",
          "added_screen_recording",
          "added_settings_addons_comments_sendbyenter",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
        director:  [
          "reworked_loading_of_app",
          "updated_changelog_roles",
          "updated_classbook_modal",
          "added_calendar_button_child_card",
          "fixed_classbook_modes",
          "reworked_camera_interface_teacher_director",
          "added_camera_input_profile",
          "added_screen_recording",
          "added_settings_addons_comments_sendbyenter",
          "default_responsive_fixes",
          "default_small_fixes",
        ],
      },
    },
    {
      name: '2.2.2',
      date: '10-01-2024',
      list: {
        parent:  [
          "added_changelog",
          "added_changelog_open",
          "added_settings_addons",
          "added_settings_addons_changelog",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        teacher:  [
          "added_changelog",
          "added_changelog_open",
          "added_classbook_autosave",
          "added_settings_addons",
          "added_settings_addons_changelog",
          "added_settings_addons_classbook_autosave",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        director:  [
          "added_changelog",
          "added_changelog_open",
          "added_classbook_autosave",
          "added_settings_addons",
          "added_settings_addons_changelog",
          "added_settings_addons_classbook_autosave",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.2.1',
      date: '29-11-2023',
      list: {
        parent:  [
          "added_notifications",
          "added_notifications_settings",
          "changed_comments_design",
          "removed_meals_archived_children",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        teacher:  [
          "added_notifications",
          "added_notifications_settings",
          "changed_comments_design",
          "removed_meals_archived_children",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        director:  [
          "added_notifications",
          "added_notifications_settings",
          "changed_comments_design",
          "enabled_substitutions_director",
          "removed_meals_archived_children",
          "default_optimalization",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.2.0',
      date: '17-11-2023',
      list: {
        parent:  [
          "added_emoji_reactions",
          "added_comments",
          "added_favorited_posts",
          "added_parent_privacy_public_profile",
          "default_small_fixes",
          "default_optimalization",
        ],
        teacher:  [
          "added_emoji_reactions",
          "added_comments",
          "added_favorited_posts",
          "default_small_fixes",
          "default_optimalization",
        ],
        director:  [
          "added_emoji_reactions",
          "added_comments",
          "added_favorited_posts",
          "default_small_fixes",
          "default_optimalization",
        ],
      },
    },
    {
      name: '2.1.2',
      date: '13-09-2023',
      list: {
        parent:  [
          "added_gallery_multidownload_support",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        teacher:  [
          "added_calendar_teacher_adding",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        director:  [
          "nothing_new_director",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.1.1',
      date: '04-09-2023',
      list: {
        parent:  [
          "changed_payments",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        teacher:  [
          "nothing_new_teacher",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        director:  [
          "nothing_new_director",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.1.0',
      date: '07-08-2023',
      list: {
        parent:  [
          "added_calendar",
          "default_optimalization",
          "default_small_fixes",
        ],
        teacher:  [
          "added_calendar",
          "changed_classbook_type_schoolcare",
          "changed_classbook_by_mode",
          "default_optimalization",
          "default_small_fixes",
        ],
        director:  [
          "added_calendar",
          "changed_classbook_type_schoolcare",
          "changed_classbook_by_mode",
          "default_optimalization",
          "default_small_fixes",
        ],
      },
    },
    {
      name: '2.0.9',
      date: '11-07-2023',
      list: {
        parent:  [
          "added_orders",
          "added_clubs",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        teacher:  [
          "added_clubs",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        director:  [
          "added_orders",
          "added_clubs",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.0.8',
      date: '13-06-2023',
      list: {
        parent:  [
          "nothing_new_parent",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        teacher:  [
          "nothing_new_teacher",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        director:  [
          "added_stock",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.0.7',
      date: '19-05-2023',
      list: {
        parent:  [
          "updated_contacts",
          "added_timetables",
          "updated_wages",
          "default_optimalization",
        ],
        teacher:  [
          "added_employees",
          "added_timetables",
          "updated_wages",
          "default_optimalization",
        ],
        director:  [
          "added_employees",
          "added_timetables",
          "updated_wages",
          "default_optimalization",
        ],
      },
    },
    {
      name: '2.0.6',
      date: '27-04-2023',
      list: {
        parent:  [
          "added_mealmenus",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        teacher:  [
          "added_mealmenus",
          "added_wages",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        director:  [
          "added_mealmenus",
          "added_wages",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.0.5',
      date: '13-04-2023',
      list: {
        parent:  [
          "added_contacts",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        teacher:  [
          "nothing_new_teacher",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        director:  [
          "nothing_new_director",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.0.4',
      date: '22-03-2023',
      list: {
        parent:  [
          "updated_substitutions",
          "updated_excuse_notes",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        teacher:  [
          "updated_substitutions",
          "updated_excuse_notes",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
        director:  [
          "updated_substitutions",
          "updated_excuse_notes",
          "default_optimalization",
          "default_small_fixes",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.0.3',
      date: '01-03-2023',
      list: {
        parent:  [
          "added_substitutions",
          "default_optimalization",
          "default_small_fixes",
        ],
        teacher:  [
          "added_substitutions",
          "default_optimalization",
          "default_small_fixes",
        ],
        director:  [
          "nothing_new_director",
          "default_optimalization",
          "default_small_fixes",
        ],
      },
    },
    {
      name: '2.0.2',
      date: '20-02-2023',
      list: {
        parent:  [
          "nothing_new_parent",
          "default_small_fixes",
          "default_optimalization",
        ],
        teacher:  [
          "added_birthday_calendar",
          "default_small_fixes",
          "default_optimalization",
        ],
        director:  [
          "added_birthday_calendar",
          "default_small_fixes",
          "default_optimalization",
        ],
      },
    },
    {
      name: '2.0.1',
      date: '05-02-2023',
      list: {
        parent:  [
          "added_finance_section",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        teacher:  [
          "nothing_new_teacher",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
        director:  [
          "nothing_new_director",
          "default_small_fixes",
          "default_optimalization",
          "default_responsive_fixes",
        ],
      },
    },
    {
      name: '2.0.0',
      date: '01-02-2023',
      list: {
        parent:  [
          "released_new_version"
        ],
        teacher:  [
          "released_new_version"
        ],
        director:  [
          "released_new_version"
        ],
      },
    }
  ];
  return versions;
};

export default useVersions;
import BlogCard from 'src/components/Cards/BlogCard';
import config from 'src/constants/config';
import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import Input from 'src/components/Forms/Input';
import Masonry from 'react-masonry-component';
import NormalButton from 'src/components/Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getQueryString, parseXmlResponse } from 'src/utils/useFunctions';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  blogWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
    flex: '0 0 auto',
    alignItems: 'center',
    maxHeight: '100%',
    gap: '64px',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      minHeight: '24px',
      height: '24px',
    },
  },
  blogItemWrapper: {
    width: '350px',
    maxWidth: '100%',
    marginBottom: '20px',
    borderRadius: '24px',
    position: 'relative !important',
    left: '0 !important',
    top: '0 !important'
  },
  masonryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
  },
  masonry: {
    width: '100%',
    maxWidth: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    width: '100%',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  blogHeader: {
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    overflow: "hidden",
    padding: '26px 24px',
    display: "flex",
    minWidth: '50vw',
    maxWidth: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '13px 12px',
      borderRadius: '0px',
      width: 'calc(100% - 24px)',
    },
  },
  inputContainer: {
    marginLeft: '20px',
    marginTop: '3px',
    width: 'calc(100% - 20px)',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '32px',
      fontWeight: '700',
      color: theme.colors.primaryBlue[500],
      marginBottom: '12px',
    },
  },
  inputWrapper: {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      gap: '0.25rem',
    },
  },
  input: {
    display: 'block',
    width: '100%',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '1.5',
    outline: 'none',
    padding: '0.175rem 0.75rem',
    color: '#495057',
    backgroundColor: theme.colors.white,
    backgroundClip: "padding-box",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.grey[325],
    borderRadius: "10px",
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    height: "2rem",
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0.175rem 0.5rem',
      fontSize: '14px',
      height: '1.75rem',
    },
    '&:active': {
      borderColor: theme.colors.primaryBlue[500],
    },
    '&:focus': {
      borderColor: theme.colors.primaryBlue[500],
    },
  },
  tagsWrapper: {
    display: 'flex',
    marginTop: '10px',
  },
  tags: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    '&:empty': {
      display: 'none',
    },
  },
  tagButton: {
    height: '30px',
    padding: '5px 12px',
    minWidth: 'unset',
    fontSize: '12px',
  },
}));

type BlogType = {
  isLoadingMore: any;
  isEndOfScroll: any;
  setIsLoadingMore: any;
  setIsEndOfScroll: any;
};

const Blog: React.FunctionComponent<BlogType> = ({ isLoadingMore, isEndOfScroll, setIsLoadingMore, setIsEndOfScroll }) => {

  const classes = useStyles();
  const { section: blogTag } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const queryString = getQueryString(location);

  const inputWrapperRef: any = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [state, setState] = useStates({
    isFirstTime: true,
    isLoading: true,
    page: 1,
    items: [],
    tag: blogTag ? blogTag : null,
    tags: [],
    search: "",
    language: "cz",
  });

  const isLoading = useRef(state.isLoading);

  const inspirationService = useAppSelector((state: any) => state.services).inspirationService;

  const handleSetDetail = (item: any) => {
    const link = item.link;
    const contentName = link.replace(`${config.WEBSITE}blog/`,'').replace("/","");
    window.open(`${link}?utm_source=webapp&utm_medium=rss-feed-inspirace&utm_campaign=twig-blog-cz&utm_content=${contentName}`);
  };

  const getTwigseeBlogTags = useCallback(() => {
    inspirationService && inspirationService.getTwigseeBlogTags().then(async (result: any) => {
      if(result && result.data) {
        setState("tags", result.data);
      }
    }).catch(() => {

    });
  }, [inspirationService, setState]);

  const getTwigseeBlog = useCallback((page: any, tag?: any, defaultItems?: boolean, search?: any) => {
    if((isLoading.current && !state.isFirstTime) || (isEndOfScroll && !defaultItems)) return;
    isLoading.current = true;
    setState("isLoading", true);
    const params = {
      "paged": page,
      "tag": tag,
      "s": search,
      "sentence": "1",
      "cache_results": "false",
    };
    inspirationService && inspirationService.getTwigseeBlog(params).then(async (result: any) => {
      if(result && result.data) {
        const response = parseXmlResponse(result.data);
        if(response.items.length === 0) {
          setIsEndOfScroll(true);
        } else {
          if(response.items.length < 20) {
            setIsEndOfScroll(true);
          }
          setState("page", page + 1);
          if(defaultItems) {
            setState("items", response.items);
          } else {
            setState("items", [...state.items, ...response.items]);
          }
        }
        if(state.isFirstTime) {
          setState("isFirstTime", false);
        }
        isLoading.current = false;
        setState("isLoading", false);
        setIsLoadingMore(false);
      }
    }).catch(() => {
      isLoading.current = false;
      setState("isLoading", false);
      setIsEndOfScroll(true);
      setIsLoadingMore(false);
      if(state.isFirstTime) {
        setState("isFirstTime", false);
      }
    });
  }, [inspirationService, setState, state.items, state.isFirstTime, setIsLoadingMore, isEndOfScroll, setIsEndOfScroll]);

  const handleFilterTag = (item: any) => {
    const tag = item === null ? null : (item.slug ? item.slug : null);
    setState("tag", tag);
    setState("page", 1);
    setState("items", []);
    getTwigseeBlog(1, tag, true, state.search);
    navigate(`/inspiration/blog${tag ? `/${tag}` : ''}${queryString}`);
  };

  const handleBlogSearch = (value: any) => {
    setState("search", value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setState("search", state.search);
      setState("page", 1);
      setState("items", []);
      getTwigseeBlog(1, state.tag, true, state.search);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [state.search, state.tag, setState, getTwigseeBlog, setIsEndOfScroll], [state.search]);
  
  useEffect(() => {
    getTwigseeBlogTags();
  }, [getTwigseeBlogTags], []);

  useEffect(() => {
    if(isLoadingMore) {
      getTwigseeBlog(state.page, state.tag, false, state.search);
    }
  }, [getTwigseeBlog, state.page, state.tag, state.search, isLoadingMore], [isLoadingMore]);
  
  return (
    <div className={classes.blogWrapper}>
      <div className={classes.blogHeader}> 
        <div className={classes.inputContainer}>
          <span>{t('blog_title')}</span>
          <div className={classes.inputWrapper} ref={inputWrapperRef}>
            <Input prepend={<SVG src="search"/>} useName={false} onInputEnd={handleBlogSearch} inputDelay={1000} inputNoDelayOnEmpty={true} placeholder={t('blog_search')} disabled={state.isLoading}/>
          </div>
          <div className={classes.tagsWrapper}>
            <div className={classes.tags}>
              <NormalButton className={classes.tagButton} buttonType={state.tag === null ? 'primary': 'secondary'} onClick={() => handleFilterTag(null)} disabled={state.tag === null || state.isLoading} useDisabledStyle={state.tag === null ? false : true}>
                {t('all')}
              </NormalButton>
              {
                state.tags.map((item: any, key: any) => (
                  <NormalButton className={classes.tagButton} key={`k_${key}`} buttonType={state.tag === item.slug ? 'primary': 'secondary'} onClick={() => handleFilterTag(item)} disabled={state.tag === item.slug || state.isLoading} useDisabledStyle={state.tag === item.slug ? false : true}>
                    {item.name}
                  </NormalButton>
                ))
              }
            </div>
          </div>
        </div>
      </div>  
      {
          state.isLoading && state.items.length === 0 ? (
            <div className={classes.spinner}>
              <CircularProgress/>
            </div>
          ) : (
            <>
              {
                state.items.length === 0 ? (
                  <NotFound title={t('no_blog_posts')} text={t('no_blog_posts_found')}/>
                ) : (
                  <div className={classes.masonryWrapper}>
                    <Masonry className={classes.masonry} options={{columnWidth: 350, gutter: 25, fitWidth: true, horizontalOrder: true, transitionDuration: 0}}>
                      {
                        state.items.map((item: any, key: any) => {
                          return (
                            <div className={classes.blogItemWrapper} key={`k_${key}`}>
                              <BlogCard item={item} onClick={handleSetDetail}/>
                            </div>
                          );               
                        })
                      }
                    </Masonry>
                    {
                      isLoadingMore ? (
                        <div className={classes.spinner}>
                          <CircularProgress/>
                        </div>
                      ) : null
                    }
                    {
                      isEndOfScroll ? ( 
                        <EndOfScroll text={t("no_more_blog_posts")}/>
                      ) : null
                    }
                  </div>
                )
              }
            </>
          )
        }
    </div>
  );
};

export default Blog;
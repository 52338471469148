import * as AuthService from '../../../../services/auth.service';
import httpResult from 'src/constants/httpresult';
import { setDeinitializeUserController } from 'src/store/actions/controllers.actions';
import { setUserStatus } from 'src/store/actions/user.actions';

const validToken = async (dispatch: any) => {
  try {
    const result: any = await AuthService.verifyToken();
    if(result && result.status === httpResult.SUCCESS) {
      return true;
    } else {
      return false;
    }
  } catch (e: any) {
    if(e.response) {
      if(e.response.status === httpResult.EXPIRED_TOKEN) {
        AuthService && AuthService.logout().then(() => {
          dispatch(setDeinitializeUserController(true));
          dispatch(setUserStatus("autoLoggingOff"));
        });
      }
    } else {
      return false; 
    }
  };
  
};

export default validToken;
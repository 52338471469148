import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { isKey } from 'src/utils/useFunctions';
import { setConfirmModal, setStockTagHandleModal } from 'src/store/actions/modals.actions';
import { setStockTags } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        '&:first-of-type:not(:last-of-type)': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type:not(:first-of-type)': {
          borderRadius: '0px 20px 0px 0px',
          textAlign: 'center',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        }, 
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      '&:only-child': {
        '& > span': {
          justifyContent: 'center',
        },
      },
      '& > span': {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        padding: '12px 10px',
      },
    },
  },
  color: {
    width: '20px',
  },
  name: {
    fontWeight: '500',
    maxWidth: '300px',
    width: '300px',
    '& > span': {
      display: 'block !important',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
    },
  },  
  type: {
    paddingLeft: '8px',
  },
  action: {
    textAlign: 'right',
    paddingRight: '16px',
    width: 'calc(72px - 16px)',
  },
  button: {
    cursor: 'pointer',
    marginRight: '10px',
    '& > svg': {
      color: theme.colors.primaryBlue[250],
      width: '24px',
      height: '24px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
}));

type StockTagsTableType = {
  schoolID: any;
};

const StockTagsTable: React.FunctionComponent<StockTagsTableType> = ({schoolID = null}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;
  const classes = useStyles();

  const tags = stockData.tags;

  const handleClickEdit = (tagID: any) => {
    const settings = {
      isOpen: true,
      tagID: tagID,
      schoolID: schoolID,
    };
    dispatch(setStockTagHandleModal(settings)); 
  };

  const handleClickDelete = (tagID: any) => {
    const onAccept = () => {
      stockService && stockService.deleteTag(tagID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            createNotification(t("stock_tag_deleted"), "success");
            const newTags = stockData.tags.map((tag: any) => {
              if(tag.tagID === tagID) {
                return null;
              } else {
                return tag;
              }
            }).filter((item: any) => item !== null);
            dispatch(setStockTags(newTags));
          } else {
            createNotification(t("stock_tag_not_deleted"), "error");
          }
        } else {
          createNotification(t("stock_tag_not_deleted"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("stock_tag_not_deleted"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title: t('stock_tag_delete'),
      content: t('stock_tag_delete_confirm'),
      onAccept: onAccept,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.tableContainer}>
        <thead className={classes.tableHead}>
          <tr>
            <th/>
            <th>
              {t('stock_tag_name')}
            </th>
            <th>
              {t('action')}
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {
            tags.length > 0 ? tags.map((tag: any, key: any) => (
              <tr className={classes.tableRow} key={`k_${key}`}>
                <td className={classes.color}>
                </td>
                <td className={classes.name}>
                  <span>{tag.name}</span>
                </td>
                <td className={classes.action}>
                  <IconButton className={classes.button} onClick={() => handleClickEdit(tag.tagID)} tooltip={t('stock_tag_edit')} tooltipPosition='bottom' tooltipMaxWidth={200}>
                    <SVG src="edit"/>
                  </IconButton>
                  <IconButton className={classes.button} onClick={() => handleClickDelete(tag.tagID)} tooltip={t('stock_tag_delete')} tooltipPosition='bottom' tooltipMaxWidth={200}>
                    <SVG src="trash-outlined"/>
                  </IconButton>
                </td>
              </tr>
            )) : (
              <tr className={classes.tableRow}>
                <td colSpan={4}>
                  <span>{t('no_stock_tags_found')}</span>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

export default StockTagsTable;
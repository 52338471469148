import moment from '../../../../utils/moment';
import React, { useMemo } from 'react';
import { compareDates } from '../../../../utils/date';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { setWarningModal } from '../../../../store/actions/modals.actions';
import { useAppDispatch } from '../../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  weekNumberMode?: boolean,
  isWeekNumber?: boolean,
  date?: any,
  isLocked?: any,
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    width: (props: Props) => props.weekNumberMode ? 'calc(100% / 8)' : 'calc(100% / 7)',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '&.range': {
      backgroundColor: '#fffaca',
    },
    '&.start': {
      backgroundColor: '#fffaca',
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    '&.end': {
      backgroundColor: '#fffaca',
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
    '&.single': {
      backgroundColor: '#fffaca',
    },
    '&.weekNumber': {
      backgroundColor: '#ececec',
      borderRadius: 'none',
      '& span': {
        color: '#bcbcbc',
      }
    }
  },
  disabled: {
    position: 'relative',
    width: (props: Props) => props.weekNumberMode ? 'calc(100% / 8)' : 'calc(100% / 7)',
    height: '40px',
    display: 'flex',
    cursor: 'not-allowed',
    justifyContent: 'center',
    alignItems: 'center',
    '& .fontGray': {
      color: '#bcbcbc',
    },
    '&.weekNumber': {
      borderRadius: '0%',
      '& span': {
        backgroundColor: '#ececec',
        color: theme.colors.black,
        fontWeight: 'normal',
      }
    }
  },
  date: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    fontSize: (props: Props) => !props.isWeekNumber ?  '0.65rem' : '0.5rem',
    //color: (props) => props.isWeekNumber ? '#BCBCBC' : props.isLocked ? (((moment(props.date).isSameOrBefore(moment(),'day') && moment(props.date).isSameOrAfter(moment().subtract('14','days'),'day')) || (moment(props.date).isSame(moment(), 'isoweek') || moment(props.date).isSame(moment().subtract('14','days'), 'isoweek'))) ? theme.colors.black: '#BCBCBC') : theme.colors.black,
    //cursor: (props) => props.isWeekNumber ? 'auto' : props.isLocked ? (((moment(props.date).isSameOrBefore(moment(),'day') && moment(props.date).isSameOrAfter(moment().subtract('14','days'),'day')) || (moment(props.date).isSame(moment(), 'isoweek') || moment(props.date).isSame(moment().subtract('14','days'), 'isoweek'))) ? "pointer": "not-allowed") : "pointer",
    color: (props: Props) => props.isWeekNumber ? '#BCBCBC' : props.isLocked ? (moment(props.date).isSame(moment(), 'isoWeek') ? theme.colors.black : '#BCBCBC') : theme.colors.black,
    cursor: (props: Props) => props.isWeekNumber ? 'auto' : props.isLocked ? (moment(props.date).isSame(moment(), 'isoWeek') ? "pointer" : "not-allowed") : "pointer",
    backgroundColor: (props: Props) => (moment(props.date).day() === 0 || moment(props.date).day() === 6) ? '#F6F6F6' : theme.colors.white, 
    fontWeight: (props: Props) => (moment(props.date).day() === 0 || moment(props.date).day() === 6) ? 'normal' : 'bold', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      borderRadius: '100%',
    },
    '&:hover::before': {
      border:  (props: Props) => (props.isLocked || props.isWeekNumber) ? "" : "1px solid " + theme.colors.primaryBlue[500],
    },
    '&.today::before': {
      backgroundColor: '#E5F4FF',
    },
    '&.today': {
      color: theme.colors.black,
      fontWeight: 'bold',
    },
    '&.selected::before': {
      backgroundColor: theme.colors.primaryBlue[500],
    },
    '&.selected': {
      color: theme.colors.white,
      fontWeight: 'bold',
    },
    '& p': {
      position: 'relative',
      zIndex: '2',
      marginBottom: '0',
      color: 'inherit',
    },
  },
}));

type DateItemType = {
  index?: any;
  weekNumberMode?: any;
  date?: any;
  setDate?: any;
  isLocked?: any;
  currentSelectedDate?: any;
  disabled?: boolean;
  handleClose: any;
};

const DateItem: React.FunctionComponent<DateItemType> = ({index, weekNumberMode, date, setDate, isLocked = false, currentSelectedDate, disabled, handleClose}) => {
  let isWeekNumber = useMemo(() => index % 8 === 0, [index]);
  if(!weekNumberMode) isWeekNumber = false;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  
  const classes = useStyles({ weekNumberMode, isWeekNumber, date, isLocked });

  const handleClick = () => {
    if(!isWeekNumber) {
      if(isLocked) {
        //if((moment(date).isSameOrBefore(moment(),'day') && moment(date).isSameOrAfter(moment().subtract('14','days'),'day') || (moment(date).isSame(moment(), 'isoweek') || moment(date).isSame(moment().subtract('14','days'), 'isoweek')))) {
        if(moment(date).isSame(moment(), 'isoWeek')) {
          setDate(date);
          handleClose();
        } else {
          const settings = {
            isOpen: true,
            title: t('function_limited'),
            content: t('function_limited_classbook_datepicker'),
          };
          dispatch(setWarningModal(settings));
        }
      } else {
        setDate(date);
        handleClose();
      }
    }
  };

  return (
    <div className={`${(disabled) ? ` ${classes.root} ${weekNumberMode && isWeekNumber && 'weekNumber'} ${(moment(currentSelectedDate).isSame(moment(date),'day')) && 'selected'}` : `${classes.disabled} ${weekNumberMode && isWeekNumber && 'weekNumber'} ${(moment(currentSelectedDate).isSame(moment(date),'day')) && 'selected'}`}`} onClick={handleClick} data-cy={isCypress() ? "calendarDayItem-" + moment(date).format("YYYY-MM-DD") : null}>
      <span className={`${classes.date} ${weekNumberMode && isWeekNumber && 'weekNumber'} ${(compareDates(moment(), moment(date))) && !isWeekNumber && 'today'} ${(moment(currentSelectedDate).isSame(moment(date),'day')) && !isWeekNumber &&  'selected'} ${!disabled ? "disabled": ""}`}>
        <p onClick={handleClick} data-clarity-unmask="true">{(weekNumberMode && isWeekNumber) ? moment(date).week() : moment(date).date()}</p>
      </span>
    </div>
  );
};

export default DateItem;
import CategorySelect from './components/CategorySelect';
import Items from './components/Items';
import Orders from './components/Orders';
import React, { useCallback } from 'react';
import SchoolSelect from './components/SchoolSelect';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import TabsMenu from 'src/components/Menus/TabsMenu';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { Navigate, useLocation } from 'react-router';
import { setStockCart, setStockFilterCurrentPage, setStockFilterOrderStatusID, setStockFilterSchoolID, setStockOrders, setStockOrdersCount, setStockSchool, setStockViewMode } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { getQueryString, getSchoolsSettings } from 'src/utils/useFunctions';

const useStyles = createUseStyles((theme: any) => ({
  shopPage: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    padding: '24px',
    height: 'calc(100% - 48px)',
    alignItems: 'center',
        [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      [theme.breakpoints.down('xl')]: {
        width: '70%',
      },
    },
    paddingBottom: '16px',
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const stockData = useAppSelector((state: any) => state.stock);
  const stockService = useAppSelector((state: any) => state.services).stockService;

  const queryString = getQueryString(location);

  const schoolSettings = userData.schoolSettings;
  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;

  const catalogSchools = getSchoolsSettings("modules", schoolSettings).filter((item: any) => item.item.stock && item.item.stock.catalog).length > 0;
  const ordersSchools = getSchoolsSettings("modules", schoolSettings).filter((item: any) => item.item.stock && item.item.stock.orders).length > 0;

  const [viewMode, setViewMode] = useState(catalogSchools ? "shop" : "orders");
  const tabsItems = [
    {
      name: 'shop',
      value: 'shop',
      disabled: !catalogSchools,
      isEnabled: true,
    },
    {
      name: 'orders',
      value: 'orders',
      disabled: !ordersSchools,
      isEnabled: true,
    },
  ];
  const list = dataData.schools.filter((item: any) => userAccessSchools.shop.includes(item.schoolID));

  const handleViewMode = (value: any) => {
    setViewMode(value);
    if(value === "orders") {
      getOrders(0);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setStockOrders([]));
      dispatch(setStockOrdersCount(0));
      dispatch(setStockFilterSchoolID([]));
      dispatch(setStockFilterOrderStatusID([]));
      dispatch(setStockFilterCurrentPage(1));
      dispatch(setStockSchool(null));
      dispatch(setStockViewMode("schoolSelect"));
    };
  }, [dispatch], []);

  const getOrders = useCallback((page: any) => {
    const settings = {
      schoolID: stockData.filterSchoolID.map((item: any) => { return item.schoolID; }).join(","),
      userID: userData.userObject.userID,
      orderStatusID: stockData.filterOrderStatusID.map((item: any) => { return item.orderStatusID; }).join(","),
      limit: 15,
      page: page, 
    };
    stockService && stockService.listOrders(settings).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setStockOrders(result.data.orders));
          dispatch(setStockOrdersCount(result.data.countItems));
        } else {
          createNotification(t("stock_orders_not_loaded"), "error");
        }
      } else {
        createNotification(t("stock_orders_not_loaded"), "error");
      }
    }).catch(() => {
      createNotification(t("stock_orders_not_loaded"), "error");
    });
  }, [t, stockService, userData, stockData, dispatch]);

  useEffect(() => {
    if(stockData.cart.length === 0) {
      const settings = list.map((item: any) => {
        return { schoolID: item.schoolID, items: [] };
      });
      dispatch(setStockCart(settings));
    }
  }, [dispatch, dataData.schools, getOrders, list, stockData], []);

  return (
    <div className={classes.shopPage}>
      {
        getUserRole(userData.userObject.roleType) === "parent" && userAccess.shop ? (
          <>
          {
            !userAccess.subscription ? (
              <SubscriptionBanner service="shop" isInCenter={true}/>
            ) : (
              <div className={classes.wrapper}>
                <div className={classes.tabsWrapper}>
                  <TabsMenu items={tabsItems} selected={viewMode} onSelect={handleViewMode}/>
                </div>
                {
                  viewMode === "shop" ? (
                    <>
                    {
                      stockData.viewMode === "schoolSelect" ? (
                        <SchoolSelect/>
                      ) : stockData.viewMode === "categorySelect" ? (
                        <CategorySelect/>
                      ) : stockData.viewMode === "items" ? (
                        <Items/>
                      ) : null
                    }
                    </>
                  ) : viewMode === "orders" ? (
                    <Orders/>
                  ) : null
                }
              </div>
            )
          }
          </>
        ) : (
          <Navigate to={`/403${queryString}`} state={{ from: location }}/>
        )
      }
    </div>
  );
};

export default PageTemplate;
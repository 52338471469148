import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import Masonry from 'react-masonry-component';
import NotFound from 'src/components/Layouts/NotFound';
import PinterestBoardCard from 'src/components/Cards/PinterestBoardCard';
import PinterestItemCard from 'src/components/Cards/PinterestItemCard';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { CircularProgress } from '@mui/material';
import { convertToCapitalFirstLetter, createUrlLink, getQueryString } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  columnSize: any;
};

const useStyles = createUseStyles((theme: any) => ({
  pinterestWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0 0 0',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
    flex: '0 0 auto',
    alignItems: 'center',
    maxHeight: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
      width: '100%',
      minHeight: '24px',
      height: '24px',
    },
  },
  pinterestBoardWrapper: {
    width: (props: Props) => `${props.columnSize}px`,
    maxWidth: '100%',
    marginBottom: '20px',
    borderRadius: '24px',
  },
  pinterestItemWrapper: {
    width: (props: Props) => `${props.columnSize}px`,
    maxWidth: '100%',
    marginBottom: '20px',
    borderRadius: '24px',
  },
  masonryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
  },
  topBar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '16px',
    height: '32px',
    '& > span': {
      fontSize: '18px',
      fontWeight: '600',
    },
  },
  homeButton: {
    '& > svg': {
      width: '16px',
      height: '16px',
    },
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    cursor: 'default',
  },
  backButton: {
    '& > svg': {
      width: '16px',
      height: '16px',
    },
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    '&:hover': {
      backgroundColor: theme.colors.grey[100],
    },
  },
  openBoardButton: {
    marginLeft: 'auto',
    '& > svg': {
      width: '16px',
      height: '16px',
    },
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    '&:hover': {
      backgroundColor: theme.colors.grey[100],
    },
  },
  masonry: {
    width: '100%',
    maxWidth: '100%',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    width: '100%',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

type PinterestType = {
  isLoadingMore: any;
  isEndOfScroll: any;
  setIsLoadingMore: any;
  setIsEndOfScroll: any;
};

const Pinterest: React.FunctionComponent<PinterestType> = ({ isLoadingMore, isEndOfScroll, setIsLoadingMore, setIsEndOfScroll }) => {

  const { t } = useTranslation();
  const { section, board, boardUrl } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const inspirationService = useAppSelector((state: any) => state.services).inspirationService;
  const userData = useAppSelector((state: any) => state.user);
  const userAccess = userData.userAccess;

  const queryString = getQueryString(location);

  const [state, setState] = useStates({
    isUserAccountLoaded: false,
    isFirstTime: true,
    isLoading: true,
    boardsPageSize: 100,
    pinsPageSize: 25,
    items: [],
    type: "boards",
    boardID: null,
    boardData: {},
    bookmark: "",
    totalFollowerCount: null,
    totalPinCount: null,
    username: '',
  });

  const contentTypeRef = useRef(state.type);
  const bookmarkRef = useRef(state.bookmark);
  const boardIDRef = useRef(state.boardID);

  const contentType = contentTypeRef.current;
  const bookmark = bookmarkRef.current;
  const boardID = boardIDRef.current;

  const columnSize = 300;

  const classes = useStyles({
    columnSize: columnSize,
  });

  const isLoading = useRef(state.isLoading);

  const allPinsItem = useMemo(() => {
    return {
      name: t('pinterest_all_pins'),
      description: "",
      media: {
        image_cover_url: "/resources/images/pins.jpg",
      },
      collaborator_count: null,
      follower_count: state.totalFollowerCount,
      pin_count: state.totalPinCount,
    };
  }, [state.totalFollowerCount, state.totalPinCount, t]);

  const handleSetContentType = useCallback((type: any) => {
    setState("type", type);
    contentTypeRef.current = type;
  }, [setState]);

  const handleSetBookmark = useCallback((bookmark: any) => {
    setState("bookmark", bookmark);
    bookmarkRef.current = bookmark;
  }, [setState]);

  const handleSetBoardID = useCallback((boardID: any) => {
    setState("boardID", boardID);
    boardIDRef.current = boardID;
  }, [setState]);

  const getPinterestPins = useCallback((bookmark: any) => {
    if((isLoading.current && !state.isFirstTime) || isEndOfScroll) return;
    isLoading.current = true;
    setState("isLoading", true);
    const params = {
      pageSize: state.pinsPageSize,
      pin_metrics: "true",
      bookmark: bookmark,
      pin_filter: "exclude_repins",
    };
    inspirationService && inspirationService.getPinterestPins(params).then((result: any) => {
      if(result && result.data) {
        const response = result.data;
        if(response.items.length === 0 || response.bookmark === null) {
          setIsEndOfScroll(true);
        } else {
          handleSetBookmark(response.bookmark);
        }
        setState("items", [...state.items, ...response.items]);
        if(state.isFirstTime) {
          setState("isFirstTime", false);
        }
        isLoading.current = false;
        setState("isLoading", false);
        setIsLoadingMore(false);
      }
    }).catch(() => {
      isLoading.current = false;
      setState("isLoading", false);
      setIsEndOfScroll(true);
      setIsLoadingMore(false);
      if(state.isFirstTime) {
        setState("isFirstTime", false);
      }
    });
  }, [inspirationService, setState, state.items, state.isFirstTime, state.pinsPageSize, setIsLoadingMore, isEndOfScroll, setIsEndOfScroll, handleSetBookmark]);

  const getPinterestBoards = useCallback((bookmark: any) => {
    if((isLoading.current && !state.isFirstTime) || isEndOfScroll) return;
    isLoading.current = true;
    setState("isLoading", true);
    const params = {
      pageSize: state.boardsPageSize,
      bookmark: bookmark,
      privacy: "PUBLIC",
    };
    inspirationService && inspirationService.getPinterestBoards(params).then((result: any) => {
      if(result && result.data) {
        const response = result.data;
        if(response.items.length === 0 || response.bookmark === null) {
          setIsEndOfScroll(true);
        } else {
          handleSetBookmark(response.bookmark);
        }
        const items = response.items.filter((item: any) => item.pin_count !== 0 && item.pin_count !== null && item.owner && item.owner.username && item.owner.username === state.username);
        setState("items", [...state.items, ...items]);
        if(state.isFirstTime) {
          setState("isFirstTime", false);
        }
        isLoading.current = false;
        setState("isLoading", false);
        setIsLoadingMore(false);
      }
    }).catch(() => {
      isLoading.current = false;
      setState("isLoading", false);
      setIsEndOfScroll(true);
      setIsLoadingMore(false);
      if(state.isFirstTime) {
        setState("isFirstTime", false);
      }
    });
  }, [inspirationService, setState, state.items, state.isFirstTime, state.boardsPageSize, state.username, setIsLoadingMore, isEndOfScroll, setIsEndOfScroll, handleSetBookmark]);
  
  const getPinterestBoard = useCallback((boardID: any) => {
    inspirationService && inspirationService.getPinterestBoard(boardID).then((result: any) => {
      if(result && result.data) {
        const response = result.data;
        setState("boardData", response);
      }
    }).catch(() => {

    });
  }, [inspirationService, setState]);

  const getPinterestBoardPins = useCallback((boardID: any, bookmark: any) => {
    if((isLoading.current && !state.isFirstTime) || isEndOfScroll) return;
    isLoading.current = true;
    setState("isLoading", true);
    const params = {
      pageSize: state.pinsPageSize,
      pin_metrics: "true",
      bookmark: bookmark,
      pin_filter: "exclude_repins",
    };
    inspirationService && inspirationService.getPinterestBoardPins(boardID, params).then((result: any) => {
      if(result && result.data) {
        const response = result.data;
        if(response.items.length === 0 || response.bookmark === null) {
          setIsEndOfScroll(true);
        } else {
          handleSetBookmark(response.bookmark);
        }
        setState("items", [...state.items, ...response.items]);
        if(state.isFirstTime) {
          setState("isFirstTime", false);
        }
        isLoading.current = false;
        setState("isLoading", false);
        setIsLoadingMore(false);
      }
    }).catch(() => {
      isLoading.current = false;
      setState("isLoading", false);
      setIsEndOfScroll(true);
      setIsLoadingMore(false);
      if(state.isFirstTime) {
        setState("isFirstTime", false);
      }
    });
  }, [inspirationService, setState, state.items, state.isFirstTime, state.pinsPageSize, setIsLoadingMore, isEndOfScroll, setIsEndOfScroll, handleSetBookmark]);

  const getPinterestUserAccount = useCallback(() => {
    inspirationService && inspirationService.getPinterestUserAccount().then((result: any) => {
      if(result && result.data) {
        const response = result.data;
        if(response.pin_count && response.follower_count) {
          setState("totalFollowerCount", response.follower_count)
          setState("totalPinCount", response.pin_count);
          setState("username", response.username);
          setState("isUserAccountLoaded", true);
        } else if(response.link && response.message) {
          isLoading.current = false;
          setState("isLoading", false);
          createNotification(t('pinterest_failed_load_data'), "error");
        }
      }
    }).catch(() => {
      isLoading.current = false;
      setState("isLoading", false);
      createNotification(t('pinterest_failed_load_data'), "error");
    });
  }, [inspirationService, setState, t]);

  const handleGoBack = () => {
    if(!userAccess.subscription) return;
    setState("items", []);
    setState("isLoading", true);
    setState("boardData", {});
    setIsEndOfScroll(false);
    setIsLoadingMore(false);
    handleSetBoardID(null);
    handleSetBookmark("");
    navigate(`/inspiration/pinterest${queryString}`);
    setTimeout(() => {
      setIsLoadingMore(true);
      handleSetContentType("boards");
    }, 100);
  };

  const handleOpenBoard = () => {
    const boardName = boardUrl;
    window.open(`https://pinterest.com/${state.username}/${boardName}${queryString}`);
  };

  const handleSetBoard = (item: any) => {
    if(!userAccess.subscription) return;
    setState("items", []);
    setState("isLoading", true);
    setState("bookmark", "");
    setState("boardData", item);
    setIsEndOfScroll(false);
    setIsLoadingMore(false);
    handleSetBoardID(item.id);
    handleSetBookmark("");
    navigate(`/inspiration/pinterest/${item.id}/${createUrlLink(item.name)}${queryString}`);
    setTimeout(() => {
      setIsLoadingMore(true);
      handleSetContentType("boardPins");
    }, 100);
  };

  const handleGetAllPins = () => {
    if(!userAccess.subscription) return;
    setState("items", []);
    setState("isLoading", true);
    setState("boardData", allPinsItem);
    setIsEndOfScroll(false);
    setIsLoadingMore(false);
    handleSetBoardID(null);
    handleSetBookmark("");
    navigate(`/inspiration/pinterest/all${queryString}`);
    setTimeout(() => {
      setIsLoadingMore(true);
      handleSetContentType("pins");
    }, 100);
  };

  const handleSetDetail = (id: any) => {
    window.open(`https://pinterest.com/pin/${id}`);
  };

  useEffect(() => {
    if(isLoadingMore && state.isUserAccountLoaded) {
      if(section && section === "all") {
        getPinterestPins(bookmark);
        setState("boardData", allPinsItem);
        handleSetContentType("pins");
      } else if(board) {
        getPinterestBoard(board);
        getPinterestBoardPins(board, bookmark);
        handleSetContentType("boardPins");
      } else {
        if(contentType === "boards") {
          getPinterestBoards(bookmark);
        } else if(contentType === "boardPins") {
          getPinterestBoardPins(boardID, bookmark);
        } else {
          getPinterestPins(bookmark);
        }
      }
    }
  }, [getPinterestBoards, getPinterestBoard, getPinterestBoardPins, getPinterestPins, getPinterestUserAccount, handleSetContentType, setState, allPinsItem, boardID, bookmark, contentType, isLoadingMore, board, section, state.isUserAccountLoaded], [isLoadingMore, state.isUserAccountLoaded]);

  useEffect(() => {
    if(!state.isUserAccountLoaded) {
      getPinterestUserAccount();
    }
  }, [getPinterestUserAccount, state.isUserAccountLoaded], [state.isUserAccountLoaded]);

  return (
    <div className={classes.pinterestWrapper}>
      <div className={classes.topBar}>
      {
        state.type === "boards" ? (
          <>
            <IconButton className={classes.homeButton} disableRipple={true}>
              <SVG src="home-outlined"/>
            </IconButton>
          <span>
            {t('pinterest_boards')}
          </span>
          </>
        ) : (
          <>
            <IconButton tooltip={t('back')} className={classes.backButton} onClick={handleGoBack} disabled={state.isLoading || isLoadingMore}>
              <SVG src="arrow-left"/>
            </IconButton>
            <span>
              {state.boardData.name ? htmlParse(convertToCapitalFirstLetter(state.boardData.name)) : t('title_loading')} { state.boardData.pin_count ? `(${state.boardData.pin_count})` : null }
            </span>
            {
              state.boardData.id ? (
                <IconButton tooltip={t('pinterest_board_open')} tooltipMaxWidth={400} className={classes.openBoardButton} onClick={handleOpenBoard}>
                  <SVG src="link"/>
                </IconButton>
              ) : null
            }
          </>
        )
      }
      </div>
      {
        state.isLoading && state.items.length === 0 ? (
          <div className={classes.spinner}>
            <CircularProgress/>
          </div>
        ) : (
          <>
            {
              state.items.length === 0 ? (
                <NotFound title={state.type === "boards" ? t('no_pinterest_boards') : t('no_pinterest_pins')} text={state.type === "boards" ? t('no_pinterest_boards_found') : t('no_pinterest_pins_found')}/>
              ) : (
                <div className={classes.masonryWrapper}>
                  <Masonry className={classes.masonry} options={{columnWidth: columnSize, gutter: 25, fitWidth: true, horizontalOrder: false}}>
                    {
                      state.type === "boards" ? (
                        <div className={classes.pinterestBoardWrapper}>
                          <PinterestBoardCard columnSize={columnSize} item={allPinsItem} onClick={handleGetAllPins}/>
                        </div>
                      ) : null
                    }
                    {
                      state.items.map((item: any, key: any) => {
                        return state.type === "boards" ? (
                          <div className={classes.pinterestBoardWrapper} key={`k_${key}`}>
                            <PinterestBoardCard columnSize={columnSize} item={item} onClick={handleSetBoard}/>
                          </div>
                        ) : (
                          <div className={classes.pinterestItemWrapper} key={`k_${key}`}>
                            <PinterestItemCard columnSize={columnSize} item={item} onClick={handleSetDetail}/>
                          </div>
                        );               
                      })
                    }
                  </Masonry>
                  {
                    isLoadingMore ? (
                      <div className={classes.spinner}>
                        <CircularProgress/>
                      </div>
                    ) : null
                  }
                  {
                    (isEndOfScroll && state.bookmark !== "") ? ( 
                      <EndOfScroll text={state.type === "boards" ? t("no_more_pinterest_boards") : t("no_more_pinterest_pins")}/>
                    ) : null
                  }
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
};

export default Pinterest;
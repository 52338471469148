import Categories from './components/Categories';
import CategorySelect from './components/CategorySelect';
import Items from './components/Items';
import Orders from './components/Orders';
import React from 'react';
import SchoolSelect from './components/SchoolSelect';
import StockSettings from './components/StockSettings';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import Tags from './components/Tags';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { Navigate, useLocation } from 'react-router';
import { setStockCategories, setStockItems, setStockItemsCount, setStockSchool, setStockTags, setStockViewMode } from 'src/store/actions/stock.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';

const useStyles = createUseStyles((theme: any) => ({
  stockPage: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    padding: '24px',
    height: 'calc(100% - 48px)',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const location = useLocation();
  const userData = useAppSelector((state: any) => state.user);
  const stockData = useAppSelector((state: any) => state.stock);

  const queryString = getQueryString(location);

  const userAccess = userData.userAccess;

  useEffect(() => {
    return () => {
      dispatch(setStockCategories([]));
      dispatch(setStockItems([]));
      dispatch(setStockItemsCount([]));
      dispatch(setStockSchool(null));
      dispatch(setStockTags([]));
      dispatch(setStockViewMode("schoolSelect"));
    };
  }, [dispatch], []);

  return (
    <div className={classes.stockPage}>
      {
        getUserRole(userData.userObject.roleType) === "director" ? (
          <>
          {
            !userAccess.subscription ? (
              <SubscriptionBanner service="stock" isInCenter={true}/>
            ) : (
              <div className={classes.wrapper}>
                {
                  stockData.viewMode === "schoolSelect" ? (
                    <SchoolSelect/>
                  ) : stockData.viewMode === "stockSettings" ? (
                    <StockSettings/>
                  ) : stockData.viewMode === "tags" ? (
                    <Tags/>
                  ) : stockData.viewMode === "categories" ? (
                    <Categories/>
                  ) : stockData.viewMode === "categorySelect" ? (
                    <CategorySelect/>
                  ) : stockData.viewMode === "items" ? (
                    <Items/>
                  ) : stockData.viewMode === "orders" ? (
                    <Orders/>
                  ) : null
                }
              </div>
            )
          }
          </>
        ) : (
          <Navigate to={`/403${queryString}`} state={{ from: location }}/>
        )
      }
    </div>
  );
};

export default PageTemplate;
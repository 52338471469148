import AuthenticatedImage from '../../Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import Drawer from '@mui/material/Drawer';
import Hotkeys from 'react-hot-keys';
import IconButton from 'src/components/Buttons/IconButton';
import Modal from '../../../utils/modal';
import NavigationButton from '../../Buttons/NavigationButton';
import React, { useRef } from 'react';
import RenderAttachment from './RenderAttachment';
import RenderPhoto from './RenderPhoto';
import RenderVideo from './RenderVideo';
import SVG from '../../Images/SvgRenderer';
import { base64toBlob, formatFileSize, removeParamsFromUrl } from 'src/utils/useFunctions';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { saveAs } from 'file-saver';
import { setPopupGalleryModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "0px",
    backgroundColor: theme.colors.black,
    overflow: "auto",
    position: "fixed",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    maxHeight: '100vh',
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '64px',
    width: 'calc(100% - 24px)',
    padding: '0 12px',
    flex: '0 0 64px',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '12px',
    flex: '0 0 auto',
  },
  title: {
    color: theme.colors.white,
    flex: '1 1 auto',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  preview: {
    width: 'calc(100% - 128px)',
    maxWidth: 'calc(100% - 128px)',
    height: 'calc(100vh - 144px)',
    marginTop: '16px',
    overflow: 'hidden',
    "& > button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "0px",
      outline: "none",
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: theme.colors.grey[400],
      minWidth: "48px",
      minHeight: "48px",
      width: "48px",
      height: "48px",
      maxWidth: "48px",
      maxHeight: "48px",
      padding: '0',
      fontSize: "20px",
      cursor: "pointer",
      opacity: "1",
      transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
      zIndex: '3',
      transform: 'translateY(-50%)',
      "&:first-of-type": {
        position: "absolute",
        top: "50%",
        left: "0px",
        borderRadius: "0px 100% 100% 0px",
      },
      "&:last-of-type": {
        position: "absolute",
        top: "50%",
        right: "0px",
        borderRadius: "100% 0px 0px 100%",
      },
      "&:hover": {
        backgroundColor: 'rgba(110, 113, 145, 0.9)',
        color: theme.colors.grey[400],
      },
      "&:disabled": {
        opacity: "0",
        pointerEvents: "none",
      },
    },
  },
  button: {
    padding: '10px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "0px",
    outline: "none",
    backgroundColor: 'rgba(110, 113, 145, 0.9)',
    minWidth: "48px",
    minHeight: "48px",
    width: "48px",
    height: "48px",
    maxWidth: "48px",
    maxHeight: "48px",
    fontSize: "20px",
    cursor: "pointer",
    opacity: "1",
    transition: "color 0.25s, background-color 0.25s, opacity 0.25s",
    zIndex: '4',
    '& svg': {
      color: theme.colors.white,
    },
    "&:hover": {
      backgroundColor: 'rgba(110, 113, 145, 0.9)',
      color: '#F1F1F1',
    },
    '&.Mui-disabled': {
      '& svg': {
        filter: 'grayscale(100%)',
      }
    },
    '&.active': {
      '& svg': {
        color: theme.colors.yellow[500], 
      },
    },
  },
  thumbsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
  },
  thumbsListWrapper: {
    width: 'calc(100% - 2rem)',
    height: 'calc(100% - 2rem)',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    maxHeight: '100%',
    overflow: 'auto',
    padding: '1rem',
  },
  itemWrapper: {
    display: 'flex',
  },
  mediaFile: {  
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#888FAE',
    width: '100px',
    height: '75px',
    backgroundColor: theme.colors.grey[350],
    borderRadius: "10px",
    borderWidth: "2.5px",
    borderStyle: "solid",
    borderColor: theme.colors.black,
    cursor: 'pointer',
    overflow: 'hidden',
    "&.current": {
      borderColor: theme.colors.primaryBlue[500],
    },
    '& > div': {
      width: '100%',
      height: '100%',
      position: 'relative',
      '& > svg': {
        width: '32px',
        height: '32px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) scale(0.8)',
        color: 'rgba(255,255,255,0.75)',
      },
      '& > div': {
        width: '100%',
        height: '100%',
      },
    },
    '& > span': {
      fontSize: '8px',
      marginBottom: '4px',
    },
    '& > svg': {
      width: '32px',
      height: '32px',
    },
    '& > p': {
      marginTop: '4px',
      marginBottom: '0',
      fontSize: '12px',
      maxWidth: '80%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  drawer: {
    zIndex: '1300',
  },
}));

const PopupGalleryModal: React.FunctionComponent = () => {

  const mediasData = useAppSelector((state: any) => state.medias);
  const modalsData = useAppSelector((state: any) => state.modals);
  const mediaID = modalsData.popupGalleryModal.mediaID;
  const messageMediasData = modalsData.popupGalleryModal.mediaList;
  const mediaData: any = messageMediasData.filter((media: any) => media.mediaID === mediaID).length === 0 ? null : messageMediasData.find((media: any) => media.mediaID === mediaID);
  const mediaIndex: any = mediaData ? messageMediasData.findIndex((media: any) => media.mediaID === mediaID) : null;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const thumbsWrapper: any = useRef(null);

  const [state, setState] = useStates({
    isLoaded: false,
    isDownloadable: false,
    isFirst: false,
    isLast: false,
    isOpenThumbs: false,
    canClick: true,
  });

  const onClose = ()  => {
    const settings = {
      isOpen: false,
      mediaID: null,
      mediaList: [],
    };
    dispatch(setPopupGalleryModal(settings));   
  };
  
  const onCloseModal = () => {
    onClose();
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };
  
  const handleThumbs = () => {
    if(!state.isOpenThumbs) {
      setTimeout(function() {
        if(thumbsWrapper.current) {
          let scrollWidth = thumbsWrapper.current.scrollWidth;
          let count = messageMediasData.length;
          thumbsWrapper.current.scrollLeft = (mediaIndex - 1) * (scrollWidth / count);
        }
      }, 1);   
    }
    setState("isOpenThumbs", !state.isOpenThumbs);
  };

  const handleDownload = () => {
    if(state.isDownloadable) {
      const fileType = (mediaData.name).split('.').pop();
      if(mediaData.type === "photo") {
        const imageData = mediasData.images.find((img: any) => img.url === removeParamsFromUrl(mediaData.fullsizeLink)).base64;
        const blob = base64toBlob(imageData, "image/" + fileType);
        saveAs(blob, mediaData.name);
      } else if(mediaData.type === "attachment") {
        const attachmentData = mediasData.attachments.find((attachment: any) => attachment.id === mediaID).base64;
        const blob = base64toBlob(attachmentData, "application/octet-stream");
        saveAs(blob, mediaData.name);
      }
    }
  };
  
  const prevMedia = () => {
    if(state.canClick && mediaIndex !== 0) {
      setState("canClick", false);
      const newMediaID = messageMediasData[mediaIndex - 1].mediaID;
      dispatch(setPopupGalleryModal({mediaID: newMediaID}));
    }
  };
  
  const nextMedia = () => {
    if(state.canClick && mediaIndex !== messageMediasData.length - 1) {
      setState("canClick", false);
      const newMediaID = messageMediasData[mediaIndex + 1].mediaID;
      dispatch(setPopupGalleryModal({mediaID: newMediaID}));
    }
  };
  
  const setMedia = (mediaID: any) => {
    if(state.canClick) {
      setState("canClick", false);
      dispatch(setPopupGalleryModal({mediaID: mediaID}));
      setState("isOpenThumbs", false);
    }
  }; 
  
  useEffect(() => {
    setState("isLoaded", true);
    if(mediaIndex === 0) {
      setState("isFirst", true); 
    } else {
      setState("isFirst", false);
    }
    if(mediaIndex === messageMediasData.length - 1) {
      setState("isLast", true); 
    } else {
      setState("isLast", false);
    }
  }, [mediaID, mediaIndex, messageMediasData.length, setState], [mediaID]);
  
  const handleSecureClick = () => {
    setState("canClick", true);
  };
  
  const keyPress = (keyName: any, e: any, handle: any) => {
    if(keyName === "left") {
      prevMedia();
    } else if(keyName === "right") {
      nextMedia();
    }
  };

  const handleDownloadable = (value: any) => {
    setState("isDownloadable", value);
  };
  
  return mediaData ? (
    <Modal
      open={true} 
      onClose={onCloseModal}
      disableEscapeKeyDown={false}
    >
      <div className={classes.root} data-cy={isCypress() ? "popupGalleryModal" : null}>
        <Hotkeys 
          keyName="left,right" 
          onKeyDown={keyPress}
        >
          <Drawer className={classes.drawer} anchor='bottom' open={state.isOpenThumbs} onClose={handleThumbs}>
            <div className={classes.thumbsList}>
              <div className={classes.thumbsListWrapper} ref={thumbsWrapper}>
                {
                  messageMediasData.map((item: any, key: any) => (
                    <div className={classes.itemWrapper} onClick={() => setMedia(item.mediaID)} key={`k_${key}`} data-cy={isCypress() ? "thumbItem" + key + "Button" : null}>
                    {                  
                      item.type === "photo" ? (
                        <div className={`${classes.mediaFile} ${mediaIndex === key && 'current'}`}>
                          <AuthenticatedImage thumbLink={item.thumbLink}/>
                        </div>                                                                         
                      ) : item.type === "attachment" ? (
                        <div className={`${classes.mediaFile} ${mediaIndex === key && 'current'}`}>
                          <span>{formatFileSize(item.size)}</span>
                          <SVG src={`${"document-"}${(item.name).split('.').pop()}${""}`} children={<SVG src="file"/>}/>
                          <p>{item.name}</p>  
                        </div>
                      ) : null
                    }
                  </div>
                  ))                  
                }
              </div>
            </div>  
          </Drawer>
          <div className={classes.topBar}>
            <span className={classes.title}>{mediaData.name}</span>
            <div className={classes.buttonWrapper}>
              <IconButton className={classes.button} onClick={handleDownload} disabled={!state.isDownloadable} data-cy={isCypress() ? "downloadMediaButton"  : null}>
                <SVG src="download"/>
              </IconButton>
              {
                messageMediasData.length > 1 ? (
                  <IconButton className={classes.button} onClick={handleThumbs} data-cy={isCypress() ? "thumbsButton" : null}>
                    <SVG src="gallery"/>
                  </IconButton>
                ) : null
              }
              <CloseButton className={classes.button} onClick={handleClose} dataCy="timesButton"/>
            </div>
          </div>
          {
            state.isLoaded ? (
              <div className={classes.preview}>
                {
                  mediaData?.type === "photo" ? (
                    <RenderPhoto mediaData={mediaData} setIsDownloadable={handleDownloadable} handleSecureClick={handleSecureClick}/>
                  ) : mediaData?.type === "attachment" ? (
                    <RenderAttachment mediaData={mediaData} setIsDownloadable={handleDownloadable} handleSecureClick={handleSecureClick}/>
                  ) : mediaData?.type === "video" ? (
                    <RenderVideo mediaData={mediaData} setIsDownloadable={handleDownloadable} handleSecureClick={handleSecureClick}/>
                  ) : (
                    <div>error</div>
                  )
                }
                {
                  messageMediasData.length > 1 ? (
                    <NavigationButton icon={<SVG src="chevron-left"/>} onClick={prevMedia} disabled={state.isFirst} dataCy="prevButton"/>
                  ) : null
                }
                {
                  messageMediasData.length > 1 ? (
                    <NavigationButton icon={<SVG src="chevron-right"/>} onClick={nextMedia} disabled={state.isLast} dataCy="nextButton"/>
                  ) : null
                }
              </div>
            ) : null
          }
        </Hotkeys>
      </div>
    </Modal>
  ) : null;
};

export default PopupGalleryModal;
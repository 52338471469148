import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { setEmployeeModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';
import { getAppEnvironmentApiData } from 'src/utils/useApp';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        '&:first-of-type:not(:last-of-type)': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type:not(:first-of-type)': {
          borderRadius: '0px 20px 0px 0px',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        }, 
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      '&:only-child': {
        '& > span': {
          justifyContent: 'center',
        },
      },
      '& > span': {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        padding: '12px 10px',
      },
    },
  },
  color: {
    width: '20px',
  },
  name: {
    fontWeight: '500',
    maxWidth: '300px',
    width: '300px',
    '& > span': {
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },  
  type: {
    paddingLeft: '8px',
  },
  action: {
    textAlign: 'right',
    paddingRight: '16px',
    width: 'calc(72px - 16px)',
  },
  button: {
    cursor: 'pointer',
    marginRight: '10px',
    '& > svg': {
      color: theme.colors.primaryBlue[250],
      width: '24px',
      height: '24px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
}));

type TimetableEmployeesTableType = {
  schoolID: any;
};

const TimetableEmployeesTable: React.FunctionComponent<TimetableEmployeesTableType> = ({schoolID = null}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dataData = useAppSelector((state: any) => state.data);
  const classes = useStyles();
  const employees = schoolID === null ? [] : dataData.employees.filter((employee: any) => employee.schoolID === schoolID);

  const getEmployeeData = (employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  };

  const handleClickDetail = (employeeID: any) => {
    const settings = {
      isOpen: true,
      employeeID: employeeID,
    };
    dispatch(setEmployeeModal(settings)); 
  };

  const handleClickEdit = (employeeID: any) => {
    const adminUrl = getAppEnvironmentApiData().adminUrl;
    const url =  `${adminUrl}employee/edit/id/${employeeID}`;
    window.open(url);
  };

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.tableContainer}>
        <thead className={classes.tableHead}>
          <tr>
            <th>
              {t('timetable_employee')}
            </th>
            <th>
              {t('timetable_employee_enabled')}
            </th>
            <th>
              {t('action')}
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {
            employees.length > 0 ? employees.map((employee: any, key: any) => (
              <tr className={classes.tableRow} key={`k_${key}`}>
                <td className={classes.name}>
                  <span>{getEmployeeData(employee.employeeID).firstname + ' ' + getEmployeeData(employee.employeeID).surname}</span>
                </td>
                <td className={classes.type}>
                  <span>{employee.enableForTimetable ? t('yes') : t('no')}</span>
                </td>
                <td className={classes.action}>
                  <IconButton className={classes.button} onClick={() => handleClickDetail(employee.employeeID)} tooltip={t('timetable_employee_detail')} tooltipPosition='bottom' tooltipMaxWidth={200}>
                    <SVG src="eye"/>
                  </IconButton>
                  <IconButton className={classes.button} onClick={() => handleClickEdit(employee.employeeID)} tooltip={t('timetable_employee_edit_in_admin')} tooltipPosition='bottom' tooltipMaxWidth={400}>
                    <SVG src="edit"/>
                  </IconButton>
                </td>
              </tr>
            )) : (
              <tr className={classes.tableRow}>
                <td colSpan={4}>
                  <span>{t('no_timetable_employees_found')}</span>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

export default TimetableEmployeesTable;
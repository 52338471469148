import DatePicker from 'src/components/DatePickers/Default';
import moment from 'src/utils/moment';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { Navigate, useLocation } from 'react-router';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useState } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  diagnosticsPage: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 48px)',
    width: '100%',
    padding: '24px',
    height: 'calc(100% - 48px)',
    alignItems: 'center',
    position: 'relative',
    gap: '16px',
  },
  table: {
    width: '100%',
    backgroundColor: theme.colors.white,
    borderCollapse: 'collapse',
    '& > thead': {
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
      '& > tr': {
        '& > th': {
          '&:first-of-type': {
            padding: '0.25rem 0.5rem',
          },
          '&:last-of-type': {
            '& > div': {
              color: theme.colors.black,
            },
          },
        },
      },
    },
    '& > tbody': {
      '& > tr': {
        '& > td': {
          padding: '0.25rem 0.5rem',
          '&:last-of-type': {
            textAlign: 'right',
          },
        },
      },
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const diagnosticsData = useAppSelector((state: any) => state.diagnotics);
  const [date, setDate] = useState(moment());
  const location = useLocation();

  const queryString = getQueryString(location);

  const items: any = {
    kindergarten: {
      "Oblast biologická": ["zachovává správné držení těla", "správně dýchá", "pohybuje se koordinovaně", "hází a chytá míč, používá různé náčiní", "napodobuje pohyb podle vzoru", "zachází s tužkou", "zachází s barvami", "zachází s nůžkami a papírem", "používá běžné předměty", "používá hračky odpovídajícím způsobem", "postará se o sebe a svoje věci", "zvládá stolování", "pojmenovává části těla a některé vnitřní orgány", "chová se opatrně a bezpečně"],
      "Oblast psychologická - jazyk a řeč": ["pojmenovává většinu věcí, dějů kolem sebe", "správně vyslovuje, mluví jasně a zřetelně", "mluví gramaticky správně", "zachytí myšlenku příběhu a zopakuje ji správně", "vede rozhovor, ptá se, slovně reaguje", "reprodukuje říkanky a písničky", "chápe slovní vtip a humor", "sleduje očima zleva doprava", "pozná některá písmena a čislice", "pozná napsané své jméno"],
      "Oblast psychologická - poznávání, představy a fantazie, myšlenkové operace": ["dokáže se soustředit na činnost a udržet pozornost", "postřehne, co je nové a změněné", "rozlišuje tvary a další vlastnosti předmětů zrakem i hmatem", "rozlišuje a zná barvy", "rozlišuje zvuky a tóny", "vyjadřuje své představy, uplatňuje vlastní fantazii", "je tvořivé a nápadité, vyjadřuje se prostřednictvím nejrůznějších tvořivých činností", "chápe význam piktogramů a jiných obrázků", "rozlišuje základní geometrické tvary", "porovnává , uspořává a třídí předměty podle určitého pravidla", "orientuje se v elementárním počtu do 10", "chápe prostorové pojmy", "chápe elementární časové pojmy", "učí se nazpaměť krátké texty, pamatuje si je", "postupuje podle instrukcí a pokynů", "odlišuje hru od systematické práce", "kreslí, modeluje, tvoří a vytváří (výtvarné činnosti)", "zpívá, zachází s nástroji, pohybuje se do rytmu"],
      "C": ["je samostatné", "má svůj názor", "umí souhlasit i odmítnout", "uvědomuje si své silné a slabé stránky", "vyrovnává se s neúspěchem", "soustředí se na činnost a její dokončení", "respektuje určitá pravidla", "zorganizuje činnost či hru", "projevuje radost, soucit, náklonnost", "projevuje  nadšení"],
      "Oblast interpersonální": ["přirozeně komunikuje s tetami a strejdou", "přirozeně komunikuje s ostatními dětmi", "bere ohled, respektuje druhé", "společně pracuje i si společně hraje", "dělí se o hračky a pomůcky", "problémy řeší v klidu", "neposmívá se druhému a neubližuje", "brání druhé"],
      "Oblast sociálně - kulturní": ["neposmívá se druhému a neubližuje", "zdraví známé děti i dospělé", "vyslechne druhé, neskáče do řeči", "poprosí, poděkuje, požádá o pomoc", "dodržuje pravidla skupiny", "podřídí se kolektivnímu programu", "spolupracuje s ostatními dětmi", "domluví se na společném řešení"],
      "Oblast envirinmentální": ["má poznatky o svém okolí - přírodě, životě lidí a kultuře svého prostředí", "orientuje se bezpečně v běžném životě", "v běžných situacích a ve známém prostředí se chová přirozeně", "ví, co je dobré a co špatné", "je aktivní a podnikavé", "chová se šetrně k přírodě", "má základní poznatky o EKO výchově"],
    },
    preschooler: {
      "PRAKTICKÁ SAMOSTATNOST - fyzický rozvoj a pohybová koordinace, sebeobsluha": ["je dostatečně fyzicky vyspělé", "jeho pohyby jsou koordinované (hází a chytá míč, udrží rovnováhu na jedné noze, běhá, skáče, v běžném prostředí se pohybuje bezpečně)", "samostatně se obléká", "je samostatné při jídle", "je samostatné při zvládání pravidelných denních úkonů (hygiena, příprava či úklid pomůcek a hraček apod.)", "dokáže se postarat o své věci"],
      "SOCIÁLNÍ INFORMOVANOST - orientace v prostředí, v okolním světě i v praktickém životě": ["vyzná se ve svém okolí (ví, kde bydlí, kam chodí do školky, apod.)", "rozumí běžným okolnostem, dějům, jevům, situacím, s nimiž se setkává", "ve známém prostředí se pohybuje samostatně", "s pomocí dospělého se dokáže zorientovat v cizím prostředí", "dovede vyřídit drobný vzkaz", "dokáže si přesně říci o to, co potřebuje", "odhadne nebezpečnou situaci, neriskuje"],
      "CITOVÁ SAMOSTATNOST - emoční stabilita, schopnost kontrolovat a řídit své chování": ["zvládá odloučení od rodičů", "vystupuje samostatně, má svůj názor,  dokáže vyjádřit souhlas i nesouhlas", "projevuje se jako emočně stálé", "reaguje přiměřeně na drobný neúspěch", "dovede odložit zájmy na pozdější dobu", "dovede se přizpůsobit konkrétní činnosti či situaci"],
      "SOCIÁLNÍ SAMOSTATNOST - soužití s vrstevníky , uplatnění ve skupině vrstevníků": ["uplatňuje základní společenská pravidla (pozdrav, požádat, poděkovat, omluvit se)", "dovede se vcítit do potřeb druhých", "dokáže vyslovit a obhájit svůj názor", "dokáže vyjednávat a dohodnout se", "dovede se zapojit do skupiny, bez projevů prosazování či naopak", "komunikuje a spolupracuje s ostatními dětmi", "při skupinových činnostech se snaží přispět svým podílem", "pokud jsou zadány úkoly, je schopno se s jimi řídit"],
      "Výslovnost, gramatická správnost řeči, slovní zásoba, bezproblémová komunikace": ["vyslovuje správně všechny hlásky", "mluví ve větách, vypráví příběh, popisovat, apod.", "mluví gramaticky správně (rod, čísla, čas, tvar, …)", "rozumí většině slov a výrazů běžně používaných", "běžně komunikuje s dospělými i dětmi", "rozumí pojmům vyjadřujícíc časoprostorovou orientaci (nad, pod, vedle, dole, nahoře, malý, těžký, dříve, včera, dnes, apod.)", "zná pojmy tvarů (čtverec, kruh, apod.)"],
      "Lateralita ruky, koordinace ruky a oka, správné držení tužky": ["upřednosňuje užívání pravé či levé ruky při kreslení či v jiných činnostech", "je zručné při zacházení s předměty, hračkami, pomůckami a nástroji (modeluje, stříhá, kreslí, ….)", "tužku drží správně", "dokáže vést stopu tužky a napodobit základní geometrické obrazce, různé tvary (písmena, ….)"],
      "DIFERENCOVÉ VNÍMÁNÍ - sluchová a zraková analýza a syntéza": ["rozlišuje podstatné znaky předmětů (barvy, tvary, figuru a pozadí)", "dokáže pracovat se strukturou slov (hraje slovní fotbal, vytleskává slabiky)", "dokáže najít rozdíly na dvou obrázcích", "dovede složit obrázek z několika tvarů a slovo z několika slabik", "postřehne změny ve svém okolí"],
      "LOGICKÉ A MYŠLENKOVÉ OPERACE - porovnávání, třídění, řazení, číselné představy, řešení problémů": ["dovede porovnávat vlastnosti předmětů (velikost, tvar)", "dovede třídit předměty podle daného klíče (korálky, podle barev, tvaru, velikosti)", "dovede seřadit předměty podle velikosti vzestupně i sestupně (pastelky, sirky, páratka, ….)", "vyjmenuje číselnou řadu a spočítá počet prvků v rozsahu do deseti a více", "přemýšlí, uvažuje (odpovídá na položené otázky, řeší jednoduché problémy a situace, slovní příklady, úlohy, hádanky, rébusy)"],
      "Záměrná pozornost, úmyslná paměť pro učení": ["dovede soustředit pozornost na činnosti po určitou dobu (do 30 minut), neodbíhá od nich", "soustředí se i na činnosti, které nejsou aktuálně pro ně aktuálně zajímavé", "dokáže si záměrně pamatovat, co prožilo, vidělo, slyšelo, dokáže si po určité době je vybavit a reprodukovat"],
      "Pracovní chování, soustředěná učební činnost, záměrné učení": ["dokáže přijmout úkol či povinnost", "dokáže postupovat podle pokynů", "zadaným činnostem se věnuje soustředěně, neodbíhá k jiným, dokáže vyvinout úsilí k jejich dokončení", "dovede odlišit hru od systematické práce", "\"nechá\" se získat pro záměrné učení"],
    },
  };
  
  return diagnosticsData.type !== null ? (
    <div className={classes.diagnosticsPage}>
      {
        Object.keys(items[diagnosticsData.type]).map((theme: any, tkey: any) => (
          <table className={classes.table} key={`k_${tkey}`}>
            <thead>
              <tr>
                <th colSpan={tkey === 0 ? 1 : 2}>{theme}</th>
                {tkey === 0 ? (<th><DatePicker presetDate={date} setDate={setDate}/></th>) : null}
              </tr>
            </thead>
            <tbody>
              {
                items[diagnosticsData.type][theme].map((item: any, ikey: any) => (
                  <tr key={`k_${ikey}`}>
                    <td>{item}</td>
                    <td>
                      <select defaultValue=" ">
                        <option disabled value=" "></option>
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="N">N</option>
                      </select>
                    </td>
                  </tr>
                ))
              }  
            </tbody>
          </table>
        ))
      }
      <NormalButton buttonType="primary">
        {t('save')}
      </NormalButton>
    </div>
  ) : (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  );
};

export default PageTemplate;
import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import CommunicationGallery from 'src/components/Gallery/CommunicationGallery';
import DateFormat from 'src/utils/dateFormat';
import DropdownWithMenu from 'src/components/Buttons/DropdownWithMenu';
import htmlParse from 'html-react-parser';
import React, { useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import Tooltip from 'src/components/Layouts/Tooltip';
import useBreakpoint from 'src/utils/useBreakpoint';
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from 'src/utils/useContextMenu';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting } from 'src/utils/useUser';
import { isMobile } from 'react-device-detect';
import { handleSum, isOnlyEmoji } from 'src/utils/useFunctions';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { updateThreadData } from 'src/store/actions/communication.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import ReactionsPopper from 'src/components/Reactions/Popper';
import ReactionsList from 'src/components/Reactions/List';

interface Props {
  isDropdownActive?: any;
  isMessageActive?: any;
  isEditMessageID?: any;
  isEdited?: any;
  isDeleted?: any;
  isMobile?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  messageFrom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    width: '100%',
    '& > span': {
      fontSize: '10px',
      marginLeft: '48px',
    },
  },
  messageTo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: '100%',
  },
  messageFromWrapper: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    '& > input': {
      display: 'none',
    },
    '& > div + div + div': {
      opacity: (props: Props) => {
        if(props.isDropdownActive || props.isMobile) return '1';
        else return '0';
      },
      transition: 'opacity 0.25s',
    },
    '& > div:first-of-type + div': {
      alignItems: 'flex-start',
      borderColor: (props: Props) => {
        if(props.isDeleted) return theme.colors.grey[500];
        else return theme.colors.grey[200];
      },
      '& > p': {
        fontSize: '12px',
        fontWeight: '500',
        color: theme.colors.black,
      },
      '& > span': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'inherit',
        backgroundColor: (props: Props) => {
          if(props.isDeleted) return 'transparent';
          else return theme.colors.grey[200];
        },
        color: (props: Props) => {
          if(props.isDeleted) return theme.colors.grey[500];
          else return theme.colors.black;
        },
        fontWeight: (props: Props) => {
          if(props.isDeleted) return '500';
          else return '400';
        },
      },
    },
    '&:hover': {
      '& > div + div + div': {
        opacity: '1',
      },
    },
  },
  messageFromAvatarWrapper: {
    width: '34px',
    height: '34px',
  },
  messageFromAvatar: {
    width: '34px',
    height: '34px',
    position: 'relative',
    '& > div': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.colors.grey[325],
      borderRadius: '12px',
      width: 'calc(100% - 2px)',
      height: 'calc(100% - 2px)',
    }
  },
  messageToWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '8px',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    '& > input': {
      display: 'none',
    },
    '& > div:first-of-type:not(:last-of-type)': {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      marginTop: (props: Props) => {
        if(props.isEdited) return '22px';
        else return '';
      },
      opacity: (props: Props) => {
        if(props.isDropdownActive || props.isMobile) return '1';
        else return '0';
      },
      transition: 'opacity 0.25s',
    },
    '& > div:last-of-type': {
      alignItems: 'flex-end',
      borderColor: (props: Props) => {
        if(props.isDeleted) return theme.colors.primaryBlue[500];
        else if(props.isEditMessageID) return theme.colors.primaryBlue[600];
        else return theme.colors.primaryBlue[500];
      },
      '& > p': {
        fontSize: '12px',
        fontWeight: '500',
        color: theme.colors.primaryBlue[500],
      },
      '& > span': {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: 'inherit',
        backgroundColor: (props: Props) => {
          if(props.isDeleted) return 'transparent';
          else if(props.isEditMessageID) return theme.colors.primaryBlue[600];
          else return theme.colors.primaryBlue[500];
        },
        color: (props: Props) => {
          if(props.isDeleted) return theme.colors.primaryBlue[500];
          else return theme.colors.white;
        },
        fontWeight: (props: Props) => {
          if(props.isDeleted) return '500';
          else return '400';
        },
      },
    },
    '&:hover': {
      '& > div:first-of-type:not(:last-of-type)': {
        opacity: '1',
      },
    },
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    gap: '4px',
    position: 'relative',
  },
  messageBox: {
    fontSize: '14px',
    fontFamily: `'Poppins', 'Noto Color Emoji', sans-serif`,
    padding: '8px 12px',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
    width: 'fit-content',
    maxWidth: '500px',
    '& abbr': {
      backgroundColor: theme.colors.yellow[500],
      padding: '2px 4px',
      fontWeight: '500',
    },
  },
  emoji: {
    fontSize: '28px',
    fontFamily: `'Poppins', 'Noto Color Emoji', sans-serif`,
    userSelect: 'none',
    '-moz-user-select': 'none',
    '-webkit-user-drag': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
    backgroundColor: 'transparent !important',
    borderWidth: '0 !important',
  },
  dropdownMenu: {
    position: 'relative',
  },
  dropdownMenuPopover: {
    borderRadius: '14px',
  },
  dropdownMenuButton: {
    backgroundColor: theme.colors.grey[100],
  },
  moreButton: {
    backgroundColor: theme.colors.grey[200],
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '&:disabled': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[75],
    },
    '& > svg': {
      width: '14px',
      height: '14px',
    },
  },
  reactionButton: {
    backgroundColor: theme.colors.grey[200],
    boxShadow: theme.shadows[2],
    width: '26px',
    height: '26px',
    padding: '0',
    minWidth: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    '&:hover': {
      backgroundColor: theme.colors.grey[300],
    },
    '&:disabled': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[75],
    },
    '& > span': {
      fontSize: '0',
      margin: 'unset',
      '& > svg': {
        width: '14px',
        height: '14px',
      },
    },
  },
  dateTimeInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '10px',
    overflow: 'hidden',
    height: (props: Props) => {
      if(props.isMessageActive) return '24px';
      else return '0px';
    },
    '& > p': {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        width: '10px',
        height: '10px',
        marginRight: '2px',
      },
    },
  },
  contextMenu: {
    minWidth: 'unset',
  },
  reactions: {
    position: 'absolute',
    right: '-5px',
    bottom: '-10px',
    padding: '2px 4px',
    borderRadius: '14px',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.colors.white,
    zIndex: '1',
    '& > div': {
      '& > div': {
        width: '16px',
        height: '16px',
        fontSize: '60%',
      }
    }
  },
}));

type MessageType = {
  threadID: any;
  activeMessageID: any;
  setActiveMessageID: any;
  messages: any;
  setMessages: any;
  messageID: any;
  editMessageID: any;
  setEditMessageID: any;
  search: any;
  messagesContainerRef: any;
};

const Message: React.FunctionComponent<MessageType> = ({ threadID, activeMessageID, setActiveMessageID, messages, setMessages, messageID, editMessageID, setEditMessageID, search, messagesContainerRef }) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const communicationData = useAppSelector((state: any) => state.communication);
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const communicationService = useAppSelector((state: any) => state.services).communicationService;
  const inputRef: any = useRef(null);

  const [state, setState] = useStates({
    isDropdownActive: false,
  });

  const breakpoint: any = useBreakpoint();

  const layouts: any = {
    xxxxl: "desktop",
    xxxl: "desktop",
    xxl: "desktop",
    xl: "desktop",
    lg: "desktop",
    bg: "desktop",
    md: "mobile",
    co: "mobile",
    sm: "mobile",
    xs: "mobile",
  };

  const customViewMode = communicationData.viewMode;
  const layout = customViewMode ? "mobile" : layouts[breakpoint];

  const getUserData = useCallback((userID: any) => {
    return dataData.users.filter((item: any) => item.userID === userID).length === 0 ? dataData.users.find((item: any) => item.userID === -1) : dataData.users.find((item: any) => item.userID === userID);
  }, [dataData.users]);

  const getThreadData = useCallback((threadID: any) => {
    return communicationData.list.filter((item: any) => item.threadID === threadID).length === 0 ? null : communicationData.list.find((item: any) => item.threadID === threadID);
  }, [communicationData.list]);

  const getMessageData = useCallback((messageID: any) => {
    return messages.filter((item: any) => item.messageID === messageID).length === 0 ? null : messages.find((item: any) => item.messageID === messageID);
  }, [messages]);

  const threadData = getThreadData(threadID);
  const isThreadReady = threadData && threadData.length !== 0;
  const users = isThreadReady ? threadData.users.filter((item: any) => item.userID !== userData.userObject.userID && getUserData(item.userID)).map((item: any) => { return item.userID; }) : [];
  const isGroup = isThreadReady ? users.length > 1 : false;
  const isThreadAuthor = isThreadReady ? userData.userObject.userID === threadData.authorID : false;

  const messageData = getMessageData(messageID);
  const isMessageReady = messageData && messageData.length !== 0;
  const isLastMessage = messages.filter((item: any) => item.messageID === messageID).length === 0 ? false : messages.findIndex((item: any) => item.messageID === messageID) === (messages.length - 1);

  const isEditable = isMessageReady ? messageData.editable : false;
  const isDeletable = isMessageReady ? messageData.deletable : false;
  const isDeleted = isMessageReady ? messageData.deleted : false;
  const isEdited = isMessageReady ? (messageData.modifiedDate && !isDeleted) : false;

  const isMessageAuthor = userData.userObject.userID === messageData.authorID;
  const authorData = getUserData(messageData.authorID);
  const authorAvatar = authorData ? (authorData.photo ? authorData.photo.thumbLink : "") : "";
 
  const classes = useStyles({
    isDropdownActive: state.isDropdownActive,
    isMessageActive: activeMessageID === messageID,
    isEditMessageID: editMessageID === messageID,
    isEdited: isEdited,
    isDeleted: isDeleted,
    isMobile: isMobile,
  });

  const dropdownMenuItems = (isMessageAuthor ? [
    !isDeleted ? {
      title: t('communication_message_copy'),
      icon: (<SVG src="copy"/>),
      onClick: () => handleCopyMessage(),
      dataCy: '',
    } : null,
    isEditable ? {
      title: t('communication_message_edit'),
      icon: (<SVG src="pencil"/>),
      onClick: () => handleEditMessage(),
      dataCy: '',
    } : null,
    isDeletable ? {
      title: t('communication_message_delete'),
      icon: (<SVG src="trash"/>),
      onClick: () => handleDeleteMessage(),
      dataCy: '',
    } : null,
  ] : [
    !isDeleted ? {
      title: t('communication_message_copy'),
      icon: (<SVG src="copy"/>),
      onClick: () => handleCopyMessage(),
      dataCy: '',
    } : null,
    (isThreadAuthor && isDeletable) ? {
      title: t('communication_message_delete'),
      icon: (<SVG src="trash"/>),
      onClick: () => handleDeleteMessage(),
      dataCy: '',
    } : null,
  ]).filter((item: any) => item !== null);

  const handleDropdownClick = (status: any) => {
    setState("isDropdownActive", status);
  };

  const handleToggleMessageActive = () => {
    if(layout === "mobile") {
      if(activeMessageID === messageID) {
        setActiveMessageID(null);
      } else {
        setActiveMessageID(messageID);
      }
      if(isLastMessage) {
        if(messagesContainerRef && messagesContainerRef.current) {
          setTimeout(() => {
            if(messagesContainerRef && messagesContainerRef.current) {
              messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            }
          } , 1);
        }
      }
    }
  };

  const handleCopyMessage = () => {
    const input = inputRef.current;
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(messageData.text);
  };

  const handleEditMessage = () => {
    setEditMessageID(messageID);
  };

  const handleDeleteMessage = () => {

    const handleDelete = () => {
      communicationService && communicationService.deleteMessage(messageID).then((result: any) => {
        if(result) {
          if(result.status === 200) {
            const response = result.data;
            const newMessages = messages.map((item: any) => {
              if(item.messageID === response.messageID) {
                return {...item, ...response};
              } else {
                return item;
              }
            });
            setMessages(newMessages);
            const newData = {
              threadID: threadID,
              data: response.thread,
            };
            dispatch(updateThreadData(newData));
            createNotification(t("community_message_deleted"), "success");
          } else {
            createNotification(t("community_message_failed_delete"), "error");
          }
        } else {
          createNotification(t("community_message_failed_delete"), "error");
        }
      }).catch(() => {
        createNotification(t("community_message_failed_delete"), "error");
      });
    };

    const settings = {
      isOpen: true,
      title: t('communication_message_delete'),
      content: t('communication_message_delete_confirm'),
      onAccept: handleDelete,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const getMessageText = () => {
    let text = messageData.text ? messageData.text : "";
    if(search && search.length >= 3) {
      const regex = new RegExp(search, "gi");
      text = text.replace(regex, (match: any) => `<abbr>${match}</abbr>`);
    }
    return text;
  };

  const messageText = getMessageText();

  return isMessageAuthor ? (
    <div className={classes.messageTo}>
      <div className={classes.messageToWrapper}>
        <input ref={inputRef}/>
        <div>
          {
            dropdownMenuItems.length > 0 ? (
              <DropdownWithMenu className={classes.dropdownMenu} items={dropdownMenuItems} classNamePopover={classes.dropdownMenuPopover} classNameMenuButton={classes.dropdownMenuButton} classNameMoreButton={classes.moreButton} moreButtonSize='small' position='top-center' onClick={handleDropdownClick} popoverArrow/>
            ) : null
          }
          {
            (messageData.reactions && !isDeleted) ? (
              <ReactionsPopper data={messageData} ID={messageID} type='communication' classNameReactionButton={classes.reactionButton}/>
            ) : null
          }
        </div>
        <div className={classes.messageContent}>
          {
            (messageData.modifiedDate && !messageData.deleted) ? (
              <Tooltip title={DateFormat(messageData.modifiedDate, "timeline", languageData, t)} position='left' maxWidth={400} disableOnMobile={true} disableInteractive={true} arrow>
                <p>{t('communication_message_edited')}</p>
              </Tooltip>
            ) : null
          }
          <ContextMenu className={classes.contextMenu} id={`message_${messageID}`} preventHideOnScroll={false} hideOnLeave={true}>
            {
              (messageData.reactions && !isDeleted) ? (
                <ContextMenuItem disableHover={true}>
                  <ReactionsPopper display='list' data={messageData} ID={messageID} type='communication'/>
                </ContextMenuItem>
              ) : null
            }
            {
              messageData.text ? (
                <ContextMenuItem onClick={handleCopyMessage}>
                  <SVG src="copy"/>
                  {t('communication_message_copy')}
                </ContextMenuItem>
              ) : null
            }
            {
              isEditable ? (
                <ContextMenuItem onClick={handleEditMessage}>
                  <SVG src="edit"/>
                  {t('communication_message_edit')}
                </ContextMenuItem>
              ) : null
            }
            {
              isDeletable ? (
                <ContextMenuItem onClick={handleDeleteMessage}>
                  <SVG src="trash"/>
                  {t('communication_message_delete')}
                </ContextMenuItem>
              ) : null
            }
          </ContextMenu>
          {
            messageData.text ? (
              <Tooltip title={DateFormat(messageData.createdDate, "timeline", languageData, t)} position='left' maxWidth={400} disableOnMobile={true} disableInteractive={true} arrow>
                <>
                  <span className={isOnlyEmoji(messageData.text) ? classes.emoji : classes.messageBox} onClick={handleToggleMessageActive}>
                    <ContextMenuTrigger id={`message_${messageID}`} renderTag={"span"} dataCy={`message${messageID}`} disable={isMobile || !getUserSetting(userData.userSettings, "addons", ["communication", "communication_message_context_menu"])}>
                      {htmlParse(messageText)}
                    </ContextMenuTrigger>
                  </span>
                  {
                    ((messageData.reactions && messageData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (messageData.reactions && messageData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0)) ? (
                      <ReactionsList className={classes.reactions} data={messageData} ID={messageID} type='communication'/>
                    ) : null
                  }
                </>
              </Tooltip>
            ) : null
          }
          {
            messageData.media.length > 0 ? (
              <>
              <CommunicationGallery messages={messages} messageID={messageID} align='flex-end'/>
              {
                (!messageData.text && ((messageData.reactions && messageData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (messageData.reactions && messageData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0))) ? (
                  <ReactionsList className={classes.reactions} data={messageData} ID={messageID} type='communication'/>
                ) : null
              }
            </>
            ) : null
          }
        </div>
      </div>
      {
        (activeMessageID === messageID && layout === "mobile") ? (
          <span className={classes.dateTimeInfo}>
            <p>
              {DateFormat(messageData.createdDate, "timeline", languageData, t)}
            </p>
            {
              (messageData.modifiedDate && !messageData.deleted) ? (
                <p>(<SVG src="pencil"/> {DateFormat(messageData.modifiedDate, "timeline", languageData, t)})</p>
              ) : null
            }
          </span>
        ) : null
      }
      </div>
  ) : (
    <div className={classes.messageFrom}>
      {
        isGroup ? ( 
          <span>{authorData.displayName}</span>
        ) : null
      }
      <div className={classes.messageFromWrapper}>
        <input ref={inputRef}/>
        <Tooltip title={authorData.displayName} position='left' maxWidth={400} disableOnMobile={true} arrow>
          <div className={classes.messageFromAvatarWrapper}>
            <AuthenticatedImage className={classes.messageFromAvatar} thumbLink={authorAvatar}/>
          </div>
        </Tooltip>
        <div className={classes.messageContent}>
          {
            (messageData.modifiedDate && !messageData.deleted) ? (
              <Tooltip title={DateFormat(messageData.modifiedDate, "timeline", languageData, t)} position='left' maxWidth={400} disableOnMobile={true} disableInteractive={true} arrow>
                <p>{t('communication_message_edited')}</p>
              </Tooltip>
            ) : null
          }
          <ContextMenu className={classes.contextMenu} id={`message_${messageID}`} preventHideOnScroll={false} hideOnLeave={true}>
            {
              (messageData.reactions && !isDeleted) ? (
                <ContextMenuItem disableHover={true}>
                  <ReactionsPopper display='list' data={messageData} ID={messageID} type='communication'/>
                </ContextMenuItem>
              ) : null
            }
            {
              messageData.text ? (
                <ContextMenuItem onClick={handleCopyMessage}>
                  <SVG src="copy"/>
                  {t('communication_message_copy')}
                </ContextMenuItem>
              ) : null
            }
            {
              (isThreadAuthor && isDeletable) ? (
                <ContextMenuItem onClick={handleDeleteMessage}>
                  <SVG src="trash"/>
                  {t('communication_message_delete')}
                </ContextMenuItem>
              ) : null
            }
          </ContextMenu>
          {
            messageData.text ? (
              <Tooltip title={DateFormat(messageData.createdDate, "timeline", languageData, t)} position='left' maxWidth={400} disableOnMobile={true} disableInteractive={true} arrow>
                <>
                  <span className={isOnlyEmoji(messageData.text) ? classes.emoji : classes.messageBox} onClick={handleToggleMessageActive}>
                    <ContextMenuTrigger id={`message_${messageID}`} renderTag={"span"} dataCy={`message${messageID}`} disable={isMobile || !getUserSetting(userData.userSettings, "addons", ["communication", "communication_message_context_menu"])}>
                      {htmlParse(messageText)}
                    </ContextMenuTrigger>
                  </span>
                  {
                    ((messageData.reactions && messageData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (messageData.reactions && messageData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0)) ? (
                      <ReactionsList className={classes.reactions} data={messageData} ID={messageID} type='communication'/>
                    ) : null
                  }
                </>
              </Tooltip>
            ) : null
          }
          {
            messageData.media.length > 0 ? (
              <>
                <CommunicationGallery messages={messages} messageID={messageID} align='flex-start'/>
                {
                  (!messageData.text && ((messageData.reactions && messageData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0) || (messageData.reactions && messageData.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0) > 0))) ? (
                    <ReactionsList className={classes.reactions} data={messageData} ID={messageID} type='communication'/>
                  ) : null
                }
              </>
            ) : null
          }
        </div>
        {
          (messageData.reactions && !isDeleted) ? (
            <ReactionsPopper data={messageData} ID={messageID} type='communication' classNameReactionButton={classes.reactionButton}/>
          ) : null
        }
        {
          dropdownMenuItems.length > 0 ? (
            <DropdownWithMenu className={classes.dropdownMenu} items={dropdownMenuItems} classNamePopover={classes.dropdownMenuPopover} classNameMenuButton={classes.dropdownMenuButton} classNameMoreButton={classes.moreButton} moreButtonSize='small' position='top-center' onClick={handleDropdownClick} popoverArrow/>
          ) : null
        }
      </div>
      {
        (activeMessageID === messageID && layout === "mobile") ? (
          <span className={classes.dateTimeInfo}>
            <p>
              {DateFormat(messageData.createdDate, "timeline", languageData, t)}
            </p>
            {
              (messageData.modifiedDate && !messageData.deleted) ? (
                <p>(<SVG src="pencil"/> {DateFormat(messageData.modifiedDate, "timeline", languageData, t)})</p>
              ) : null
            }
          </span>
        ) : null
      }
    </div>
  );
};

export default Message;
import Modal from '../../../utils/modal';
import PopupCard from 'src/components/Cards/PopupCard';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setPopup } from 'src/store/actions/popups.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { useTranslation } from 'react-i18next';

interface Props {
  browserHeight?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "0px",
    overflow: "auto",
    position: "fixed",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100vw',
    padding: '24px 0',
    height: (props: Props) => props.browserHeight + 'px',
    maxHeight: (props: Props) => props.browserHeight + 'px',
  },
  popupModal: {
    width: "800px",
    maxWidth: '90vw',
    maxHeight: 'calc(100vh - 48px)',
  },
}));

const PopupModal: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const browserData = useAppSelector((state: any) => state.browser);
  const popupsData = useAppSelector((state: any) => state.popups);

  const classes = useStyles({
    browserHeight: browserData.browserHeight,
  });

  const popupData = popupsData.popup;
 
  const onClose = () => {
    dispatch(setPopup(null));
  };

  const onCloseModal = (e: any, reason: any) => {
    if(reason === "backdropClick" || "escapeKeyDown") {
      if(reason === "backdropClick") {
        if(!e.target.getAttribute("data-type") || e.target.getAttribute("data-type") !== "backdrop") {
          return;
        }
      }
      const settings = {
        isOpen: true,
        title: t('popup_close'),
        content: t('popup_close_confirm'),
        onAccept: onClose,
        onDecline: null,
      };
      dispatch(setConfirmModal(settings));
      return;
    } 
    onClose();
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-type="backdrop" data-cy={isCypress() ? "popupModal" : null} onClick={(e: any) => onCloseModal(e, 'backdropClick')}>
        <div className={classes.popupModal}>
          <PopupCard popupData={popupData} inModal={true} disableMoreContent={false} disableButtonToggleViewed={true} onClose={onClose}/>
        </div>
      </div>
    </Modal>
  );
};

export default PopupModal;
import * as UserService from '../../../../../services/user.service';
import Banner from '../../../../../components/Layouts/Banner';
import Input from '../../../../../components/Forms/Input';
import NormalButton from '../../../../../components/Buttons/NormalButton';
import React, { useRef, useState } from 'react';
import SHA1 from '../../../../../services/crypto.service';
import tabs from 'src/constants/tabs';
import TabsMenu from 'src/components/Menus/TabsMenu';
import { createNotification } from '../../../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getPasswordWeakness, validateEmail, validatePassword } from '../../../../../utils/useValidation';
import { getUserRole } from '../../../../../utils/useUser';
import { setSSOGetModal } from 'src/store/actions/modals.actions';
import { setUserObject } from '../../../../../store/actions/user.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks';
import { useStates } from '../../../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  securityWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  tabsWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: '100%',
    justifyContent: 'center',
    '& > div': {
      width: '100%',
    },
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    gap: '16px',
    maxWidth: '100%',
    marginBottom: '20px',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: '0px',
      marginBottom: '0px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 60px)',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: '24px',
    padding: '4px 30px 20px 30px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    height: 'fit-content',
    '& > button': {
      marginTop: '16px',
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& > span': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      width: '100%',
    },
  },
  info: {
    fontSize: '12px',
    marginBottom: '8px',
  },
  buttons: {
    display: 'flex',
    gap: '8px',
  },
  banner: {
    marginTop: '16px',
  },
  tooltip: {
    backgroundColor: theme.colors.systemRed[500],
    color: theme.colors.white,
  },
  tooltipArrow: {
    color: theme.colors.systemRed[500],
  },
}));

const SecuritySettings: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const userData = useAppSelector((state: any) => state.user);
  const actualEmailInput: any = useRef({});
  const newEmailInput: any = useRef({});
  const actualPasswordInput: any = useRef({});
  const newPasswordInput: any = useRef({});
  const confirmPasswordInput: any = useRef({});

  const tabsItems = tabs.settings.security.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));
  const [viewMode, setViewMode] = useState(tabsItems[0].value);

  const defaultEmailValues = {
    actualEmail: userData.userObject.email,
    newEmail: '',
  };

  const defaultPasswordValues = {
    actualPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [emailValues, setEmailValues] = useStates(defaultEmailValues);
  const [passwordValues, setPasswordValues] = useStates(defaultPasswordValues);

  const [state, setState] = useStates({
    isEmailChanged: false,
    isNewPasswordBlurred: true,
    isConfirmPasswordBlurred: false,
  });

  const isFilledEmail = emailValues.actualEmail !== '' && emailValues.newEmail !== '' && emailValues.newEmail !== emailValues.actualEmail;
  const isFilledPassword = passwordValues.actualPassword !== '' && passwordValues.newPassword !== '' && passwordValues.confirmPassword !== '';

  const passwordWeakness = getPasswordWeakness(passwordValues.newPassword);
  const newPasswordPopupShow = passwordValues.newPassword.length > 0 && passwordWeakness.length !== 0 && !state.isNewPasswordBlurred;
  const newPasswordPopupContent = () => {
    const content = (
      <>
        {
          passwordWeakness.indexOf("length") !== -1 ? (
            <>{t('password_required_length')}<br/></>
          ) : null
        }
        {
          passwordWeakness.indexOf("lowercase") !== -1 ? (
            <>{t('password_required_lowercase')}<br/></>
          ) : null
        }
        {
          passwordWeakness.indexOf("uppercase") !== -1 ? (
            <>{t('password_required_uppercase')}<br/></>
          ) : null
        }
        {
          passwordWeakness.indexOf("number") !== -1 ? (
            <>{t('password_required_number')}<br/></>
          ) : null
        }
      </>
    );
    return content;
  };

  const confirmPasswordPopupShow = passwordValues.newPassword.length > 0 && passwordValues.confirmPassword.length > 0 && passwordValues.newPassword !== passwordValues.confirmPassword && state.isConfirmPasswordBlurred && state.isNewPasswordBlurred;
  const confirmPasswordPopupContent = t('password_must_be_match');

  const handleFocusConfirmPasswordInput = () => {
    setState("isConfirmPasswordBlurred", false);
  };

  const handleBlurConfirmPasswordInput = () => {
    setState("isConfirmPasswordBlurred", true);
  };

  const handleFocusNewPasswordInput = () => {
    setState("isNewPasswordBlurred", false);
  };

  const handleBlurNewPasswordInput = () => {
    setState("isNewPasswordBlurred", true);
  };

  const handleEmailInput = (name: any, value: any) => {
    setEmailValues(name, value);
  };

  const handlePasswordInput = (name: any, value: any) => {
    setPasswordValues(name, value);
  };

  const sendEmailChange = () => {
    let payload: any = {};
    Object.keys(emailValues).forEach((key: any) => {
      if(key !== "actualEmail") {
        payload[key] = emailValues[key as keyof typeof emailValues];
      }
    });
    if(Object.keys(payload).length > 0) {
      if(validateEmail(emailValues.newEmail)) {
        if(emailValues.newEmail !== emailValues.actualEmail) {
          UserService && UserService.changeEmail(payload).then((result: any) => {
            if(result.status === 201) { 
              let newUser = userData.userObject;
              const newRequest = {
                open: true,
                email: emailValues.newEmail,
              }
              newUser = {...newUser, emailChangeRequest: newRequest};
              dispatch(setUserObject(newUser));
              setState("isEmailChanged", true);
              createNotification(t('email_request_sent'), 'success');
            } else {
              createNotification(t('something_went_wrong'), 'error');
            }
          }).catch((e: any) => {
            if(e.response.status === 403) {
              createNotification(t('email_already_used'), 'error');
            } else if(e.response.status === 404) {
              createNotification(t('email_request_exist'), 'error');
            } else {
              createNotification(t('something_went_wrong'), 'error');
            }
          });
        } else {
          createNotification(t('email_same'), 'error');
        }
      } else {
        createNotification(t('email_invalid'), 'error');
      }
    }
  };

  const cancelEmailChange = () => {
    UserService && UserService.deleteChangeEmail().then((result: any) => {
      if(result.status === 201) { 
        let newUser = userData.userObject;
        const newRequest = {
          open: false,
          email: "",
        }
        newUser = {...newUser, emailChangeRequest: newRequest};
        dispatch(setUserObject(newUser));
        newEmailInput.current.querySelector("input").value = '';
        createNotification(t('email_request_canceled'), 'success');
      } else {
        createNotification(t('something_went_wrong'), 'error');
      }
    }).catch((e: any) => {
      createNotification(t('something_went_wrong'), 'error');
    });
  };

  const savePasswordChanges = () => {
    let payload: any = {};
    Object.keys(passwordValues).forEach((key: any) => {
      if(key !== "confirmPassword") {
        payload[key] = SHA1(passwordValues[key as keyof typeof passwordValues]);
      }
    });
    if(Object.keys(payload).length > 0) {
      if(validatePassword(passwordValues.newPassword)) {
        if(passwordValues.newPassword === passwordValues.confirmPassword) {
          UserService && UserService.changePassword(payload).then((result: any) => {
            if(result.status === 201) { 
              setPasswordValues(defaultPasswordValues);
              actualPasswordInput.current.querySelector("input").value = '';
              newPasswordInput.current.querySelector("input").value = '';
              confirmPasswordInput.current.querySelector("input").value = '';
              createNotification(t('password_changed'), 'success');
            } else {
              createNotification(t('something_went_wrong'), 'error');
            }
          }).catch((e: any) => {
            if(e.response.status === 403) {
              createNotification(t('password_incorrect'), 'error');
            } else {
              createNotification(t('something_went_wrong'), 'error');
            }
          });
        } else {
          createNotification(t('password_not_match'), 'error');
        }
      } else {
        if(passwordWeakness[0] === "length") {
          createNotification(t('password_required_length'), 'error');
        } else if(passwordWeakness[0] === "lowercase") {
          createNotification(t('password_required_lowercase'), 'error');
        } else if(passwordWeakness[0] === "uppercase") {
          createNotification(t('password_required_uppercase'), 'error');
        } else if(passwordWeakness[0] === "number") {
          createNotification(t('password_required_number'), 'error');
        }
      }
    }
  };

  const handleGenerateQRCode = () => {
    const settings = {
      isOpen: true,
      type: 'qrcode',
    };
    dispatch(setSSOGetModal(settings));
  };

  const handleGenerateLink = () => {
    const settings = {
      isOpen: true,
      type: 'link',
    };
    dispatch(setSSOGetModal(settings));
  };
  
  return (
    <div className={classes.securityWrapper}>
      <div className={classes.tabsWrapper}>
        <TabsMenu items={tabsItems} selected={viewMode} onSelect={setViewMode}/>
      </div>
      <div className={classes.boxWrapper}>
        {
          viewMode === "email_change" ? (
            <div className={classes.box}>
              {
                getUserRole(userData.userObject.roleType) === "parent" ? (
                  <>
                    <Input type="email" label={t('email_actual')} value={emailValues.actualEmail} name="actaulEmail" onChange={handleEmailInput} tabIndex="1" readOnly={true} customRef={actualEmailInput}/>
                    <Input type="email" label={t('email_new') + '*'} value={userData.userObject.emailChangeRequest.open ? userData.userObject.emailChangeRequest.email : ''} name="newEmail" onChange={handleEmailInput} tabIndex="3" customRef={newEmailInput} disabled={userData.userObject.emailChangeRequest.open}/>
                    {
                      userData.userObject.emailChangeRequest.open ? (
                        <>
                          {
                            state.isEmailChanged ? (
                              <Banner className={classes.banner} type="success" icon={true}>{t('email_request_sent_info')}</Banner>
                            ) : (
                              <>
                                <Banner className={classes.banner} type="info" icon={true}>{t('email_request_info')}</Banner>
                                <NormalButton buttonType="clear" onClick={cancelEmailChange} tabIndex="7">{t('email_request_delete')}</NormalButton>
                              </>
                            )
                          }
                        </>
                      ) : (
                        <NormalButton buttonType="primary" disabled={!isFilledEmail} onClick={sendEmailChange} tabIndex="7">{t('email_request_send')}</NormalButton>
                      )
                    }
                  </>
                ) : (
                  <div className={classes.inputWrapper}>
                      <label>{t('email_actual')}</label>
                      <span>{emailValues.actualEmail}</span>
                  </div>
                )
              }
            </div>
          ) : viewMode === "password_change" ? (
            <div className={classes.box}>
              <Input type="password" label={t('password_actual') + '*'} name="actualPassword" onChange={handlePasswordInput} tabIndex="8" customRef={actualPasswordInput}/>
              <Tooltip classes={{tooltip: classes.tooltip, arrow: classes.tooltipArrow}} title={newPasswordPopupContent()} open={newPasswordPopupShow} placement="bottom" arrow>
                <div className={classes.inputWrapper}>
                <Input type="password" label={t('password_new') + '*'} name="newPassword" onChange={handlePasswordInput} onFocus={handleFocusNewPasswordInput} onBlur={handleBlurNewPasswordInput} tabIndex="10" customRef={newPasswordInput}/>
                </div>
              </Tooltip>
              <Tooltip classes={{tooltip: classes.tooltip, arrow: classes.tooltipArrow}} title={confirmPasswordPopupContent} open={confirmPasswordPopupShow} placement="bottom" arrow>
                <div className={classes.inputWrapper}>
                  <Input type="password" label={t('password_confirm') + '*'} name="confirmPassword" onChange={handlePasswordInput} onFocus={handleFocusConfirmPasswordInput} onBlur={handleBlurConfirmPasswordInput} tabIndex="12" customRef={confirmPasswordInput}/>
                </div>
              </Tooltip>
              <NormalButton buttonType="primary" disabled={!isFilledPassword} onClick={savePasswordChanges} tabIndex="13">{t('change_password')}</NormalButton>
            </div>
          ) : viewMode === "sso_one_time_login" ? (
            <div className={classes.box}>
              <div className={classes.inputWrapper}>
                <label>{t('sso_one_time_login')}</label>
                <p className={classes.info}>
                  {t('sso_one_time_login_info')}
                </p>
                <div className={classes.buttons}>
                  <NormalButton buttonType="primary" onClick={handleGenerateQRCode} tabIndex="14">{t('sso_generate_qr_code')}</NormalButton>
                  <NormalButton buttonType="secondary" onClick={handleGenerateLink} tabIndex="15">{t('sso_generate_link')}</NormalButton>
                </div>
              </div>
            </div>
          ) : (
            <>...</>
          )
        }
      </div>
    </div>
  );
};

export default SecuritySettings;
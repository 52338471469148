import ContactCard from 'src/components/Cards/ContactCard';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getFiltersKeys, getFiltersKeysArray, getQueryString } from 'src/utils/useFunctions';
import { getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { Navigate, useLocation } from 'react-router';
import { resetFilterParams, setFilterParams, setFiltersSettings, setIsFilterParamsLoaded, setIsFilterSettingsLoaded } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  contactsPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    padding: '24px 24px',
    overflow: 'auto',
    maxHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  schoolList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: 'fit-content',
    width: '100%',
    maxWidth: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
      height: '16px',
      width: '100%',
    },
  },
  school: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    '& > span': {
      fontSize: '18px',
      fontWeight: '600',
      marginBottom: '16px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '12px',
      },
    }
  },
  contactList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    height: 'fit-content',
    width: '100%',
    maxWidth: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
      height: '16px',
      width: '100%',
    },
  },
  contactCardWrapper: {
    width: 'calc(16.6666666667% - 16px)',
    [theme.breakpoints.down('xxxl')]: {
      width: 'calc(20% - 16px)',
    },
    [theme.breakpoints.down('xxl')]: {
      width: 'calc(25% - 16px)',
    },
    [theme.breakpoints.down('xl')]: {
      width: 'calc(33% - 8px)',
    },
    [theme.breakpoints.down('lg')]: {
      width: 'calc(33% - 12px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(50% - 8px)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: "0px",
      '& > div': {
        borderRadius: "0px",
      },
    },
    maxWidth: '100vw',
    borderRadius: '24px',
    height: '140px',
    display: 'flex',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 240px',
    height: '100%',
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    width: '100%',
    paddingBottom: '100px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  notFound: {
    height: '100%',
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const userData = useAppSelector((state: any) => state.user); 
  const employees = dataData.employees;
  const location = useLocation();

  const queryString = getQueryString(location);

  const [state, setState] = useStates({
    isFirstTime: true,
    isLoading: true,
  });

  const filtersParams = getFiltersKeys(filtersData.filterParams, {});

  const schoolIDFilter = (filtersParams.schoolID && filtersParams.schoolID.length !== 0) ? filtersParams.schoolID.map((item: any) => { return item; }) : dataData.schoolsID.map((item: any) => { return item; });

  const employeeList = dataData.schools.map((item: any) => {
    return {schoolID: item.schoolID, schoolName: item.name, contacts: employees.filter((employee: any) => employee.schoolID === item.schoolID)}
  }).filter((item: any) => item.contacts.length !== 0).filter((item: any) => schoolIDFilter.includes(item.schoolID));

  const saveFilters = useCallback(async (filterParam: any) => {
    if(getUserSetting(userData.userSettings, "addons", ["contacts", "contacts_filters_save"])) {
      const filtersParams = getFiltersKeysArray(filterParam, {});
      await saveUserSettings(dispatch, userData, "filters", ["contacts"], filtersParams);
    }
  }, [dispatch, userData]);

  useEffect(() => {
    if(filtersData.isFilterSetupLoaded && !filtersData.isFilterSettingsLoaded && !filtersData.isFilterParamsLoaded) {
      const settings = {
        isAllowedPostID: false,
        isAllowedPaymentID: false,
        isAllowedPostType: false,
        isAllowedGalleryType: false,
        isAllowedActivityType: false,
        isAllowedChildID: false,
        isAllowedEmployeeID: false,
        isAllowedDate: false,
        isAllowedAuthors: false,
        isAllowedPolls: false,
        isAllowedClasses: false,
        isAllowedSchools: true,
        isAllowedTimelineFavorite: false,
        isAllowedGalleryFavorite: false,
        isAllowedGalleryHide: false,
        isAllowedPaymentMethod: false,
        isAllowedPaymentStatus: false,
        isAllowedPaymentType: false,
        isAllowedCurrencyID: false,
        isAllowedArchived: false,
      };
      dispatch(setFiltersSettings(settings));
      setTimeout(() => {
        dispatch(setIsFilterSettingsLoaded(true));
      }, 100);
    }
  }, [dispatch, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    if(!filtersData.isFilterParamsLoaded && filtersData.isFilterSettingsLoaded) {
      if(getUserSetting(userData.userSettings, "addons", ["contacts", "contacts_filters_save"])) {
        const customFilters = getUserSetting(userData.userSettings, "filters", ["contacts"]);
        if(Array.isArray(customFilters) && customFilters.length > 0) {
          customFilters.forEach((item: any) => {
            dispatch(setFilterParams({[item.key]: item.value}));
          });
          if(getUserSetting(userData.userSettings, "addons", ["app", "app_filter_autoopening"])) {
            dispatch(setIsFiltersVisible(true));
          }
        } else {
          dispatch(setFilterParams({}));
        }
      } else {
        dispatch(setFilterParams({}));
      }
      dispatch(setIsFilterParamsLoaded(true));
      setState("isLoading", false);
    }
  }, [dispatch, setState, dataData.schoolsID, userData.userSettings, filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded,], [filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    dispatch(setIsFilterParamsLoaded(false));
    dispatch(setIsFilterSettingsLoaded(false));
    return () => {
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(resetFilterParams());
    }
  }, [dispatch], []);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && !state.isFirstTime) {
      saveFilters(filtersData.filterParams);
      setState("isLoading", true);
      setTimeout(() => {
        setState("isLoading", false);
      }, 500);
    } else {
      setState("isFirstTime", false);
    }
  }, [saveFilters, setState, state.isFirstTime, filtersData.filterParams, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams]);

  return employees.length === 0 ? (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  ) : (
    <div className={classes.contactsPage}>
      {
        state.isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress/>
          </div>
        ) : (
          <>
            {
              employeeList.length === 0 ? (
                <NotFound className={classes.notFound} title={t('no_contacts')} text={t('no_contacts_found')}/>
              ) : (
                <div className={classes.schoolList}>
                  {
                    employeeList.map((school: any, skey: any) => (
                      <div className={classes.school} key={`k_${skey}`}>
                        {
                          employeeList.length > 1 ? (
                            <span>{school.schoolName}</span>
                          ) : null
                        }
                        <div className={classes.contactList}>
                        {
                          school.contacts.map((data: any, key: any) => (
                            <div className={classes.contactCardWrapper} key={`k_${key}`}>
                              <ContactCard employeeID={data.employeeID}/>
                            </div>
                          ))      
                        }
                        </div>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
};

export default PageTemplate;
import config from '../../constants/config';
import DocumentTitle from '../../utils/documentTitle';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { trimCharacter } from 'src/utils/useFunctions';
import { useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation } from 'react-router-dom';
import { useState } from '../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  children: any;
};

const TitleController: React.FunctionComponent<Props> = (props:Props) => {

  const { t } = useTranslation();
  const communicationData = useAppSelector((state: any) => state.communication);
  const loadingData = useAppSelector((state: any) => state.loading);
  const userData = useAppSelector((state: any) => state.user);
  const windowHandler: any = window;
  const location = windowHandler.location;
  const appLocation = useLocation();
  const loadingTitle = 'title_loading';
  const isBranch = config.ENVIRONMENT === "review";
  const branch = config.BRANCH ? config.BRANCH.toUpperCase() : "";
  const defaultTitle = `WebApp ${config.APP_VERSION}`;
  const isUnreadThreads = communicationData.notificationsCount > 0;
  const unreadThreads = communicationData.notificationsCount;

  const pathName = trimCharacter(location.pathname,"/");
  const hashName = trimCharacter(location.hash, "#");
  
  const isInstantAccessPage = config.APP_INSTANT_ACCESS_PAGES.some((page: any) => pathName === page || pathName.startsWith(`${page}/`)) || config.APP_INSTANT_ACCESS_HASHES.some((hash: any) => hashName === hash);
  const [title, setTitle] = useState(defaultTitle);
  
  const handleTitle = useCallback((location: any) => {
    const url = location.pathname.substring(1);
    let variable = "";
    if(url.length === 0) {
      variable = 'title_loading';
    } else {
      let tempVariable = 'title_' + url.replaceAll('/', '_').replaceAll('-', '_');
      if(i18next.exists(tempVariable)) {
        variable = tempVariable;
      } else {
        while (i18next.exists(tempVariable) === false && tempVariable !== "title") {
          let tempVariableArray = tempVariable.split('_');
          tempVariableArray.pop();
          tempVariable = tempVariableArray.join('_');
          if (tempVariable === 'title') {
            variable = 'title_nothing_was_found';
          } else {
            variable = tempVariable;
          }
        }   
      }
    }
    setTitle(variable);
  }, []);
  
  useEffect(() => {
    if((userData.userStatus !== "loggedIn" && loadingData.isLanguageLoaded) || (userData.userStatus === "loggedIn" && loadingData.isLanguageLoaded && loadingData.isUserLoaded) || (userData.userStatus === "loggedIn" && loadingData.isLanguageLoaded && !loadingData.isUserLoaded && isInstantAccessPage)) {
      handleTitle(appLocation);
    } else {
      setTitle(loadingTitle);
    }
  }, [location, appLocation, loadingData.isLanguageLoaded, loadingData.isUserLoaded, userData.userStatus, handleTitle, loadingTitle, isInstantAccessPage], [appLocation, loadingData.isLanguageLoaded, loadingData.isUserLoaded, userData.userStatus]); 
  
  return (
    <DocumentTitle title={`${(isUnreadThreads && !isInstantAccessPage) ? `(${unreadThreads}) ` : ''}${isBranch ? `[${branch}] ` : ''}${config.APP_NAME} ${config.APP_DELIMITER} ${t(title)}`}>
      {props.children}
    </DocumentTitle>
  );
};

TitleController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default TitleController;
import CloseButton from 'src/components/Buttons/CloseButton';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import Modal from '../../../utils/modal';
import NormalButton from '../../Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import widgets from 'src/constants/widgets';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { addDashboardWidget, setDashboardWidgets } from 'src/store/actions/dashboard.actions';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { mergeArraysFirst } from 'src/utils/useFunctions';
import { setDashboardHandleWidgetModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    padding: "20px",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.colors.black,
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  detail: {
    display: 'block',
  },
  description: {
    fontSize: '14px',
  },
  link: {
    color: theme.colors.black,
    textDecoration: 'underline',
    fontSize: '14px',
    cursor: 'pointer',
    marginLeft: '4px',
  },
  accordions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  accordion: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    margin: '0 !important',
    borderRadius: '12px !important', 
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    backgroundColor: theme.colors.grey[300],
    padding: '8px 8px',
    alignItems: 'center',
    gap: '8px',
    minHeight: 'unset !important',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      margin: '6px 0',
      alignItems: 'center',
      ' & > span': {
        fontSize: '14px',
      },
    },
    '& > .MuiAccordionSummary-expandIconWrapper': {
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 4px',
    },
  },
  accordionDetails: {
    padding: '16px',
    backgroundColor: theme.colors.grey[200],
    '& > p': {
      fontSize: '13px',
    }
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    '& > span': {
      fontSize: '16px',
      fontWeight: '500',
    },
    '& > p': {
      fontSize: '13px',
      marginBottom: '0',
    },
  },
  buttons: {
    display: 'flex',
    gap: '4px',
    marginLeft: 'auto',
  },
  smallButton: {
    cursor: 'pointer',
    width: '36px',
    height: '36px',
    '& > svg': {
      color: theme.colors.primaryBlue[250],
      width: '18px',
      height: '18px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '16px',
    gap: '4px',
  },
}));

const DashboardHandleWidgetModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const dashboardData = useAppSelector((state: any) => state.dashboard);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);

  const userWidgets = dashboardData.widgets.map((item: any) => {
    const widgetData: any = widgets.filter((subItem: any) => subItem.key === item.type).length === 1 ? widgets.find((subItem: any) => subItem.key === item.type) : {};
    if(widgetData.settings) {
      return {...item, settings: mergeArraysFirst(item.settings ? item.settings : [], widgetData.settings)};
    } else {
      return item;
    }
  });

  const widgetKey = modalsData.dashboardHandleWidgetModal.widgetKey;
  const availableWidgets = widgets.filter((item: any) => item.roles.includes(getUserRole(userData.userObject.roleType)));

  const [state, setState] = useStates({
    widgets: availableWidgets,
    userWidgets: userWidgets,
  });

  const getUserWidgetData = (key: any) => {
    const userWidgetExist = userWidgets.filter((item: any) => item.key === key).length === 1;
    if(userWidgetExist) {
      const userWidgetData = userWidgets.find((item: any) => item.key === key);
      const widgetExist = widgets.filter((item: any) => item.key === userWidgetData.type).length === 1;
      if(widgetExist) {
        const widgetData = widgets.find((item: any) => item.key === userWidgetData.type);
        const mergedData = {...widgetData, ...userWidgetData};
        return mergedData;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      widgetKey: null,
    };
    dispatch(setDashboardHandleWidgetModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleAddWidget = (e: any, widget: any) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(addDashboardWidget(widget));
    onCloseModal();
  };
  
  const updateWidgetSettings = (key: any, name: any, value: any, e: any) => {
    setTimeout(() => {
      const newWidgets = state.widgets.map((item: any) => {
        if(item.key === key) {
          const settings = item.settings.map((subItem: any) => {
            if(subItem.key === name) {
              if(subItem.type[0] === "input") {
                if(subItem.type[1] === "number") {
                  if(parseInt(value) < subItem.type[2] || parseInt(value) > subItem.type[3]) {
                    e.value = subItem.value;
                    return subItem;
                  } else {
                    return {...subItem, value: parseInt(value)};
                  }
                } else {
                  return {...subItem, value: value};
                }
              } else {
                return {...subItem, value: value};
              }
            } else {
              return subItem;
            }
          });
          return {...item, settings: settings};
        } else {
          return item;
        }
      });
      setState("widgets", newWidgets);
    }, 100);
  };

  const updateExistingWidgetSettings = (key: any, name: any, value: any, e: any) => {
    setTimeout(() => {
      const newWidgets = state.userWidgets.map((item: any) => {
        if(item.key === key) {
          const settings = item.settings.map((subItem: any) => {
            if(subItem.key === name) {
              if(subItem.type[0] === "input") {
                if(subItem.type[1] === "number") {
                  if(parseInt(value) < subItem.type[2] || parseInt(value) > subItem.type[3]) {
                    e.value = subItem.value;
                    return subItem;
                  } else {
                    return {...subItem, value: parseInt(value)};
                  }
                } else {
                  return {...subItem, value: value};
                }
              } else {
                return {...subItem, value: value};
              }
            } else {
              return subItem;
            }
          });
          return {...item, settings: settings};
        } else {
          return item;
        }
      });
      setState("userWidgets", newWidgets);
    }, 100);
  };

  const handleSave = () => {
    dispatch(setDashboardWidgets(state.userWidgets));
    onCloseModal();
  };
  
  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "conceptModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p data-cy={isCypress() ? 'conceptModalTitle' : null}>{widgetKey ? t('edit_widget') : t('add_widget')}</p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/> 
        </div>
        <div className={classes.body}>
          {
            widgetKey ? (
              <>
              {
                (getUserWidgetData(widgetKey).settings && getUserWidgetData(widgetKey).settings.length > 0) ? (
                  <>
                    {
                      getUserWidgetData(widgetKey).settings.map((subItem: any, skey: any) => {
                        if(subItem.type[0] === "input") {
                          return (
                            <Input key={`k_${skey}`} label={t(subItem.name)} name={subItem.key} value={subItem.value} type={subItem.type[1]} min={subItem.type[2]} max={subItem.type[3]} onChange={(_: any, value: any, e: any) => updateExistingWidgetSettings(widgetKey, subItem.key, value, e)}/>
                          );
                        } else {
                          return null;
                        }
                      })
                    }
                  </>
                ) : null
              }
              </>
            ) : (
              <>
                {
                  state.widgets.length > 0 ? (
                    <div className={classes.accordions}>
                      {
                        state.widgets.map((item: any, key: any) => (
                          <Accordion className={classes.accordion} data-cy={isCypress() ? 'widgetAccordion' + item.key : null} key={`k_${key}`} expanded={(item.settings && item.settings.length > 0) ? undefined : false}>
                            <AccordionSummary className={classes.accordionSummary} expandIcon={(item.settings && item.settings.length > 0) ? (<SVG src="chevron-down"/>) : null}>
                              <span>{t(item.title)}</span>
                              <div className={classes.buttons}>
                                <IconButton className={classes.smallButton} tooltip={t("add_widget")} tooltipPosition='bottom' tooltipMaxWidth={400} onClick={(e: any) => handleAddWidget(e, item)} dataCy={'widgetAccordionLoad' + item.key}>
                                  <SVG src="plus"/>
                                </IconButton>
                              </div>
                            </AccordionSummary>
                            {
                              (item.settings && item.settings.length > 0) ? (
                                <AccordionDetails className={classes.accordionDetails}>
                                  {
                                    item.settings.map((subItem: any, skey: any) => {
                                      if(subItem.type[0] === "input") {
                                        return (
                                          <Input key={`k_${skey}`} label={t(subItem.name)} name={subItem.key} value={subItem.value} type={subItem.type[1]} min={subItem.type[2]} max={subItem.type[3]} onChange={(_: any, value: any, e: any) => updateWidgetSettings(item.key, subItem.key, value, e)}/>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })
                                  }
                                </AccordionDetails>
                              ) : null
                            }
                          </Accordion>
                        ))
                      }
                    </div>
                  ) : (
                    <NotFound text={t('no_widgets_found')}/>
                  )
                }
              </>
            )
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="widgetModalClose">
            {t("cancel")}
          </NormalButton>
          {
            widgetKey ? (
              <NormalButton buttonType="primary" onClick={handleSave} dataCy="widgetModalSave" disabled={JSON.stringify(userWidgets) === JSON.stringify(state.userWidgets)}>
                {t("save")}
              </NormalButton>
            ) : null
          }
        </div>
      </div>
    </Modal>
  );
};

export default DashboardHandleWidgetModal;
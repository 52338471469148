import * as DirectorPopupsService from '../../../../services/director/popups.service';
import * as ParentPopupsService from '../../../../services/parent/popups.service';
import * as TeacherPopupsService from '../../../../services/teacher/popups.service';
import config from 'src/constants/config';
import { getUserRole } from 'src/utils/useUser';
import { setPopup } from 'src/store/actions/popups.actions';

const getService = (userData: any) => {
  if(getUserRole(userData.userObject.roleType) === "parent") return ParentPopupsService;
  else if(getUserRole(userData.userObject.roleType) === "teacher") return TeacherPopupsService;
  else if(getUserRole(userData.userObject.roleType) === "director") return DirectorPopupsService;
  else return false;
};

const loadPopups = async (dispatch: any, userData: any) => {
  if(getService(userData)) {
    const service: any = getService(userData);
    try {
      const result: any = await service.listPopups({viewed: false});
      if(result && result.data) {
        const response = result.data[config.JSONLD_DATA];
        const count = result.data[config.JSONLD_COUNT];
        if(count > 0) {
          const firstPopup = response[0];
          const popupData = {...firstPopup, isMorePopups: count > 1};
          dispatch(setPopup(popupData));
        }
        return true;              
      } else {
        return false;
      }
    } catch {
      return false;
    };
  } 
};

export default loadPopups;
import EmojiIcon from 'src/components/Icons/EmojiIcon';
import React from 'react';
import { Avatar, AvatarGroup } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { handleSum } from 'src/utils/useFunctions';
import { setReactionDetailModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useMemo } from 'src/utils/useMemo';

const useStyles = createUseStyles((theme: any) => ({
  reactions: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    cursor: 'pointer',
    '&:hover': {
      '& > span': {
        textDecoration: 'underline',
      },
    },
  },
  reactionsGroup: {
    '&:empty': {
      display: 'none',
    }
  },
  reaction: {
    width: '24px',
    height: '24px',
    fontSize: '90%',
    backgroundColor: theme.colors.grey[200],
  },
  totalReactions: {
    fontSize: '12px',
  },
}));

type ReactionsListType = {
  data: any;
  ID: any;
  type: 'post' | 'media' | 'comment' | 'communication';
  className?: any;
};

const ReactionsList: React.FunctionComponent<ReactionsListType> = ({ data, ID, type, className }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const reactions = useMemo(() => configurationData.configuration.reactions, [configurationData.configuration.reactions]);

  const handleReactionModal = () => {
    const settings = {
      isOpen: true,
      ID: ID,
      type: type,
    };
    dispatch(setReactionDetailModal(settings));   
  };
  
  return (
    <div className={`${classes.reactions} ${className ? className : ''}`} onClick={handleReactionModal}>
      <AvatarGroup className={classes.reactionsGroup} total={data.reactions.totals.slice(0, 3).length}>
        {
          data.reactions.totals.slice(0, 3).map((item: any, key: any) => reactions.filter((reaction: any) => reaction.emojiID === item.emojiID).length === 1 ? (
            <Avatar className={classes.reaction} key={`k_${key}`}>
              <EmojiIcon emoji={reactions.find((reaction: any) => reaction.emojiID === item.emojiID).emoji}/>
            </Avatar>
          ) : null)
        }
      </AvatarGroup>
      <span data-clarity-unmask="true" className={classes.totalReactions}>
        {data.reactions.totals.map((item: any) => { return item.total; }).reduce(handleSum, 0)}
      </span>
    </div>
  );
}

export default ReactionsList;
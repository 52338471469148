import EmployeesListSelect from 'src/components/Selects/EmployeeListSelect';
import React, { useCallback, useRef } from 'react';
import TimetableTable from 'src/components/Tables/TimetableTable';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { Navigate, useLocation } from 'react-router';
import { setTimetableData, setTimetableEmployee, setTimetableRooms, setTimetableSchool, setTimetableSettings, setTimetableSubjects, setTimetableType } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: '32px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
}));

const TimetableViewTeacher: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const timetableData = useAppSelector((state: any) => state.timetable);
  const userData = useAppSelector((state: any) => state.user);
  const timetableWrapper: any = useRef(null);
  const timetableWrapperCurrent = timetableWrapper.current;
  const timetableWrapperClientWidth = timetableWrapperCurrent ? (timetableWrapperCurrent.clientWidth ? timetableWrapperCurrent.clientWidth : 0) : 0;
  const timetableService = useAppSelector((state: any) => state.services).timetableService;

  const queryString = getQueryString(location);

  const userAccessSchools = userData.userAccessSchools;

  const currentEmployeesData = dataData.employees.filter((employee: any) => employee.enableForTimetable && userData.userObject.employeeID.includes(employee.employeeID) && userAccessSchools.timetable.includes(employee.schoolID) && userAccessSchools.subscription.includes(employee.schoolID)).map((employeeData: any) => { return { employeeID: employeeData.employeeID, schoolID: employeeData.schoolID }; });
  const employeesData = dataData.employees.filter((employee: any) => employee.enableForTimetable && userAccessSchools.timetable.includes(employee.schoolID) && userAccessSchools.subscription.includes(employee.schoolID)).map((employeeData: any) => { return { employeeID: employeeData.employeeID, schoolID: employeeData.schoolID }; });

  const [state, setState] = useStates({
    isLoading: true,
    employeesListSelectWidth: timetableWrapperCurrent ? timetableWrapperCurrent.clientWidth : 0,
    currentEmployee: currentEmployeesData.length > 0 ? currentEmployeesData[0] : employeesData[0],
  });

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getEmployeeData = useCallback((employeeID: any) => {
    return dataData.employees.filter((item: any) => item.employeeID === employeeID).length === 0 ? [] : dataData.employees.find((item: any) => item.employeeID === employeeID);
  }, [dataData.employees]);

  const getHours = useCallback((employeeID: any) => {
    const params = {
      teacherID: employeeID,
      schoolID: getEmployeeData(employeeID).schoolID,
      type: [1, 2, 3],
    };
    timetableService && timetableService.listHours(params).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableData(result.data));
          setState("isLoading", false);
        } else {
          createNotification(t("timetable_failed_load"), "error");
        }
      } else {
        createNotification(t("timetable_failed_load"), "error");
      }
    }).catch(() => {
      createNotification(t("timetable_failed_load"), "error");
    });
  }, [dispatch, t, timetableService, setState, getEmployeeData]);

  const getRooms = useCallback((employeeID: any) => {
    timetableService && timetableService.listRooms().then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableRooms(result.data));
          getHours(employeeID);
        } else {
          createNotification(t("timetable_rooms_not_loaded"), "error");
        }
      } else {
        createNotification(t("timetable_rooms_not_loaded"), "error");
      }
    }).catch(() => {
      createNotification(t("timetable_rooms_not_loaded"), "error");
    });
  }, [t, timetableService, dispatch, getHours]);

  const getSubjects = useCallback((employeeID: any) => {
    timetableService && timetableService.listSubjects().then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setTimetableSubjects(result.data));
          getRooms(employeeID);
        } else {
          createNotification(t("timetable_subjects_not_loaded"), "error");
        }
      } else {
        createNotification(t("timetable_subjects_not_loaded"), "error");
      }
    }).catch(() => {
      createNotification(t("timetable_subjects_not_loaded"), "error");
    });
  }, [t, timetableService, getRooms, dispatch]);

  useEffect(() => {
    const settings = {
      breaks: true,
      canEdit: false,
      canMultipleHours: false,
      subjectPopup: true,
    };
    dispatch(setTimetableSettings(settings));
    dispatch(setTimetableSchool(getSchoolData(getEmployeeData(state.currentEmployee.employeeID).schoolID)));
    getSubjects(state.currentEmployee.employeeID);
  }, [timetableData, dispatch, state, getSubjects, getEmployeeData, getSchoolData], []);

  const handleCurrentEmployee = (employee: any) => {
    setState("isLoading", true);
    setState("currentEmployee", employee);
    dispatch(setTimetableEmployee(employee));
    dispatch(setTimetableType("employee"));
    dispatch(setTimetableSchool(getSchoolData(getEmployeeData(employee.employeeID).schoolID)));
    getSubjects(employee.employeeID);
  };

  useEffect(() => {
    setState("employeesListSelectWidth", timetableWrapperClientWidth);  
  }, [browserData.width, timetableWrapperClientWidth, setState], [browserData.width, timetableWrapperClientWidth]);
  
  return employeesData.length > 0 ? (
    <div className={classes.wrapper} ref={timetableWrapper}>
      <EmployeesListSelect currentEmployee={state.currentEmployee} employeesData={employeesData} setCurrentEmployee={handleCurrentEmployee} width={state.employeesListSelectWidth} isDisabled={state.isLoading}/>
      {
        state.isLoading ? (
          <div className={classes.spinner}>
            <CircularProgress/>
          </div>
        ) : (
          <TimetableTable/>
        )
      }
    </div> 
  ) : (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  );
};

export default TimetableViewTeacher;
import ColorInfoItem from 'src/components/Items/ColorInfoItem';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import IconButton from 'src/components/Buttons/IconButton';
import { isKey } from 'src/utils/useFunctions';
import { setConfirmModal, setTimetableSubjectDetailModal } from 'src/store/actions/modals.actions';
import { setTimetableSubjects } from 'src/store/actions/timetable.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  tableWrapper: {
    maxWidth: '100%',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  tableContainer: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: "#ECECEC",
    borderRadius: '20px 20px 0 0',
    '& > tr': {
      '& > th': {
        padding: '12px 10px',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        '&:first-of-type:not(:last-of-type)': {
          borderRadius: '20px 0px 0px 0px',
        },
        '&:last-of-type:not(:first-of-type)': {
          borderRadius: '0px 20px 0px 0px',
        },
        '&:first-of-type:last-of-type': {
          borderRadius: '20px 20px 0px 0px',
        },
      },
    },
  },
  tableBody: {
    borderRadius: '0 0 20px 20px',  
  },
  tableRow: {
    fontSize: '14px',
    backgroundColor: theme.colors.white,
    whiteSpace: 'nowrap',
    textAlign: 'center',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:last-of-type': {
      borderRadius: '0 0 20px 20px',
      '& > td': {
        '&:first-of-type': {
          borderRadius: '0 0 0 20px',
        },
        '&:last-of-type': {
          borderRadius: '0 0 20px 0',
        },
        '&:last-of-type:first-of-type': {
          borderRadius: '0 0 20px 20px',
        }, 
      },
    },
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > td': {
      '&:only-child': {
        '& > span': {
          justifyContent: 'center',
        },
      },
      '& > span': {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        padding: '12px 10px',
      },
    },
  },
  color: {
    width: '20px',
  },
  name: {
    fontWeight: '500',
    maxWidth: '300px',
    width: '300px',
    '& > span': {
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },  
  type: {
    paddingLeft: '8px',
  },
  action: {
    textAlign: 'right',
    paddingRight: '16px',
    width: 'calc(72px - 16px)',
  },
  button: {
    cursor: 'pointer',
    marginRight: '10px',
    '& > svg': {
      color: theme.colors.primaryBlue[250],
      width: '24px',
      height: '24px',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
}));

type TimetableSubjectsTableType = {
  schoolID: any;
};

const TimetableSubjectsTable: React.FunctionComponent<TimetableSubjectsTableType> = ({schoolID = null}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const timetableData = useAppSelector((state: any) => state.timetable);
  const timetableService = useAppSelector((state: any) => state.services).timetableService;
  const classes = useStyles();

  const handleClickEdit = (subjectID: any) => {
    const settings = {
      isOpen: true,
      subjectID: subjectID,
      schoolID: schoolID,
    };
    dispatch(setTimetableSubjectDetailModal(settings)); 
  };

  const handleClickDelete = (subjectID: any) => {
    const onAccept = () => {
      timetableService && timetableService.deleteSubject(subjectID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            createNotification(t("timetable_subject_deleted"), "success");
            const newSubjects = timetableData.subjects.map((subject: any) => {
              if(subject.subjectID === subjectID) {
                return null;
              } else {
                return subject;
              }
            }).filter((item: any) => item !== null);
            dispatch(setTimetableSubjects(newSubjects));
          } else {
            createNotification(t("timetable_subject_not_deleted"), "error");
          }
        } else {
          createNotification(t("timetable_subject_not_deleted"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("timetable_subject_not_deleted"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title: t('timetable_subject_delete'),
      content: t('timetable_subject_delete_confirm'),
      onAccept: onAccept,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const getType = (type: any) => {
    if(type === 1) {
      return t('timetable_subject_education');
    } else if(type === 2) {
      return t('timetable_subject_teacher');
    } else {
      return '';
    }
  };

  return (
    <div className={classes.tableWrapper}>
      <table className={classes.tableContainer}>
        <thead className={classes.tableHead}>
          <tr>
            <th/>
            <th>
              {t('timetable_subject_name')}
            </th>
            <th>
              {t('timetable_subject_type')}
            </th>
            <th>
              {t('action')}
            </th>
          </tr>
        </thead>
        <tbody className={classes.tableBody}>
          {
            timetableData.subjects.length > 0 ? timetableData.subjects.map((subject: any, key: any) => (
              <tr className={classes.tableRow} key={`k_${key}`}>
                <td className={classes.color}>
                  <ColorInfoItem color={subject.color}/>
                </td>
                <td className={classes.name}>
                  <span>{subject.name} ({subject.shortName})</span>
                </td>
                <td className={classes.type}>
                  <span>{getType(subject.type)}</span>
                </td>
                <td className={classes.action}>
                  <IconButton className={classes.button} onClick={() => handleClickEdit(subject.subjectID)} tooltip={t('timetable_subject_edit')} tooltipPosition='bottom' tooltipMaxWidth={200}>
                    <SVG src="eye"/>
                  </IconButton>
                  <IconButton className={classes.button} onClick={() => handleClickDelete(subject.subjectID)} tooltip={t('timetable_subject_delete')} tooltipPosition='bottom' tooltipMaxWidth={200}>
                    <SVG src="trash-outlined"/>
                  </IconButton>
                </td>
              </tr>
            )) : (
              <tr className={classes.tableRow}>
                <td colSpan={4}>
                  <span>{t('no_timetable_subjects_found')}</span>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

export default TimetableSubjectsTable;
import ChildrenListSelect from 'src/components/Selects/ChildrenListSelect';
import MealsTable from 'src/components/Tables/MealsTable';
import moment from 'src/utils/moment';
import MonthPicker from 'src/components/DatePickers/Months';
import React, { useCallback, useRef } from 'react';
import Select from 'src/components/Forms/Select';
import Sidebar from 'src/components/Layouts/Sidebar';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getQueryString } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { Navigate, useLocation } from 'react-router';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  mealsPage: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 48px)',
    width: 'calc(100% - 48px)',
    padding: '24px',
    height: 'calc(100% - 48px)',
    alignItems: 'center',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  meals: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxHeight: 'calc(100% - 16px)',
    overflow: 'auto',
    gap: '16px',
    marginTop: '16px',
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '10px',
    '& > div': {
      width: '200px',
      flex: '0 0 200px',
    },
  },
  filtersColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '800px',
    gap: '16px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const userData = useAppSelector((state: any) => state.user);
  const location = useLocation();
  const mealsService = useAppSelector((state: any) => state.services).mealsService;
  const { t } = useTranslation();
  const mealsWrapper: any = useRef(null);
  const mealsWrapperCurrent = mealsWrapper.current;
  const mealsWrapperClientWidth = mealsWrapperCurrent ? (mealsWrapperCurrent.clientWidth ? mealsWrapperCurrent.clientWidth : 0) : 0
  const mealsScroll: any = useRef(null);

  const queryString = getQueryString(location);

  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;

  const dayRef: any = useRef(null);

  const [state, setState] = useStates({
    curChild: null,
    curSchool: null,
    curDate: moment(),
    isLoading: true,
    meals: [],
    childrenListSelectWidth: mealsWrapperClientWidth,
  });

  const listSchools = dataData.schools.filter((item: any) => userAccessSchools.mealMenu.includes(item.schoolID) && userAccessSchools.subscription.includes(item.schoolID));
  
  const listChildren = dataData.children.filter((item: any) => !item.isArchived && item.isInAnyActiveClass && userAccessSchools.mealMenu.includes(item.schoolID) && userAccessSchools.subscription.includes(item.schoolID));
  
  const mealsData = {
    currentChild: state.curChild === null ? listChildren[0] : state.curChild,
  };

  const listMeals = useCallback(() => {
    const dateTo = moment(state.curDate).startOf('month').format("YYYY-MM-DD");
    const dateFrom = moment(state.curDate).endOf('month').format("YYYY-MM-DD");
    const filterID = getUserRole(userData.userObject.roleType) === "parent" ? (state.curChild === null ? listChildren[0].childID : state.curChild.childID) : (state.curSchool === null ? listSchools[0].schoolID : state.curSchool.schoolID);
    setState("isLoading", true);
    mealsService && mealsService.listMeals(dateTo, dateFrom, filterID).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data) {
            setState("meals", result.data);
            setState("isLoading", false);
          } else {
            createNotification(t("meals_failed_load"), "error");
            setState("isLoading", false);
            setState("meals", []);
          }
        } else {
          createNotification(t("meals_failed_load"), "error");
          setState("isLoading", false);
          setState("meals", []);
        }
      } else {
        createNotification(t("meals_failed_load"), "error");
        setState("isLoading", false);
        setState("meals", []);
      }
    }).catch(() => {
      createNotification(t("meals_failed_load"), "error");
      setState("isLoading", false);
      setState("meals", []);
    });
  }, [state.curDate, mealsService, setState, t, listChildren, state.curChild, listSchools, state.curSchool, userData.userObject.roleType]);

  const handleSetDate = (newDate: any) => {
    setState("curDate", newDate);
  };

  const handleChangeSchool = (value: any) => {
    const newSchool = value;
    setState("curSchool", newSchool);
  };

  const setCurrentChild = (child: any) => {
    setState("curChild", child);
  };

  useEffect(() => {
    if(userAccess.mealMenu && (getUserRole(userData.userObject.roleType) === "parent" ? listChildren.length > 0 : listSchools.length > 0)) {
      listMeals();
    }    
  }, [state.curDate, state.curSchool, state.curChild, listMeals, listChildren.length, listSchools.length, userData.userObject.roleType, userAccess.mealMenu], [state.curDate, state.curSchool, state.curChild]);

  useEffect(() => {
    setState("childrenListSelectWidth", mealsWrapperClientWidth);  
  }, [browserData.width, mealsWrapperClientWidth, setState], [browserData.width, mealsWrapperClientWidth]);

  useEffect(() => {
    if(dayRef.current && !state.isLoading) {       
      mealsScroll.current.scrollTo({
        top: dayRef.current.offsetTop - mealsWrapper.current.clientHeight - 48,
        behavior: 'smooth'
      });
    }
  }, [state.isLoading, dayRef]);
  
  return userAccess.mealMenu && (getUserRole(userData.userObject.roleType) === "parent" ? listChildren.length > 0 : listSchools.length > 0) ? (
    <div className={classes.mealsPage}>
      <>
      {
        !userAccess.subscription ? (
          <SubscriptionBanner service="meals" isInCenter={true}/>
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.content}>
              {
                getUserRole(userData.userObject.roleType) === "parent" ? (
                  <div className={classes.filtersColumn} ref={mealsWrapper}>
                    {
                      listChildren.length > 1 ? (
                        <ChildrenListSelect currentChild={mealsData.currentChild} childrenData={listChildren} setCurrentChild={setCurrentChild} width={state.childrenListSelectWidth} isDisabled={state.isLoading}/>
                      ) : null
                    }
                    <MonthPicker presetDate={moment(state.curDate)} setDate={handleSetDate} mode="spinner" disabled={state.isLoading}/>
                  </div>
                ) : (
                  <div className={classes.filters} ref={mealsWrapper}>
                    {
                      listSchools.length > 1 ? (
                        <Select inputLabel={t("school")} items={listSchools} selected={state.curSchool === null ? listSchools[0] : state.curSchool} setSelected={handleChangeSchool} width={200} allowClear={false}/>
                      ) : null
                    }
                    <MonthPicker presetDate={moment(state.curDate)} setDate={handleSetDate} mode="spinner" disabled={state.isLoading}/>
                  </div>
                )
              }
              <div className={state.meals.length === 0 ? classes.list : classes.meals} ref={mealsScroll}>
                {
                  state.isLoading ? (
                    <div className={classes.spinner}>
                      <CircularProgress/>
                    </div>
                  ) : (
                    <>
                      {
                        state.meals.length === 0 ? (
                          <div className={classes.notFound}>
                            <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                            <span>{t('no_results')}</span>
                            <p>{t('meals_not_available')}</p>
                          </div>
                        ) : (
                          <>
                          {
                            Object.keys(state.meals).map((day: any, key: any) => (
                              <MealsTable day={day} childID={getUserRole(userData.userObject.roleType) === "parent" ? (state.curChild === null ? listChildren[0].childID : state.curChild.childID) : null} schoolID={getUserRole(userData.userObject.roleType) === "parent" ? (state.curChild === null ? listChildren[0].schoolID : state.curChild.schoolID) : (state.curSchool === null ? listSchools[0].schoolID : state.curSchool.schoolID)} meals={state.meals[day]} key={`k_${key}`} customRef={moment().isSame(moment(day), 'day') ? dayRef : null}/>
                            ))
                          }
                          </>
                        )
                      }
                    </>
                  )
                }
              </div>
            </div>
            <Sidebar/>
          </div> 
        )}
      </>
    </div>
  ) : (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  );
};

export default PageTemplate;
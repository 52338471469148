const t = (key: any) => { return key };

const addons = [
  {
    name: t('classbook'),
    icon: 'classbook',
    key: 'classbook',
    roles: [
      "teacher",
      "director",
    ],
    isEnabled: true,
    items: [
      {
        name: t('classbook_autosave'),
        desc: t('classbook_autosave_info'),
        icon: 'draft-outlined',
        key: 'classbook_autosave',
        value: false,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: true,
      },
      {
        name: t('classbook_context_menu'),
        desc: t('classbook_context_menu_info'),
        icon: 'context-menu',
        key: 'classbook_context_menu',
        value: true,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('classbook_filters_save'),
        desc: t('classbook_filters_save_info'),
        icon: 'filter-outlined',
        key: 'classbook_filters_save',
        value: true,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('classbook_record_autoupdate'),
        desc: t('classbook_record_autoupdate_info'),
        icon: 'reload',
        key: 'classbook_record_autoupdate',
        value: true,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: true,
      },
      {
        name: t('classbook_class_school_years'),
        desc: t('classbook_class_school_years_info'),
        icon: 'calendar',
        key: 'classbook_class_school_years',
        value: true,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('classbook_missing_inputs'),
        desc: t('classbook_missing_inputs_info'),
        icon: 'missing-info',
        key: 'classbook_missing_inputs',
        value: true,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('timeline'),
    icon: 'timeline',
    key: 'timeline',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    isEnabled: true,
    items: [
      {
        name: t('timeline_post_autosave'),
        desc: t('timeline_post_autosave_info'),
        icon: 'draft-outlined',
        key: 'timeline_post_autosave',
        value: false,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: true,
      },
      {
        name: t('timeline_context_menu'),
        desc: t('timeline_context_menu_info'),
        icon: 'context-menu',
        key: 'timeline_context_menu',
        value: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('timeline_filters_save'),
        desc: t('timeline_filters_save_info'),
        icon: 'filter-outlined',
        key: 'timeline_filters_save',
        value: false,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('timeline_repost_button'),
        desc: t('timeline_repost_button_info'),
        icon: 'repost',
        key: 'timeline_repost_button',
        value: true,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('timeline_delete_button'),
        desc: t('timeline_delete_button_info'),
        icon: 'trash-outlined',
        key: 'timeline_delete_button',
        value: false,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('comments'),
    icon: 'comment',
    key: 'comments',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    isEnabled: true,
    items: [
      {
        name: t('comment_sendbyenter'),
        desc: t('comment_sendbyenter_info'),
        icon: 'enter-key',
        key: 'comment_sendbyenter',
        value: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('comment_emoji'),
        desc: t('comment_emoji_info'),
        icon: 'emoji',
        key: 'comment_emoji',
        value: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('finance'),
    icon: 'finance',
    key: 'finance',
    roles: [
      "parent",
    ],
    isEnabled: true,
    items: [
      {
        name: t('finance_filters_save'),
        desc: t('finance_filters_save_info'),
        icon: 'filter-outlined',
        key: 'finance_filters_save',
        value: false,
        roles: [
          "parent",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('children'),
    icon: 'people',
    key: 'children',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    isEnabled: true,
    items: [
      {
        name: t('children_filters_save'),
        desc: t('children_filters_save_info'),
        icon: 'filter-outlined',
        key: 'children_filters_save',
        value: false,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('contacts'),
    icon: 'contacts',
    key: 'contacts',
    roles: [
      "parent",
    ],
    isEnabled: true,
    items: [
      {
        name: t('contacts_filters_save'),
        desc: t('contacts_filters_save_info'),
        icon: 'filter-outlined',
        key: 'contacts_filters_save',
        value: false,
        roles: [
          "parent",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('gallery'),
    icon: 'gallery',
    key: 'gallery',
    roles: [
      "parent",
    ],
    isEnabled: true,
    items: [
      {
        name: t('gallery_filters_save'),
        desc: t('gallery_filters_save_info'),
        icon: 'filter-outlined',
        key: 'gallery_filters_save',
        value: false,
        roles: [
          "parent",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('calendar'),
    icon: 'calendar',
    key: 'calendar',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    isEnabled: true,
    items: [
      {
        name: t('calendar_filters_save'),
        desc: t('calendar_filters_save_info'),
        icon: 'filter-outlined',
        key: 'calendar_filters_save',
        value: false,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('employees'),
    icon: 'employees',
    key: 'employees',
    roles: [
      "director",
    ],
    isEnabled: true,
    items: [
      {
        name: t('employees_filters_save'),
        desc: t('employees_filters_save_info'),
        icon: 'filter-outlined',
        key: 'employees_filters_save',
        value: false,
        roles: [
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('app'),
    icon: 'webapp-logo',
    key: 'app',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    isEnabled: true,
    items: [
      {
        name: t('app_dashboard'),
        desc: t('app_dashboard_info'),
        icon: 'dashboard',
        key: 'app_dashboard',
        value: false,
        roles: [
          "teacher",
          "director",
        ],
        isEnabled: false,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('app_update_welcome_screen'),
        desc: t('app_update_welcome_screen_info'),
        icon: 'welcome-screen',
        key: 'app_update_welcome_screen',
        value: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: true,
      },
      {
        name: t('app_user_switching'),
        desc: t('app_user_switching_info'),
        icon: 'user-switch',
        key: 'app_user_switching',
        value: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('app_filter_autoopening'),
        desc: t('app_filter_autoopening_info'),
        icon: 'filter',
        key: 'app_filter_autoopening',
        value: false,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('app_filter_fast_button'),
        desc: t('app_filter_fast_button_info'),
        icon: 'filter-close',
        key: 'app_filter_fast_button',
        value: false,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
  {
    name: t('communication'),
    icon: 'communication',
    key: 'communication',
    requirements: [
      "communication",
    ],
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    isEnabled: true,
    items: [
      {
        name: t('communication_autoopen_sidebar'),
        desc: t('communication_autoopen_sidebar_info'),
        icon: 'sidebar-right',
        key: 'communication_autoopen_sidebar',
        value: false,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('communication_show_toolbar_button'),
        desc: t('communication_show_toolbar_button_info'),
        icon: 'communication',
        key: 'communication_show_toolbar_button',
        value: false,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('communication_sendbyenter'),
        desc: t('communication_sendbyenter_info'),
        icon: 'enter-key',
        key: 'communication_sendbyenter',
        value: false,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('communication_emoji'),
        desc: t('communication_emoji_info'),
        icon: 'emoji',
        key: 'communication_emoji',
        value: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('communication_thread_context_menu'),
        desc: t('communication_thread_context_menu_info'),
        icon: 'context-menu',
        key: 'communication_thread_context_menu',
        value: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
      {
        name: t('communication_message_context_menu'),
        desc: t('communication_message_context_menu_info'),
        icon: 'context-menu',
        key: 'communication_message_context_menu',
        value: true,
        roles: [
          "parent",
          "teacher",
          "director",
        ],
        isEnabled: true,
        isSetup: true,
        isRecommended: false,
      },
    ],
  },
];

export default addons;
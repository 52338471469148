import * as AuthService from '../../../services/auth.service';
import config from '../../../constants/config';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import store from '../../../store';
import SVG from 'src/components/Images/SvgRenderer';
import theme from 'src/ui/theme';
import { createUseStyles } from 'react-jss';
import { FallbackProps } from 'react-error-boundary';
import { getAppApiServer, getAppData } from '../../../utils/useApp';
import { getQueryParams, isValidHexColor } from 'src/utils/useFunctions';
import { saveAs } from 'file-saver';
import { serializeError } from 'serialize-error';
import { setDeinitializeUserController } from 'src/store/actions/controllers.actions';
import { setUserStatus } from '../../../store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  backgroundColor?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  errorScreen: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    zIndex: theme.zIndex.screen,
    backgroundColor: (props: Props) => props.backgroundColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > svg': {
      maxWidth: '60%',
    }, 
  },
  buttons: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  failed: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '43px',
    left: '50%',
    transform: 'translateX(-50%)',
    '& > span': {
      textAlign: 'center',
      color: '#EE576C',
      fontWeight: 'bold',
    },
  },
}));

const ErrorScreen: React.FunctionComponent<FallbackProps> = ({error, resetErrorBoundary}) => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const queryParams = getQueryParams(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const background = queryParams.get("background");
  const backgroundColor = isEmbed ? (background === "gradient" ? theme.colors.gradient : (isValidHexColor(`#${background}`) ? `#${background}` : theme.colors.white)) : theme.colors.white;

  const classes = useStyles({
    backgroundColor: backgroundColor,
  });

  const handleLogout = () => {
    dispatch(setUserStatus("loggingOff"));
    const savedData = getAppData();
    const savedUsers = savedData.users ? savedData.users : [];
    dispatch(setDeinitializeUserController(true));
    navigate(savedUsers.length > 1 ? "/auth/switch" : "/auth/login");
    window.location.reload();
  };

  const handleDownloadLog = () => {
    let text = [];
    text.push("Twigsee WebApp Error log");
    text.push("Environment: " + config.APP_ENVIRONMENT);
    text.push("API server: " + getAppApiServer());
    text.push("Date: " + moment().format("YYYY-MM-DD HH:mm:ss"));
    text.push("Version: " + config.APP_VERSION);
    text.push("Location: " + location.pathname);
    text.push("");
    text.push("Errors:");
    text.push(JSON.stringify(serializeError(error))); 
    text.push("");
    text.push("Error messages:");
    text.push(error.message);       
    if(userData.userObject) {
      text.push("");
      text.push("User:");
      text.push(JSON.stringify(userData.userObject));
    }
    text.push("");
    text.push("Redux Store:");
    text.push(JSON.stringify(store.getState()));
    let blob = new Blob([text.join("\n")], {type: "text/plain;charset=utf-8"});
    let file_name = "twigsee_webapp_error_log_" + moment().format("YYYY_MM_DD_HH_mm_ss") + ".txt";
    saveAs(blob, file_name);  
  };

  const handleClickTryAgain = () => {
    window.location.reload();
  };

  const handleClickLogout = () => {
    AuthService && AuthService.logout().then(() => {
      handleLogout();
    }).catch(() => {
      handleLogout();
    });
  }; 
  
  return (
    <div className={classes.errorScreen}>
      <SVG src={config.APP_LOGO}/>
      <div className={classes.failed}>
        <span>{config.APP_ENVIRONMENT === "production" ? t('error') : t('error_occured')}.</span>
        <div className={classes.buttons}>
        {
          config.APP_ENVIRONMENT !== "production" ? (
            <NormalButton buttonType="primary" onClick={handleDownloadLog} dataCy="errorScreenDownloadButton">
              {t("download_log")}
            </NormalButton>
          ) : null
        }
        <NormalButton buttonType="secondary" onClick={handleClickTryAgain}>
          {t("try_again")}
        </NormalButton>
        {
          Object.keys(userData.userObject).length > 0 ? (
            <NormalButton buttonType="tertiary" onClick={handleClickLogout}>
              {t("logout")}
            </NormalButton>
          ) : null
        }
        </div>
      </div>        
    </div>
  );
}

export default ErrorScreen;